import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import ZoomInIcon from '@material-ui/icons/ZoomIn';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import { green, red } from '@material-ui/core/colors';

import StopsService from './../../../services/api/stops';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: '12px',
  },
  table: {
    minWidth: 650,
  },
  th: {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
  header: {
    marginBottom: '20px',
  },
  searchIcon: {
    marginRight: '16px',
  },
  paper: {
    height: '42px',
    display: 'flex',
    padding: '0px 16px',
    alignItems: 'center',
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: '1',
    maxWidth: '480px',
    flexBasis: '480px',
  },
  searchButton: {
    marginLeft: '16px',
    marginRight: '5px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
}));

export const RoutesTable = () => {
  const classes = useStyles();

  let { stopId } = useParams();

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [routes, setRoutes] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setTimeout(function () {
      //Request
      // RoutesService.deleteItem(routeId)
      //   .then((response) => {
      //     if (response === null || !response.IsValid) {
      //       throw response.Message;
      //     }
      //     if (response.IsValid && response.Data !== undefined) {
      //       setOpen(false);
      //       history.push(`/routes/`);
      //     } else {
      //       throw response.Message;
      //     }
      //   })
      //   .catch((e) => {
      //     setRequestError({
      //       error: true,
      //       errorDescription: e.status ? e.Data.Message : e,
      //     });
      //     setOpen(false);
      //     setIsLoading(false);
      //   });
    }, 1000);
  };

  useEffect(() => {
    setIsLoading(true);

    setTimeout(function () {
      //Request
      StopsService.getRoutes(stopId)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }

          //Get Routes
          if (response && response.Data !== undefined) {
            // console.log(response.Data);
            setRoutes(response.Data);
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 500);
  }, []);

  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        direction="row"
        alignContent="center"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Grid item>
          <h2>Rutas</h2>
        </Grid>
      </Grid>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.th} align="center">
                ID
              </TableCell>
              <TableCell className={classes.th} align="left">
                Nombre
              </TableCell>
              <TableCell className={classes.th} align="center">
                Estado
              </TableCell>
              <TableCell className={classes.th} align="center">
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow key="skeletonRow" width={100}>
                <TableCell colSpan="6" align="center">
                  <Skeleton count={5} height={50} />
                </TableCell>
              </TableRow>
            )}
            {routes.map((row) => (
              <TableRow key={row.Nombre}>
                <TableCell align="center">{row.RutaID}</TableCell>
                <TableCell align="left">{row.Nombre}</TableCell>
                <TableCell align="center">
                  {row.EstaActivo ? (
                    <Chip
                      icon={<CheckIcon style={{ color: green[700] }} />}
                      label="Activa"
                      className={classes.chipActive}
                    />
                  ) : (
                    <Chip
                      icon={<CloseIcon style={{ color: red[700] }} />}
                      label="Inactiva"
                      className={classes.chipInactive}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  <Tooltip title="Ver detalle">
                    <IconButton
                      color="secondary"
                      size="small"
                      href={`/routes/detail/${row.RutaID}`}
                      // onClick={() => handleStopDetailClicked(row.id)}
                      aria-label="show detail route"
                    >
                      <ZoomInIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Eliminar Ruta / Parada">
                    <IconButton
                      color="secondary"
                      size="small"
                      onClick={handleClickOpen}
                      aria-label="delete route stop"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Esta seguro que desea remover esta Ruta?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
