import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import 'moment/locale/es';

import 'react-dates/lib/css/_datepicker.css';

import { makeStyles } from '@material-ui/core/styles';

import { useTable, usePagination, useSortBy } from 'react-table';

import Card from '@material-ui/core/Card';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { CardContent } from '@material-ui/core';

import MapIcon from '@material-ui/icons/Room';

import TablePagination from '@material-ui/core/TablePagination';

import { green, red } from '@material-ui/core/colors';

import ReportsServices from './../../../services/api/reports';
import UsersServices from './../../../services/api/users';

import { MapBox } from './../../Common/MapBox';

import { getSessionCookie, getLoggedUserId } from './../../../services/session';

const useStyles = makeStyles((theme) => ({
  body: {
    boxSizing: 'border-box',
  },
  table: {
    minWidth: 650,
  },
  th: {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
  searchIcon: {
    marginRight: '16px',
  },
  paper: {
    width: '100%',
    height: '56px',
    display: 'flex',
    padding: '0px 16px',
    alignItems: 'center',
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: '1',
    maxWidth: '480px',
    flexBasis: '480px',
  },
  searchButton: {
    marginLeft: '16px',
    marginRight: '5px',
  },
  searchContainer: {
    marginBottom: '1em',
    overflow: 'visible',
  },
  searchInput: {
    width: '100%',
  },
  cardContent: {
    paddingBottom: '1em !important',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
  paginatorContainer: {
    marginTop: '1em',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    fontWeight: 800,
  },
  pos: {
    marginBottom: 12,
  },
  analyticsIcons: {
    fontSize: 80,
    marginLeft: '1.5rem',
  },
  paperModal: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function Table({ columns, data, pageCount: controlledPageCount, loading }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
    },
    useSortBy,
    usePagination
  );

  const handleChangeRows = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    gotoPage(0);
  };

  const handlePageChange = (event, newPage) => {
    gotoPage(newPage);
  };

  // Render the UI for your table
  return (
    <React.Fragment>
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps(
                    column.getSortByToggleProps([
                      {
                        className: column.className,
                        style: column.style,
                      },
                    ])
                  )}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {loading && (
            <TableRow key="skeletonRow" width={100}>
              <TableCell colSpan="8" align="center">
                <Skeleton count={5} height={50} />
              </TableCell>
            </TableRow>
          )}
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                      ])}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </MaUTable>
      <Grid
        container
        direction="column"
        alignContent="flex-end"
        alignItems="flex-end"
        style={{ marginTop: '1em', marginBottom: '1em' }}
      >
        <Grid item>
          <TablePagination
            component="div"
            page={pageIndex}
            count={rows.length}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRows}
            onPageChange={handlePageChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export const ReportsPassengers = () => {
  let { query } = useParams();

  const columns = React.useMemo(
    () => [
      {
        Header: 'REPORTE DE PASAJEROS',
        columns: [
          {
            Header: 'ID',
            accessor: 'ViajeID',
            style: {
              textAlign: 'center',
            },
          },
          {
            Header: 'PASAJERO',
            accessor: 'NombrePasajero',
          },
          {
            Header: 'HORA',
            accessor: (d) => {
              return moment(d.Fecha).local().format('hh:mm a');
            },
            style: {
              textAlign: 'center',
            },
          },
          {
            Header: 'FECHA',
            accessor: (d) => {
              return moment(d.Fecha).local().format('DD/MM/YYYY');
            },
            style: {
              textAlign: 'center',
            },
          },
          {
            Header: 'RUTA',
            accessor: 'Ruta',
            style: {
              textAlign: 'center',
            },
          },
          {
            Header: 'FOLIO VIAJE',
            accessor: 'Folio',
          },
          {
            Header: 'UBICACIÓN',
            accessor: (d) => {
              return (
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => handleOpen(d.Latitud, d.Longitud)}
                  aria-label="show map"
                >
                  <MapIcon />
                </IconButton>
              );
            },
            style: {
              textAlign: 'center',
            },
          },
        ],
      },
    ],
    []
  );

  const classes = useStyles();
  let session = getSessionCookie();

  const [focusedInput, setFocusedInput] = React.useState();

  const [startDate, setStartDate] = React.useState(
    moment().subtract(1, 'week')
  );
  const [endDate, setEndDate] = React.useState(moment());

  const [results, setResults] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [routeId, setRouteId] = React.useState('all');
  const [pageCount, setPageCount] = React.useState(0);

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [latitude, setLatitude] = React.useState('');
  const [longitude, setLongitude] = React.useState('');

  const handleOpen = (latitude, longitude) => {
    setOpen(true);
    setLatitude(latitude);
    setLongitude(longitude);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);

    setTimeout(function () {
      UsersServices.getLoggedUser(getLoggedUserId())
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }

          if (response && response.Data !== undefined) {
            let filters = query;

            //Request
            ReportsServices.getCapacity(filters)
              .then((response) => {
                if (response === null || !response.IsValid) {
                  throw response.Message;
                }
                setIsLoading(false);
                //Get Routes
                if (response && response.Data && response.Data.length > 0) {
                  setPageCount(Math.ceil(response.Data.length / 10));
                  setResults(response.Data);
                  setIsLoading(false);
                }
              })
              .catch((e) => {
                setIsLoading(false);
              });
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 500);
  }, [endDate, startDate, session.role]);

  const handleSearchClick = () => {
    setIsLoading(true);
    setResults([]);
    let filters = '';

    //ClienteID
    if (session.role !== 'superadmin') {
      filters = filters + `ClienteID=1&`;
    }

    //RutaID
    if (routeId !== 'all') {
      filters = filters + `RutaID=${routeId}&`;
    }
    //fechaInicio
    if (startDate !== '') {
      filters = filters + `fechaInicio=${startDate.format('YYYYMMDD')}&`;
    }
    //fechaFin
    if (endDate !== '') {
      filters = filters + `fechaFin=${endDate.format('YYYYMMDD')}`;
    }

    setTimeout(function () {
      //Request
      ReportsServices.getTravels(filters)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          setIsLoading(false);
          //Get Routes
          if (response && response.Data && response.Data.length > 0) {
            setResults(response.Data);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 1000);
  };

  const body = (
    <div style={modalStyle} className={classes.paperModal}>
      <MapBox latitude={latitude} longitude={longitude} />
    </div>
  );

  return (
    <React.Fragment>
      <Card
        className={classes.searchContainer}
        style={{ background: '#f4f6f8', marginTop: '15px' }}
      >
        <CardContent>
          <Grid
            container
            className={classes.header}
            alignContent="center"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs>
              <div className={classes.searchBox}>
                <Paper elevation={1} className={classes.paper}>
                  <SearchIcon className={classes.searchIcon} />
                  <InputBase
                    className={classes.searchInput}
                    placeholder="Buscar"
                  />
                </Paper>
              </div>
            </Grid>

            <Grid item></Grid>
            <Grid item></Grid>
            <Grid item>
              <Button
                onClick={handleSearchClick}
                size="large"
                variant="contained"
                color="secondary"
                className={classes.searchButton}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card style={{ background: '#f4f6f8' }}>
        <CardContent>
          <Grid container className={classes.header} direction="column">
            <Grid item sm={12} xs={12}>
              <TableContainer component={Paper}>
                <Table columns={columns} data={results} loading={isLoading} />
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </React.Fragment>
  );
};
