import React from 'react';

import DashboardLayout from './../layouts/dashboard';

import Grid from '@material-ui/core/Grid';

import { VehiclesList } from './../../components/Vehicles/VehiclesList';

export const VehiclesPage = () => {
  return (
    <DashboardLayout title={'Unidades'}>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Grid item xs>
          <VehiclesList />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};
