// ACL Rules

const rules = {
  employee: {
    static: [],
  },
  driver: {
    static: [],
  },
  company: {
    static: ['routes:visit', 'reports:visit'],
  },
  client: {
    static: [
      'routes:visit',
      'vehicles:visit',
      'drivers:visit',
      'reports:visit',
      'clients:read',
      'stops:visit',
      'notifications:visit',
      'passengers:visit',
    ],
  },

  superadmin: {
    static: [
      'routes:visit',
      'stops:visit',
      'vehicles:visit',
      'drivers:visit',
      'reports:visit',
      'clients:read',
      'companies:read',
      'notifications:visit',
      'passengers:visit',
      'superadmin'
    ],
  },
};

export default rules;
