import React from 'react';

import DashboardLayout from './../../layouts/dashboard';

import Grid from '@material-ui/core/Grid';

import { ReportsPassengers } from './../../../components/Reports/Passengers';

export const ReportsPassengersPage = () => {
  return (
    <DashboardLayout title={'Reporte de Pasajeros'}>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs>
          <ReportsPassengers />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};
