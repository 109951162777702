import { useState, useEffect } from 'react';

import VehiclesService from './../services/api/vehicles';

export default function useVehicles() {

  const [vehicles, setvVehicles] = useState([]);

  useEffect(() => {
    VehiclesService.getList()
      .then((response) => {
        if (response === null || !response.IsValid) {
          throw response.Message;
        }
        //Get Companies
        if (response && response.Data !== undefined) {
          setvVehicles(response.Data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return vehicles;
}