import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import GoogleMapReact from 'google-map-react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import ReplyIcon from '@material-ui/icons/Reply';

import { green, red } from '@material-ui/core/colors';

import stopMarkerIcon from './../../../assets/images/maps/ic_stop.png';

import StopsService from './../../../services/api/stops';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  select: {
    width: '100%',
  },
  formControl: {
    marginTop: '0px',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
}));

export const StopsModalDetail = (props) => {
  const classes = useStyles();

  let { clickedRouteStopId, clickedStopId, modalStatusHandler } = props;

  const [center, setCenter] = React.useState({
    lat: 29.0836781,
    lng: -110.959494,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [stop, setStop] = useState({});
  const [routeStop, setRouteStop] = useState({});

  const handleCancelClick = () => {
    modalStatusHandler();
  };

  const handleMapLoaded = (map, maps) => {
    var imageGoing = {
      url: stopMarkerIcon,
      // This marker is 20 pixels wide by 32 pixels high.
      size: new maps.Size(26, 26),
      scaledSize: new maps.Size(26, 26),
    };

    // let marker;

    // marker = new maps.Marker({
    //   map: map,
    //   draggable: false,
    //   icon: imageGoing,
    //   animation: maps.Animation.DROP,
    //   position: { lat: center.lat, lng: center.lng },
    // });

    //Fit Boundaries
    // var bounds = new maps.LatLngBounds();
    // bounds.extend(new maps.LatLng(marker.lat, marker.lng));
    // map.fitBounds(bounds);
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(function () {
      //Request
      StopsService.get(clickedStopId)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }

          //Get Routes
          if (response && response.Data !== undefined) {
            setStop({
              id: response.Data.ParadaID,
              name: response.Data.Nombre,
              description: response.Data.Descripcion,
              latitude: response.Data.Latitud,
              longitude: response.Data.Longitud,
              status: response.Data.EstaActivo,
            });
            setCenter({
              lat: parseFloat(response.Data.Latitud),
              lng: parseFloat(response.Data.Longitud),
            });
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 500);
  }, []);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(function () {
      //Request
      StopsService.getRouteStopsDetail(clickedRouteStopId)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }

          //Get Routes
          if (response && response.Data !== undefined) {
            setRouteStop({
              id: response.Data[0].ParadaRutaID,
              time: response.Data[0].Nombre,
              order: response.Data[0].Orden,
              status: response.Data[0].EstaActivoRutaParada,
            });
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 500);
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <Skeleton count={1} height={50} />
      ) : (
        <React.Fragment>
          <Grid
            container
            spacing={2}
            direction="row"
            alignContent="center"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography>Detalle de la parada</Typography>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            direction="row"
            alignContent="center"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Grid item xs>
              <Typography variant="overline" gutterBottom>
                Nombre
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {stop.name}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="overline" gutterBottom>
                Descripción
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {stop.description}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            direction="row"
            alignContent="center"
            alignItems="flex-start"
            justifyContent="space-between"
            style={{ marginTop: '5px' }}
          >
            <Grid item sm={3} xs={12}>
              <Typography variant="overline" gutterBottom>
                Orden
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {routeStop.order}
              </Typography>
            </Grid>
            <Grid item sm={3} xs={12}>
              <Typography variant="overline" gutterBottom>
                Estado
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {stop.status ? (
                  <Chip
                    icon={<CheckIcon style={{ color: green[700] }} />}
                    label="Activa"
                    className={classes.chipActive}
                  />
                ) : (
                  <Chip
                    icon={<CloseIcon style={{ color: red[700] }} />}
                    label="Inactiva"
                    className={classes.chipInactive}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item sm={3} xs={12}>
              <Typography variant="overline" gutterBottom>
                Latitud
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {stop.latitude}
              </Typography>
            </Grid>
            <Grid item sm={3} xs={12}>
              <Typography variant="overline" gutterBottom>
                Longitud
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {stop.longitude}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            direction="row"
            alignContent="center"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Grid item xs>
              <div
                style={{
                  height: '350px',
                  minHeight: '350px',
                  width: '100%',
                  marginTop: '10px',
                }}
              >
                <GoogleMapReact
                  draggable={false}
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_MAPS_KEY,
                  }}
                  defaultCenter={center}
                  defaultZoom={12}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }) =>
                    handleMapLoaded(map, maps)
                  }
                ></GoogleMapReact>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={1} direction="row" justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={handleCancelClick}
                variant="contained"
                color="secondary"
                size="small"
                className={classes.button}
                startIcon={<ReplyIcon />}
              >
                Regresar
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
