import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import { green, red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';

import { Typography, CardContent } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ReplyIcon from '@material-ui/icons/Reply';

import { RouteCoordinatesPanel } from './../RouteCoordinatesPanel';
import { RouteStopsList } from './../RouteStopsList';

import RoutesService from './../../../services/api/routes';

const useStyles = makeStyles({
  table: {
    marginTop: '10px',
  },
  cardContent: {
    padding: '0px !important',
  },
  card: {
    marginTop: '1rem',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
});

export const RoutesDetail = () => {
  const classes = useStyles();

  let { routeId } = useParams();

  const [route, setRoute] = React.useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(function () {
      //Request
      RoutesService.get(routeId)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          setIsLoading(false);
          //Get Routes
          if (response && response.Data !== undefined) {
            setRoute({
              id: response.Data.RutaID,
              name: response.Data.Nombre,
              description: response.Data.Descripcion,
              start_place: response.Data.Inicio,
              end_place: response.Data.Fin,
              duration: response.Data.Duracion,
              frecuency: response.Data.Frecuencia,
              start_time: response.Data.HoraInicio,
              end_time: response.Data.HoraFin,
              status: response.Data.EstaActivo,
              clientName: response.Data.NombreCliente,
              companyName: response.Data.NombreCompania,
            });
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 500);
  }, [routeId]);

  return (
    <React.Fragment>
      <Card style={{ background: '#f4f6f8' }}>
        <CardContent>
          {isLoading ? (
            <Skeleton count={1} height={50} />
          ) : (
            <Grid
              container
              spacing={2}
              direction="row"
              alignContent="center"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={9}>
                <Typography variant="h6" display="block" gutterBottom>
                  {route.name}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  ID: {route.id}
                </Typography>
              </Grid>
              <Grid>
                <Tooltip title="Regresar">
                  <IconButton
                    color="secondary"
                    size="medium"
                    href={`/routes/`}
                    aria-label="back to routes"
                  >
                    <ReplyIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Editar">
                  <IconButton
                    color="secondary"
                    size="medium"
                    href={`/routes/edit/${route.id}`}
                    aria-label="edit route"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      <Card className={classes.card} style={{ background: '#f4f6f8' }}>
        <CardContent>
          <Grid
            container
            spacing={2}
            direction="row"
            alignContent="center"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Grid item sm={4} xs={12}>
              {isLoading ? (
                <Skeleton count={10} height={50} />
              ) : (
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Cliente
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{route.clientName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Compañia
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{route.companyName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Descripción
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{route.description}</TableCell>
                    </TableRow>
                    <TableRow selected>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Inicio
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{route.start_place}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Fin
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{route.end_place}</TableCell>
                    </TableRow>
                    <TableRow selected>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Duración
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{route.duration}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Frecuencia
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{route.frecuency}</TableCell>
                    </TableRow>
                    <TableRow selected>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Hora Inicio / Hora Fin
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {route.start_time} / {route.end_time}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Estado
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {route.status ? (
                          <Chip
                            icon={<CheckIcon style={{ color: green[700] }} />}
                            label="Activa"
                            className={classes.chipActive}
                          />
                        ) : (
                          <Chip
                            icon={<CloseIcon style={{ color: red[700] }} />}
                            label="Inactiva"
                            className={classes.chipInactive}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Grid>
            <Grid item sm={8} xs={12}>
              <RouteCoordinatesPanel />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.card} style={{ background: '#f4f6f8' }}>
        <CardContent>
          {isLoading ? (
            <Skeleton count={1} height={50} />
          ) : (
            <RouteStopsList routeId={route.id} />
          )}
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
