import { makeStyles } from '@material-ui/core/styles';

import { green, red } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  th: {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
  searchIcon: {
    marginRight: '16px',
  },
  paper: {
    height: '42px',
    display: 'flex',
    padding: '0px 16px',
    alignItems: 'center',
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: '1',
  },
  searchButton: {
    marginLeft: '16px',
    marginRight: '5px',
  },
  cardContent: {
    paddingBottom: '1em !important',
  },
  divider: {
    height: 28,
    margin: 4,
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
  paginatorContainer: {
    marginTop: '1em',
  },
  filtersBox: {
    marginBottom: '-30px',
  },
  filterBtn: {
    // marginTop: '3px',
    width: '20%',
    minWidth: '100px'
  },
  actionBtn: {
    marginLeft: '5px',
    marginRight: '5px',
  },
  table: {
    overflowX: 'auto',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  spacer: {
    flex: 'none',
  },
  searchInput: {
    width: '100% !important'
  },
  deleteIcon: {
    color: 'red'
  }
}));
