import React from 'react';

import DashboardLayout from './../../layouts/dashboard';

import Grid from '@material-ui/core/Grid';

import { ReportsCapacity } from './../../../components/Reports/Capacity';

export const ReportsCapacityPage = () => {
  return (
    <DashboardLayout title={'Reporte de Aforo'}>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs>
          <ReportsCapacity />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};
