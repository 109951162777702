import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import { green, red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';

import { Typography, CardContent } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ReplyIcon from '@material-ui/icons/Reply';

import NotificationsService from './../../../services/api/notifications';

const useStyles = makeStyles({
  table: {
    marginTop: '10px',
  },
  cardContent: {
    padding: '0px !important',
  },
  card: {
    marginTop: '1rem',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
});

export const NotificationDetail = () => {
  const classes = useStyles();

  let { notificationId } = useParams();

  const [notification, setNotification] = React.useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(function () {
      //Request
      NotificationsService.get(notificationId)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          //Get Routes
          if (response && response.Data !== undefined) {
            setNotification(response.Data);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 1000);
  }, [notificationId]);

  const clientsReadable = (clients) => {
    return clients.map(item => {
      return item + ' ';
    }) 
  }

  return (
    <React.Fragment>
      <Card style={{ background: '#f4f6f8' }}>
        <CardContent>
          {isLoading ? (
            <Skeleton count={1} height={50} />
          ) : (
            <Grid
              container
              spacing={2}
              direction="row"
              alignContent="center"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={9}>
                <Typography variant="h6" display="block" gutterBottom>
                  {notification.Titulo}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  ID: {notification.NotificacionID}
                </Typography>
              </Grid>
              <Grid>
                <Tooltip title="Regresar">
                  <IconButton
                    color="secondary"
                    size="medium"
                    href={`/notifications/`}
                    aria-label="back to notifications"
                  >
                    <ReplyIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Editar">
                  <IconButton
                    color="secondary"
                    size="medium"
                    href={`/notifications/edit/${notification.NotificacionID}`}
                    aria-label="edit notification"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      <Card className={classes.card} style={{ background: '#f4f6f8' }}>
        <CardContent>
          <Grid
            container
            spacing={2}
            direction="row"
            alignContent="center"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Grid item sm={12} xs={12}>
              {isLoading ? (
                <Skeleton count={10} height={50} />
              ) : (
                <Table>
                  <TableBody>
                    <TableRow selected>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Texto de la notificación
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{notification.Contenido}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Clientes
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {clientsReadable(notification.NombreClientes) }
                      </TableCell>
                    </TableRow>
                    <TableRow selected>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Fecha Envio
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{moment(notification.FechaEnvio).format('D/M/YYYY hh:mm:ss A')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Enviado
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {notification.EstaActivo ? (
                          <Chip
                            icon={<CheckIcon style={{ color: green[700] }} />}
                            label="Enviada"
                            className={classes.chipActive}
                          />
                        ) : (
                          <Chip
                            icon={<CloseIcon style={{ color: red[700] }} />}
                            label="Sin Enviar"
                            className={classes.chipInactive}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
