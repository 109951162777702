import React from 'react';

import GoogleMapReact from 'google-map-react';
import { green, red } from '@material-ui/core/colors';

import goingMarkerIcon from './../../../assets/images/maps/ic_location_map_start.png';
import backMarkerIcon from './../../../assets/images/maps/ic_location_map_end.png';

export const MapBox = (props) => {
  const [center, setCenter] = React.useState({
    lat: parseFloat(props.latitude),
    lng: parseFloat(props.longitude),
  });

  const handleMapLoaded = (map, maps) => {
    var imageGoing = {
      url: goingMarkerIcon,
      // This marker is 20 pixels wide by 32 pixels high.
      size: new maps.Size(18, 35),
      scaledSize: new maps.Size(18, 35),
    };

    new maps.Marker({
      position: {
        lat: parseFloat(props.latitude),
        lng: parseFloat(props.longitude),
      },
      map: map,
      icon: imageGoing,
      title: '',
      draggable: false,
    });
  };

  return (
    <React.Fragment>
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          draggable={false}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_MAPS_KEY,
          }}
          defaultCenter={center}
          defaultZoom={18}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleMapLoaded(map, maps)}
        ></GoogleMapReact>
      </div>
    </React.Fragment>
  );
};
