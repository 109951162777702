import request from './../request';
import { getAuthorizationToken } from './../session';

function getList() {
  return request({
    url: '/Cliente/clientes',
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function getCityClients() {
  return request({
    url: '/Cliente/ClientesCiudad',
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

const CompaniesService = {
  getList,
  getCityClients,
};

export default CompaniesService;
