import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import GoogleMapReact from 'google-map-react';

import Can from './../../Can';

import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { green, red } from '@material-ui/core/colors';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import { Alert, AlertTitle } from '@material-ui/lab';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

import stopMarkerIcon from './../../../assets/images/maps/ic_stop.png';

import { CompanyCitySelect } from './../../Common/Selects/CompanyCity';

import StopsService from './../../../services/api/stops';

import { getSessionCookie } from './../../../services/session';

const useStyles = makeStyles({
  table: {
    marginTop: '10px',
  },
  cardContent: {
    padding: '0px !important',
  },
  card: {
    marginTop: '1rem',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
  select: {
    width: '100%',
    marginTop: '1em',
    marginBottom: '1em',
  },
  clientSelect: {
    marginBottom: '12px',
  },
});

const RedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

const GreenButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

export const StopsCreate = () => {
  const classes = useStyles();
  let history = useHistory();

  const [center] = React.useState({
    lat: 23.70919380667747,
    lng: -102.80046400857569,
  });
  const [role, setRole] = React.useState('logged');
  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState({
    error: false,
    errorDescription: null,
  });

  const [stop, setStop] = useState({
    latitude: '',
    longitude: '',
    status: false,
    companyId: '',
  });

  let stopSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string(),
    latitude: yup.string().required(),
    longitude: yup.string().required(),
    status: yup.boolean().required(),
    companyId: yup.number(),
  });

  const { handleSubmit, errors, control, setValue } = useForm({
    validationSchema: stopSchema,
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    setRequestError({ error: false, errorDescription: null });

    setTimeout(function () {
      //Request
      StopsService.create(data)
        .then((response) => {
          if (response === null) {
            throw new Error('Request error');
          }
          if (response.IsValid && response.Data !== undefined) {
            history.push(`/stops/detail/${response.Data.ParadaID}`);
          } else {
            throw response.Message;
          }
        })
        .catch((e) => {
          console.log(e);
          setRequestError({
            error: true,
            errorDescription: e.status ? e.data.Message : e,
          });
          setIsLoading(false);
        });
    }, 500);
  };

  const handleStatusChange = (event) => {
    setValue('status', event.target.value);
    setStop({ ...stop, status: event.target.value });
  };

  const handleMapLoaded = (map, maps) => {
    var imageStop = {
      url: stopMarkerIcon,
      // This marker is 20 pixels wide by 32 pixels high.
      size: new maps.Size(26, 26),
      scaledSize: new maps.Size(26, 26),
    };

    let marker;

    maps.event.addListener(map, 'click', function (event) {
      if (marker != null) {
        marker.setMap(null);
      }

      marker = new maps.Marker({
        map: map,
        draggable: true,
        icon: imageStop,
        // animation: maps.Animation.DROP,
        position: { lat: event.latLng.lat(), lng: event.latLng.lng() },
      });

      setStop({
        ...stop,
        latitude: event.latLng.lat(),
        longitude: event.latLng.lng(),
      });

      setValue('latitude', event.latLng.lat());
      setValue('longitude', event.latLng.lng());

      maps.event.addListener(marker, 'dragend', function () {
        setStop({
          ...stop,
          latitude: marker.getPosition().lat(),
          longitude: marker.getPosition().lng(),
        });

        setValue('latitude', marker.getPosition().lat());
        setValue('longitude', marker.getPosition().lng());
      });
    });

    // setValue('clientId', value);

    // setRoute({ ...route, clientId: value });

    //Fit Boundaries
    // var bounds = new maps.LatLngBounds();
    // for (let marker of markersGo) {
    //   bounds.extend(new maps.LatLng(marker.lat, marker.lng));
    // }
    // map.fitBounds(bounds);
    // }
  };

  const handleCompanyChange = (value) => {
    setValue('companyId', value);
    setStop({ ...stop, companyId: parseInt(value) });
  };

  useEffect(() => {
    let isCancelled = false;
    setTimeout(function () {
      let session = getSessionCookie();

      if (
        session !== undefined &&
        session.role !== undefined &&
        session.role !== null
      ) {
        setRole(session.role);
      }
    }, 0);

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <React.Fragment>
      <Card className={classes.card} style={{ background: '#f4f6f8' }}>
        <CardContent>
          <form
            className={classes.form}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            {isLoading ? (
              <Skeleton count={5} height={50} />
            ) : (
              <React.Fragment>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignContent="center"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Grid item xs>
                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="name"
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="name"
                      label="Nombre de la Parada"
                      autoComplete="name"
                      autoFocus
                      error={errors.name ? true : false}
                      style={{ marginTop: '0px', marginBottom: '0px' }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignContent="center"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Grid item xs>
                    <Controller
                      as={<TextField />}
                      control={control}
                      name="description"
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="description"
                      label="Descripción"
                      autoComplete="description"
                      error={errors.description ? true : false}
                      style={{ marginTop: '1em', marginBottom: '0px' }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignContent="center"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Can
                    role={role}
                    perform="companies:read"
                    yes={() => (
                      <Grid item sm={3} xs={12}>
                        <CompanyCitySelect
                          parentCallback={handleCompanyChange}
                          companyId={stop.companyId}
                          control={control}
                          error={errors.companyId ? true : false}
                        />
                      </Grid>
                    )}
                    no={() => ''}
                  />

                  <Grid item sm={3} xs={12}>
                    <Controller
                      as={
                        <FormControl
                          variant="outlined"
                          className={classes.select}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Estado
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            autoWidth
                            value={stop.status}
                            onChange={handleStatusChange}
                            label="Estado"
                          >
                            <MenuItem value={true}>Activo</MenuItem>
                            <MenuItem value={false}>Inactivo</MenuItem>
                          </Select>
                        </FormControl>
                      }
                      className={classes.select}
                      name="status"
                      control={control}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="latitude"
                      defaultValue=""
                      variant="outlined"
                      margin="none"
                      style={{ marginTop: '1em' }}
                      fullWidth
                      required
                      id="latitude"
                      label="Latitud"
                      autoComplete="latitude"
                      error={errors.latitude ? true : false}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="longitude"
                      defaultValue=""
                      variant="outlined"
                      margin="none"
                      fullWidth
                      required
                      style={{ marginTop: '1em' }}
                      id="longitude"
                      label="Longitud"
                      autoComplete="longitude"
                      error={errors.longitude ? true : false}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  alignContent="center"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Grid item xs>
                    <div
                      style={{
                        height: '470px',
                        minHeight: '350px',
                        width: '100%',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      <GoogleMapReact
                        draggable={true}
                        bootstrapURLKeys={{
                          key: process.env.REACT_APP_MAPS_KEY,
                        }}
                        defaultCenter={center}
                        defaultZoom={5}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) =>
                          handleMapLoaded(map, maps)
                        }
                      ></GoogleMapReact>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={1} direction="row" justifyContent="flex-end">
                  <Grid item>
                    <RedButton
                      component={Link}
                      to="/stops"
                      variant="contained"
                      color="secondary"
                      size="large"
                      className={classes.button}
                      startIcon={<CancelIcon />}
                    >
                      Cancelar
                    </RedButton>
                  </Grid>
                  <Grid item>
                    <GreenButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.button}
                      startIcon={<SaveIcon />}
                    >
                      Guardar Cambios
                    </GreenButton>
                  </Grid>
                </Grid>
                {requestError.error && (
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-end"
                  >
                    <Grid item xs>
                      <Alert severity="error">
                        <AlertTitle>Algo salió mal</AlertTitle>
                        {requestError.errorDescription}
                      </Alert>
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            )}
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
