import React from 'react';

import GoogleMapReact from 'google-map-react';
import { green, red } from '@material-ui/core/colors';

import goingMarkerIcon from './../../../assets/images/maps/ic_location_map_start.png';
import backMarkerIcon from './../../../assets/images/maps/ic_location_map_end.png';

export const RoutesCoordinatesMap = (props) => {
  const [center, setCenter] = React.useState({
    lat: 29.0836781,
    lng: -110.959494,
  });

  const handleMapLoaded = (map, maps) => {
    let markersGo = [];
    let markersBack = [];

    if (props.routeCoordinates.length > 0 && maps) {
      setCenter({
        lat: props.routeCoordinates[0].Latitud,
        lng: props.routeCoordinates[0].Longitud,
      });

      props.routeCoordinates.map((item, key) => {
        //Validate Lat/Lng
        if (
          !inRange(-90, item.Latitud, 90) &&
          !inRange(-180, item.Longitud, 180)
        ) {
          return true;
        }

        var imageGoing = {
          url: goingMarkerIcon,
          // This marker is 20 pixels wide by 32 pixels high.
          size: new maps.Size(18, 35),
          scaledSize: new maps.Size(18, 35),
        };

        var imageBack = {
          url: backMarkerIcon,
          // This marker is 20 pixels wide by 32 pixels high.
          size: new maps.Size(18, 35),
          scaledSize: new maps.Size(18, 35),
        };

        if (item.Orientacion === 1) {
          markersGo.push({ lat: item.Latitud, lng: item.Longitud });

          new maps.Marker({
            position: { lat: item.Latitud, lng: item.Longitud },
            map: map,
            icon: imageGoing,
            title: '',
            draggable: false,
          });
        } else {
          markersBack.push({ lat: item.Latitud, lng: item.Longitud });

          new maps.Marker({
            position: { lat: item.Latitud, lng: item.Longitud },
            map: map,
            icon: imageBack,
            title: '',
            draggable: false,
          });
        }
      });

      //Draw route
      let goPolyline = new maps.Polyline({
        path: markersGo,
        geodesic: false,
        strokeColor: green[600],
        strokeOpacity: 1.0,
        strokeWeight: 4,
      });

      let backPolyline = new maps.Polyline({
        path: markersBack,
        geodesic: false,
        strokeColor: red[600],
        strokeOpacity: 1.0,
        strokeWeight: 4,
      });

      //Draw Polyline
      goPolyline.setMap(map);
      backPolyline.setMap(map);

      //Fit Boundaries
      var bounds = new maps.LatLngBounds();
      for (let marker of markersGo) {
        bounds.extend(new maps.LatLng(marker.lat, marker.lng));
      }
      map.fitBounds(bounds);
    }
  };

  const inRange = (min, number, max) => {
    if (!isNaN(number) && number >= min && number <= max) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <div style={{ height: '80vh', width: '100%' }}>
        <GoogleMapReact
          draggable={false}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_MAPS_KEY,
          }}
          defaultCenter={center}
          defaultZoom={12}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleMapLoaded(map, maps)}
        ></GoogleMapReact>
      </div>
    </React.Fragment>
  );
};
