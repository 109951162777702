import React from 'react';

import Grid from '@material-ui/core/Grid';

import DashboardLayout from '../../layouts/dashboard';
import { StopsDetail } from '../../../components/Stops/StopsDetail';

export const StopsDetailPage = () => {
  return (
    <DashboardLayout title={'Paradas'}>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs>
          <StopsDetail />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};
