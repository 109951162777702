import request from './../request';
import { getAuthorizationToken } from './../session';

function getList() {
  return request({
    url: '/Ruta/Rutas/',
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function getListByClient(companyId) {
  return request({
    url: `/Ruta/rutasDisponiblesPorCliente/${companyId}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function get(routeId) {
  return request({
    url: `/Ruta/Rutas/${routeId}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function create(data) {
  let startTime = new Date(data.start_time);
  let endTime = new Date(data.end_time);

  return request({
    url: '/Ruta/Rutas/',
    method: 'POST',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      Nombre: data.name,
      Descripcion: data.description,
      Inicio: data.start_place,
      Fin: data.end_place,
      Duracion: data.duration,
      Frecuencia: data.frecuency,
      ClienteID: data.companyId,
      TipoTransporteID: 1,
      TipoUsuarioID: data.clientId,
      EstaActivo: data.status,
      HoraInicio: startTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      }),
      HoraFin: endTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      }),
    },
  });
}

function update(routeId, data) {
  let startTime = new Date(data.start_time);
  let endTime = new Date(data.end_time);

  return request({
    url: `/Ruta/Rutas/`,
    method: 'PUT',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      RutaID: routeId,
      Nombre: data.name,
      Descripcion: data.description,
      Inicio: data.start_place,
      Fin: data.end_place,
      Duracion: data.duration,
      Frecuencia: data.frecuency,
      ClienteID: data.companyId,
      TipoTransporteID: 1,
      TipoUsuarioID: data.clientId,
      EstaActivo: data.status,
      HoraInicio: startTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      }),
      HoraFin: endTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      }),
    },
  });
}

function deleteItem(routeId) {
  return request({
    url: `/Ruta/Rutas/`,
    method: 'DELETE',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      RutaID: routeId,
    },
  });
}

function getRoutePoints(routeId) {
  return request({
    url: `/RutaCoordenada/Coordenadas/${routeId}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function createRoutePoints(data) {
  return request({
    url: '/RutaCoordenada/CoordenadasBulkInsert/',
    method: 'POST',
    headers: { Authorization: getAuthorizationToken() },
    data: data,
  });
}

function deleteRoutePoints(data) {
  return request({
    url: '/RutaCoordenada/Coordenadas/',
    method: 'DELETE',
    headers: { Authorization: getAuthorizationToken() },
    data: data,
  });
}

function getStops(routeId) {
  return request({
    url: `/Paarada/Paradas/${routeId}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function getListByCompany(companyId) {
  return request({
    url: `/Ruta/rutasDisponiblesPorTipoUsuarioID/${companyId}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

const RoutesService = {
  get,
  getList,
  getListByClient,
  getListByCompany,
  create,
  update,
  deleteItem,
  getRoutePoints,
  createRoutePoints,
  deleteRoutePoints,
  getStops,
};

export default RoutesService;
