import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import { esES } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Routes from './Routes';

const browserHistory = createBrowserHistory();

const theme = createTheme(
  {
    palette: {
      background: {
        default: '#fab81a',
      },
      primary: {
        light: '#ffea56',
        main: '#fab81a',
        dark: '#c28800',
        contrastText: '#000000',
      },
      secondary: {
        light: '#a4a4a4',
        main: '#757575',
        dark: '#494949',
        contrastText: '#ffffff',
      },
    },
  },
  esES
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={browserHistory}>
        <Routes />
      </Router>
    </ThemeProvider>
  );
}

export default App;
