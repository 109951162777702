import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';

import CssBaseline from '@material-ui/core/CssBaseline';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    alignItems: 'center',
    // marginTop: 'calc(5% + 60px)',
  },
  loveIcon: {
    width: '15px',
    paddingTop: '11px',
    color: '#FC0C0C',
    marginBottom: '-2px',
  },
  footerText: {
    marginBottom: '10px',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function DefaultLayout({ children, title }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        {title && <title>{title} | Enrútate</title>}
      </Helmet>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>{children}</div>
        <Box mt={8}>
          <Typography variant="body2" color="textSecondary" align="center">
            Copyright © {new Date().getFullYear()} All rights reserved <br />{' '}
            Made with <FavoriteIcon className={classes.loveIcon} /> by{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://enrutate.com"
            >
              Enrútate
            </a>
            {'.'}
          </Typography>
        </Box>
      </Container>
    </React.Fragment>
  );
}

DefaultLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};
