import React, { createRef, useEffect, useState } from 'react';
import Chart from 'chart.js';
import moment from 'moment';
import { blue, red } from '@material-ui/core/colors';

const labels = moment();

const config = {
  type: 'bar',
  data: {
    labels: [
      labels.subtract(7, 'day').format('dddd'),
      labels.subtract(6, 'day').format('dddd'),
      labels.subtract(5, 'day').format('dddd'),
      labels.subtract(4, 'day').format('dddd'),
      labels.subtract(3, 'day').format('dddd'),
      labels.subtract(2, 'day').format('dddd'),
      labels.subtract(1, 'day').format('dddd'),
      labels.format('dddd'),
    ],
    datasets: [
      {
        label: 'Pasajeros',
        backgroundColor: blue['A100'],
        borderColor: blue[500],
        borderWidth: 1,
        data: [15, 25, 8, 52, 96, 45, 12, 25],
        fill: true,
      },
    ],
  },
  options: {
    responsive: true,
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Flujo de Pasajeros ultimos 7 días',
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Días',
          },
        },
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Pasajeros',
          },
        },
      ],
    },
  },
};

export const BarChart = (props) => {
  const chartRef = createRef();

  useEffect(() => {
    let chart = new Chart(chartRef.current, config);
  }, []);

  return <canvas ref={chartRef} />;
};
