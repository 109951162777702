import * as Cookies from 'js-cookie';

export const setSessionCookie = (userName, authToken, userId, role) => {
  Cookies.remove('userName');
  Cookies.remove('authToken');
  Cookies.remove('userId');
  Cookies.remove('role');

  Cookies.set('authToken', authToken, { expires: 14 });
  Cookies.set('userName', userName, { expires: 14 });
  Cookies.set('userId', userId, { expires: 14 });
  Cookies.set('role', role, { expires: 14 });
};

export const getSessionCookie = () => {
  const sessionCookie = {
    authToken: Cookies.get('authToken'),
    userName: Cookies.get('userName'),
    userId: Cookies.get('userId'),
    role: Cookies.get('role'),
  };

  if (sessionCookie === undefined) {
    return {};
  } else {
    return sessionCookie;
  }
};

export const destroySessionCookie = () => {
  const sessionCookie = {
    authToken: Cookies.get('authToken'),
    userName: Cookies.get('userName'),
    userId: Cookies.get('userId'),
    role: Cookies.get('role'),
  };

  if (sessionCookie === undefined) {
    return true;
  } else {
    Cookies.remove('userName');
    Cookies.remove('authToken');
    Cookies.remove('userId');
    Cookies.remove('role');
    return true;
  }
};

export const isUserLogged = () => {
  const sessionCookie = {
    authToken: Cookies.get('authToken'),
    userName: Cookies.get('userName'),
    userId: Cookies.get('userId'),
    role: Cookies.get('role'),
  };

  if (sessionCookie === undefined || sessionCookie.authToken === undefined) {
    return false;
  } else {
    return true;
  }
};

export const getAuthorizationToken = () => {
  return Cookies.get('authToken');
};

export const getLoggedUserId = () => {
  if (isUserLogged()) {
    return Cookies.get('userId');
  } else {
    return false;
  }
};
