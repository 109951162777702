import React from 'react';

import Grid from '@material-ui/core/Grid';

import DashboardLayout from '../../layouts/dashboard';
import { RoutesEdit } from '../../../components/Routes/RoutesEdit';

export const RoutesEditPage = () => {
  return (
    <DashboardLayout title={'Rutas'}>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs>
          <RoutesEdit routeId={1} />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};
