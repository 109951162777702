import React, { useEffect, useState, useRef, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { useTable, usePagination, useSortBy } from 'react-table';
import TablePagination from '@material-ui/core/TablePagination';

import TableContainer from '@material-ui/core/TableContainer';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import SearchIcon from '@material-ui/icons/Search';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import Chip from '@material-ui/core/Chip';
import { green, red } from '@material-ui/core/colors';

import VehiclesService from './../../../services/api/vehicles';

const useStyles = makeStyles({
  th: {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
  searchIcon: {
    marginRight: '16px',
  },
  paperFilter: {
    padding: '15px',
    marginBottom: '20px',
    background: '#f4f6f8',
  },
  paperInput: {
    height: '44px',
    display: 'flex',
    padding: '10px',
    alignItems: 'center',
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: '1',
  },
  searchButton: {
    // marginLeft: '16px',
    // width: '100%',
  },
  searchContainer: {},
  cardContent: {
    paddingBottom: '1em !important',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
  paginatorContainer: {
    marginTop: '1em',
  },
  iconButton: {
    padding: 10,
  },
});

function Table({ columns, data, pageCount: controlledPageCount, loading }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
    },
    useSortBy,
    usePagination
  );

  const handleChangeRows = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    gotoPage(0);
  };

  const handlePageChange = (event, newPage) => {
    gotoPage(newPage);
  };

  // Render the UI for your table
  return (
    <React.Fragment>
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps(
                    column.getSortByToggleProps([
                      {
                        className: column.className,
                        style: column.style,
                      },
                    ])
                  )}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {loading && (
            <TableRow key="skeletonRow" width={100}>
              <TableCell colSpan="8" align="center">
                <Skeleton count={5} height={50} />
              </TableCell>
            </TableRow>
          )}
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                      ])}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </MaUTable>
      <Grid
        container
        direction="column"
        alignContent="flex-end"
        alignItems="flex-end"
        style={{ marginTop: '1em', marginBottom: '1em' }}
      >
        <Grid item>
          <TablePagination
            component="div"
            page={pageIndex}
            count={rows.length}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRows}
            onPageChange={handlePageChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export const VehiclesList = () => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    status: '',
  });

  const [vehicles, setVehicles] = React.useState([]);
  const [initialVehicles, setInitialVehicles] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = React.useState(false);
  const [searchStatus, setSearchStatus] = React.useState(false);

  const searchInput = useRef();

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'UnidadID',
        style: {
          textAlign: 'center',
        },
      },
      {
        Header: 'NÚMERO',
        accessor: (d) => {
          return d.Numero;
        },
      },
      {
        Header: 'TITULAR',
        accessor: 'Titular',
      },
      {
        Header: 'TIPO UNIDAD',
        accessor: 'TipoUnidad',
        style: {
          textAlign: 'center',
        },
      },
      {
        Header: 'RUTA',
        accessor: 'NombreRuta',
      },
      {
        Header: 'CHOFER',
        accessor: 'NombreChofer',
      },
      {
        Header: 'ESTADO',
        accessor: (d) => {
          return (
            <React.Fragment>
              {d.EstaActivo ? (
                <Chip
                  icon={<CheckIcon style={{ color: green[700] }} />}
                  label="Activa"
                  className={classes.chipActive}
                />
              ) : (
                <Chip
                  icon={<CloseIcon style={{ color: red[700] }} />}
                  label="Inactiva"
                  className={classes.chipInactive}
                />
              )}
            </React.Fragment>
          );
        },
      },
      {
        Header: 'ACCIONES',
        accessor: (d) => {
          return (
            <React.Fragment>
              <Tooltip title="Ver detalle">
                <IconButton
                  color="secondary"
                  size="small"
                  href={`/vehicles/detail/${d.UnidadID}`}
                  aria-label="see detail"
                >
                  <ZoomInIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Editar">
                <IconButton
                  color="secondary"
                  size="small"
                  href={`/vehicles/edit/${d.UnidadID}`}
                  aria-label="edit passengers"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    setIsLoading(true);

    setTimeout(function () {
      //Request
      VehiclesService.getList()
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          setIsLoading(false);
          //Get Routes
          if (response && response.Data && response.Data.length > 0) {
            setVehicles(response.Data);
            setInitialVehicles(response.Data);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 1000);
  }, []);

  const handleFilters = (event) => {
    setFilters(filters ? false : true);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const findVehicle = () => {
    let resultSet = [];

    if (searchInput.current.value === '') {
      clearSearchField();
      return;
    }

    for (var i = 0; i < initialVehicles.length; i++) {
      if (initialVehicles[i].Numero.search(searchInput.current.value) !== -1) {
        resultSet = [...resultSet, ...[initialVehicles[i]]];
      }
    }
    setVehicles(resultSet);
    setSearchStatus(true);
  };

  const findVehicleKey = (e) => {
    if (e.charCode === 13) {
      findVehicle();
    }
  };

  const clearSearchField = () => {
    setSearchStatus(false);
    setVehicles(initialVehicles);
    searchInput.current.value = '';
  };

  return (
    <React.Fragment>
      <Paper elevation={3} className={classes.paperFilter}>
        <Grid
          container
          spacing={1}
          alignContent="center"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Paper elevation={1} className={classes.paperInput}>
              <SearchIcon className={classes.searchIcon} />
              <InputBase
                inputRef={searchInput}
                placeholder="Buscar"
                onKeyPress={findVehicleKey}
              />
              {searchStatus && (
                <IconButton
                  onClick={clearSearchField}
                  className={classes.iconButton}
                  aria-label="clear"
                >
                  <DeleteOutlineIcon />
                </IconButton>
              )}
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={findVehicle}
              className={classes.searchButton}
              width={1}
            >
              Buscar
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4} align="right">
            <Button
              component={Link}
              to="/vehicles/create"
              size="large"
              variant="contained"
              color="primary"
            >
              Nueva Unidad
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={3} className={classes.paperFilter}>
        <Grid container className={classes.header} direction="column">
          <Grid item sm={12} xs={12}>
            <TableContainer component={Paper}>
              <Table columns={columns} data={vehicles} loading={isLoading} />
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};
