import request from './../request';
import { getAuthorizationToken } from './../session';

function getList() {
  return request({
    url: '/Parada/Paradas/',
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function get(stopId) {
  return request({
    url: `/Parada/Paradas/${stopId}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function create(data) {
  return request({
    url: '/Parada/Paradas/',
    method: 'POST',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      Nombre: data.name,
      Descripcion: data.description,
      Latitud: data.latitude,
      Longitud: data.longitude,
      EstaActivo: data.status,
      ClienteCiudadID: data.companyId,
    },
  });
}

function update(stopId, data) {
  return request({
    url: `/Parada/Paradas/`,
    method: 'PUT',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      ParadaID: stopId,
      Nombre: data.name,
      Descripcion: data.description,
      Latitud: data.latitude,
      Longitud: data.longitude,
      EstaActivo: data.status,
      ClienteCiudadID: data.companyId,
    },
  });
}

function deleteItem(routeId) {
  return request({
    url: `/Ruta/Rutas/`,
    method: 'DELETE',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      RutaID: routeId,
    },
  });
}

function getRoutes(routeId) {
  return request({
    url: `/Parada/rutasPorParada/${routeId}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function getStopsRoutes(routeId) {
  return request({
    url: `/Parada/rutaParadasDetalle/${routeId}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function getRouteStopsDetail(routeStopId) {
  return request({
    url: `/Parada/rutaParada/${routeStopId}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function assignRouteStop(routeId, stopId, data) {
  return request({
    url: '/Parada/Paradas/',
    method: 'POST',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      RutaID: routeId,
      ParadaID: stopId,
      Horario: data.time,
      Orden: parseInt(data.order),
      Orientacion: data.orientation,
      EstaActivoRutaParada: data.status,
      EstaActivo: data.status,
    },
  });
}

function unassignRouteStop(routeStopId) {
  return request({
    url: 'Parada/Paradas',
    method: 'DELETE',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      RutaParadaID: routeStopId,
    },
  });
}

function updateRouteStop(routeStopId, routeId, stopId, data) {
  return request({
    url: `/Parada/Paradas/`,
    method: 'PUT',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      ParadaRelacionID: stopId,
      RutaParadaID: routeStopId,
      RutaID: parseInt(routeId),
      Horario: data.time,
      Orden: parseInt(data.order),
      Orientacion: data.orientation,
      EstaActivoRutaParada: data.status,
      EstaActivo: data.status,
    },
  });
}

function deleteRoutePoints(data) {
  return request({
    url: '/RutaCoordenada/Coordenadas/',
    method: 'DELETE',
    headers: { Authorization: getAuthorizationToken() },
    data: data,
  });
}

const StopsService = {
  get,
  getList,
  create,
  update,
  deleteItem,
  getRoutes,
  getStopsRoutes,
  getRouteStopsDetail,
  assignRouteStop,
  unassignRouteStop,
  updateRouteStop,
  deleteRoutePoints,
};

export default StopsService;
