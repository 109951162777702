import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Route } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

const breadcrumbNameMap = {
  '/dashboard': 'Inicio',
  '/routes': 'Rutas',
  '/routes/create': 'Nueva Ruta',
  '/routes/detail/:routeId': 'Detalle de la Ruta',
  '/routes/edit/:routeId': 'Editar Ruta',
  '/stops': 'Paradas',
  '/stops/create': 'Nueva Parada',
  '/stops/detail/:stopId': 'Detalle de la Parada',
  '/stops/edit/:stopId': 'Editar la Parada',
  '/vehicles': 'Unidades',
  '/vehicles/detail/:vehicleId': 'Detalle de la Unidad',
  '/vehicles/edit/:vehicleId': 'Editar Unidades',
  '/drivers': 'Choferes',
  '/drivers/detail/:driverId': 'Detalle del Chofer',
  '/drivers/edit/:driverId': 'Editar Chofer',
  '/drivers/create': 'Nuevo Chofer',
  '/reports': 'Reportes',
  '/reports/travels': 'Reporte de Viajes',
  '/reports/capacity': 'Reporte de Aforo',
  '/reports/passengers': 'Reporte de Pasajeros',
  '/notifications': 'Notificaciones',
  '/notifications/create': 'Nueva Notificación',
  '/notifications/detail/:notificationId': 'Detalle de Notificación',
  '/notifications/edit/:notificationId': 'Editar Notificación',
  '/passengers': 'Pasajeros',
  '/passengers/create': 'Nuevo Pasajero',
  '/passengers/detail/:passengerId': 'Detalle del Pasajero',
  '/passengers/batch': 'Insertar Pasajeros en Masa',
  '/profile': 'Mi Perfil',
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  breadcrumb: {
    marginBottom: '1rem',
  },
}));

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

export default function BreadcrumbsComponent() {
  const classes = useStyles();

  
  return (
    <div className={classes.root}>
      <Route>
        {({ location, match }) => {
          const pathnames = match.path.split('/').filter((x) => x);

          return (
            <Breadcrumbs className={classes.breadcrumb} aria-label="breadcrumb">
              {pathnames[0] !== 'dashboard' && (
                <LinkRouter color="inherit" to="/dashboard">
                  Inicio
                </LinkRouter>
              )}

              {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                if (breadcrumbNameMap[to] !== undefined) {
                  return last ? (
                    <Typography color="textPrimary" key={to}>
                      {breadcrumbNameMap[to]}
                    </Typography>
                  ) : (
                    <LinkRouter color="inherit" to={to} key={to}>
                      {breadcrumbNameMap[to]}
                    </LinkRouter>
                  );
                }
              })}
            </Breadcrumbs>
          );
        }}
      </Route>
    </div>
  );
}
