import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

import Can from './../../Can';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { green, red } from '@material-ui/core/colors';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert, AlertTitle } from '@material-ui/lab';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplyIcon from '@material-ui/icons/Reply';

import VehiclesServices from './../../../services/api/vehicles';

import { RouteSelect } from './../../Common/Selects/Route';
import { DriverSelect } from './../../Common/Selects/Driver';
import { CompanySelect } from './../../Common/Selects/Company';

import { getSessionCookie } from './../../../services/session';

const useStyles = makeStyles({
  table: {
    marginTop: '10px',
  },
  cardContent: {
    padding: '0px !important',
  },
  card: {
    marginTop: '1rem',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
  select: {
    width: '100%',
    marginTop: '1em',
    marginBottom: '1em',
  },
});

const RedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

const GreenButton = withStyles((theme) => ({
  root: {
    color: 'white', //theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

export const VehiclesEdit = () => {
  const classes = useStyles();
  let history = useHistory();
  let { vehicleId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [requestError, setRequestError] = useState({
    error: false,
    errorDescription: null,
  });

  const [companyId, setCompanyId] = useState(undefined);
  const [role, setRole] = useState('logged');

  const [vehicle, setVehicle] = React.useState({});

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStatusChange = (event) => {
    setValue('status', event.target.value);
    setVehicle({ ...vehicle, status: event.target.value });
  };

  const handleRouteIdChange = (routeId) => {
    setValue('route', routeId);
    setVehicle({ ...vehicle, route: routeId });
  };

  const handleDriverIdChange = (driverId) => {
    setValue('driver', driverId);
    setVehicle({ ...vehicle, driver: driverId });
  };

  const handleBackClick = () => {
    history.goBack();
  };

  let vehicleSchema = yup.object().shape({
    number: yup.string().required(),
    headline: yup.string().required(),
    imei: yup.string(),
    driver: yup.string().required(),
    route: yup.string().required(),
    status: yup.boolean().required(),
    clientId: yup.string()
  });

  const { handleSubmit, errors, control, setValue, reset } = useForm({
    validationSchema: vehicleSchema,
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    setRequestError({ error: false, errorDescription: null });

    //admin

    //Request
    setTimeout(function () {
      //Request
      VehiclesServices.update(vehicleId, data)
        .then((response) => {
          if (response === null || !response.IsValid) {
            setForm(vehicle);
            throw response.Message;
          }
          if (response.IsValid && response.Data !== undefined) {
            history.push(`/vehicles/detail/${response.Data.UnidadID}`);
          } else {
            throw response.Message;
          }
        })
        .catch((e) => {
          setRequestError({
            error: true,
            errorDescription: e.status ? e.Data.Message : e,
          });
          setIsLoading(false);
        });
    }, 500);
  };

  const handleDelete = () => {
    setTimeout(function () {
      //Request
      VehiclesServices.deleteItem(vehicleId)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          if (response.IsValid && response.Data !== undefined) {
            setOpen(false);
            history.push(`/vehicles/`);
          } else {
            throw response.Message;
          }
        })
        .catch((e) => {
          setRequestError({
            error: true,
            errorDescription: e.status ? e.Data.Message : e,
          });
          setOpen(false);
          setIsLoading(false);
        });
    }, 1000);
  };

  useEffect(() => {
    setIsLoading(true);
    VehiclesServices.get(vehicleId)
      .then((response) => {
        if (response === null || !response.IsValid) {
          throw response.Message;
        }
        //
        //Get Routes
        if (response && response.Data !== undefined) {
          reset({
            number: response.Data.Numero ? response.Data.Numero : '',
            headline: response.Data.Titular ? response.Data.Titular : '',
            imei: response.Data.Imei ? response.Data.Imei : '',
            driver: response.Data.ChoferUsuarioID
              ? response.Data.ChoferUsuarioID
              : '',
            route: response.Data.RutaID ? response.Data.RutaID : '',
            status: response.Data.EstaActivo ? response.Data.EstaActivo : '',
            companyId: response.Data.ClienteID ? response.Data.ClienteID : '',
          });
          setVehicle({
            number: response.Data.Numero,
            headline: response.Data.Titular,
            imei: response.Data.Imei,
            driver: response.Data.ChoferUsuarioID,
            route: response.Data.RutaID,
            status: response.Data.EstaActivo,
            companyId: response.Data.ClienteID,
          });
          setIsLoading(false);
        } else if (response.Data === null) {
          history.push(`/vehicles/`);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);


  useEffect(() => {
    let session = getSessionCookie();

    if (
      session !== undefined &&
      session.role !== undefined &&
      session.role !== null
    ) {
      setRole(session.role);
    }
  }, [vehicle.companyId]);

  const setForm = (data) => {
    setValue('number', data.Numero);
    setValue('headline', data.Titular);
    setValue('driver', data.ChoferUsuarioID);
    setValue('imei', data.Imei);
    setValue('route', data.RutaID);
    setValue('status', data.EstaActivo ? true : false);
    setValue('clientId', data.ClienteID ? true : false);
  };

  const handleCompanyChange = (value) => {
    setCompanyId(value);
  };

  return (
    <React.Fragment>
      <Card className={classes.card} style={{ background: '#f4f6f8' }}>
        <CardContent>
          <form
            className={classes.form}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            {isLoading ? (
              <Skeleton count={5} height={50} />
            ) : (
              <React.Fragment>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignContent="center"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Grid item sm={6} xs={12}>
                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="number"
                      value={vehicle.number}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="number"
                      label="Numero"
                      autoComplete="number"
                      autoFocus
                      error={errors.number ? true : false}
                    />
                    <Can
                      role={role}
                      perform="superadmin"
                      yes={() => (
                        <CompanySelect
                          parentCallback={handleCompanyChange}
                          control={control}
                          companyId={vehicle.companyId}
                          error={errors.companyId ? true : false}
                        />
                      )}
                      no={() => ''}
                    />
                    <RouteSelect
                      parentCallback={handleRouteIdChange}
                      routeId={vehicle.route}
                      control={control}
                      companyId={companyId}
                      error={errors.route ? true : false}
                    />

                    <Can
                      role={role}
                      perform="superadmin"
                      yes={() => (
                        <Controller
                        as={<TextField />}
                        control={control}
                        name="imei"
                        value={vehicle.imei}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="imei"
                        label="IMEI"
                        autoComplete="imei"
                        error={errors.imei ? true : false}
                      />
                      )}
                      no={() => ''}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="headline"
                      value={vehicle.headline || ''}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="headline"
                      label="Titular"
                      autoComplete="headline"
                      error={errors.headline ? true : false}
                    />

                    <DriverSelect
                      parentCallback={handleDriverIdChange}
                      routeId={vehicle.driver}
                      control={control}
                      error={errors.driver ? true : false}
                    />

                    <Controller
                      as={
                        <FormControl
                          variant="outlined"
                          className={classes.select}
                        >
                          <InputLabel id="status-label">Estado</InputLabel>
                          <Select
                            labelId="status-label"
                            id="status"
                            autoWidth
                            value={vehicle.status}
                            onChange={handleStatusChange}
                            label="Estado"
                          >
                            <MenuItem value={true}>Activo</MenuItem>
                            <MenuItem value={false}>Inactivo</MenuItem>
                          </Select>
                        </FormControl>
                      }
                      className={classes.select}
                      name="status"
                      defaultValue={vehicle.status}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs sm>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <GreenButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.button}
                          startIcon={<SaveIcon />}
                        >
                          Guardar Cambios
                        </GreenButton>
                      </Grid>
                      <Grid item>
                        <RedButton
                          variant="contained"
                          color="secondary"
                          size="large"
                          onClick={handleClickOpen}
                          className={classes.button}
                          startIcon={<DeleteIcon />}
                        >
                          Eliminar
                        </RedButton>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={handleBackClick}
                          variant="contained"
                          color="secondary"
                          size="large"
                          className={classes.button}
                          startIcon={<ReplyIcon />}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {requestError.error && (
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-end"
                  >
                    <Grid item xs>
                      <Alert severity="error">
                        <AlertTitle>Algo salió mal</AlertTitle>
                        {requestError.errorDescription}
                      </Alert>
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            )}
          </form>
        </CardContent>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Esta seguro que desea eliminar esta Unidad?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
