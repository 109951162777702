import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Skeleton from 'react-loading-skeleton';
import GoogleMapReact from 'google-map-react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { green, red } from '@material-ui/core/colors';
import CardContent from '@material-ui/core/CardContent';
import { Alert, AlertTitle } from '@material-ui/lab';
import Chip from '@material-ui/core/Chip';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';

import Typography from '@material-ui/core/Typography';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ReplyIcon from '@material-ui/icons/Reply';

import stopMarker from './../../../assets/images/maps/ic_stop.png';

import { RoutesTable } from '../RoutesTable';

import { CompanyCitySelect } from './../../Common/Selects/CompanyCity';

import StopsService from './../../../services/api/stops';

import { getSessionCookie } from './../../../services/session';

const useStyles = makeStyles({
  table: {
    marginTop: '10px',
  },
  cardContent: {
    padding: '0px !important',
  },
  card: {
    marginTop: '1rem',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
  select: {
    width: '100%',
    // marginTop: '1em',
    // marginBottom: '1em',
  },
  clientSelect: {
    marginBottom: '12px',
  },
});

export const StopsDetail = () => {
  const classes = useStyles();

  let { stopId, routeStopId } = useParams();

  const [center, setCenter] = React.useState({
    lat: 29.0836781,
    lng: -110.959494,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState({
    error: false,
    errorDescription: null,
  });
  const [routes, setRoutes] = useState([]);
  const [role, setRole] = React.useState('logged');

  const [stop, setStop] = useState({
    latitude: 'a',
    longitude: 'as',
  });

  const handleMapLoaded = (map, maps) => {
    var imageGoing = {
      url: stopMarker,
      size: new maps.Size(26, 26),
      scaledSize: new maps.Size(26, 26),
    };

    let marker;

    marker = new maps.Marker({
      map: map,
      draggable: false,
      icon: imageGoing,
      // animation: maps.Animation.DROP,
      position: { lat: center.lat, lng: center.lng },
    });
  };

  useEffect(() => {
    let session = getSessionCookie();

    if (
      session !== undefined &&
      session.role !== undefined &&
      session.role !== null
    ) {
      setRole(session.role);
    }

    setIsLoading(true);

    setTimeout(function () {
      //Request
      StopsService.get(stopId)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }

          //Get Routes
          if (response && response.Data !== undefined) {
            setStop({
              id: response.Data.ParadaID,
              name: response.Data.Nombre,
              description: response.Data.Descripcion,
              latitude: response.Data.Latitud,
              longitude: response.Data.Longitud,
              status: response.Data.EstaActivo,
            });
            setCenter({
              lat: parseFloat(response.Data.Latitud),
              lng: parseFloat(response.Data.Longitud),
            });
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 500);
  }, [stopId]);

  return (
    <React.Fragment>
      <Card style={{ background: '#f4f6f8' }}>
        <CardContent>
          {isLoading ? (
            <Skeleton count={1} height={50} />
          ) : (
            <Grid
              container
              spacing={2}
              direction="row"
              alignContent="center"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={9}>
                <Typography variant="h6" display="block" gutterBottom>
                  {stop.name}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  ID: {stop.id}
                </Typography>
              </Grid>
              <Grid>
                <Tooltip title="Regresar">
                  <IconButton
                    color="secondary"
                    size="medium"
                    href={`/stops/`}
                    aria-label="back to stops"
                  >
                    <ReplyIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Editar">
                  <IconButton
                    color="secondary"
                    size="medium"
                    href={`/stops/edit/${stop.id}`}
                    aria-label="edit stop"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      <Card className={classes.card} style={{ background: '#f4f6f8' }}>
        <CardContent>
          {isLoading ? (
            <Skeleton count={5} height={50} />
          ) : (
            <React.Fragment>
              <Grid
                container
                spacing={2}
                direction="row"
                alignContent="center"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <Grid item xs>
                  <Typography variant="overline" display="block" gutterBottom>
                    Descripción
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {stop.description}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                direction="row"
                alignContent="center"
                alignItems="flex-start"
                justifyContent="space-between"
                style={{ marginTop: '5px' }}
              >
                <Grid item sm={4} xs={12}>
                  <Typography variant="overline" display="block" gutterBottom>
                    Latitud
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {stop.latitude}
                  </Typography>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Typography variant="overline" display="block" gutterBottom>
                    Longitud
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {stop.longitude}
                  </Typography>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Typography variant="overline" display="block" gutterBottom>
                    Estado
                  </Typography>
                  {stop.status ? (
                    <Chip
                      icon={<CheckIcon style={{ color: green[700] }} />}
                      label="Activa"
                      className={classes.chipActive}
                    />
                  ) : (
                    <Chip
                      icon={<CloseIcon style={{ color: red[700] }} />}
                      label="Inactiva"
                      className={classes.chipInactive}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                direction="row"
                alignContent="center"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <Grid item xs>
                  <div
                    style={{
                      height: '350px',
                      minHeight: '350px',
                      width: '100%',
                      marginTop: '10px',
                    }}
                  >
                    <GoogleMapReact
                      draggable={false}
                      bootstrapURLKeys={{
                        key: process.env.REACT_APP_MAPS_KEY,
                      }}
                      defaultCenter={center}
                      defaultZoom={12}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={({ map, maps }) =>
                        handleMapLoaded(map, maps)
                      }
                    ></GoogleMapReact>
                  </div>
                </Grid>
              </Grid>

              {requestError.error && (
                <Grid container spacing={1} direction="row" justifyContent="flex-end">
                  <Grid item xs>
                    <Alert severity="error">
                      <AlertTitle>Algo salió mal</AlertTitle>
                      {requestError.errorDescription}
                    </Alert>
                  </Grid>
                </Grid>
              )}
            </React.Fragment>
          )}
        </CardContent>
      </Card>
      <Card className={classes.card} style={{ background: '#f4f6f8' }}>
        <CardContent>
          <RoutesTable />
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
