import React, { useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd'; 
import DeleteIcon from '@material-ui/icons/Delete'; 

import { TableSelectable } from '../../Common/Table/table_selectable.js'

import { useStyles } from './styles';

import UsersServices from './../../../services/api/users';

export const PassengersList = () => {

  const classes = useStyles();

  const [passengers, setPassengers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [deleteBtnDisabled, setDeleteBtnDisabled] = useState(false);

  const columns = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'UsuarioID',
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'CLIENTE',
      accessor: (d) => {
        return d.NombreTipoUsuario;
      },
    },
    {
      Header: 'NOMBRE',
      accessor: 'Nombre',
    },
    {
      Header: 'TELÉFONO',
      accessor: 'Telefono1',
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'USUARIO',
      accessor: 'NombreUsuario',
    },
    {
      Header: 'PUESTO',
      accessor: 'Puesto',
    },
    {
      Header: 'ACCIONES',
      accessor: (d) => {
        return (
          <React.Fragment>
            <Tooltip title="Ver detalle">
              <IconButton
                color="secondary"
                size="small"
                href={`/passengers/detail/${d.UsuarioID}`}
                aria-label="see detail"
              >
                <ZoomInIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Editar">
              <IconButton
                color="secondary"
                size="small"
                href={`/passengers/edit/${d.UsuarioID}`}
                aria-label="edit passengers"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ], []);

  useEffect(() => {
    fetchPassengers();
  }, []);

  const fetchPassengers = () => {
    UsersServices.getPassengers()
      .then((response) => {
        if (response === null || !response.IsValid) {
          throw response.Message;
        }
        //Get Passengers
        if (response && response.Data && response.Data.length > 0) {
          setPassengers(response.Data);
          setIsLoading(false);
          setShowDelete(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

  const handleSearch = () => {
    const results = passengers.filter(item => item.Nombre.toLowerCase().includes(search.toLowerCase()))
    setSearchResults(results);
  };

  const handleRowSelection = (rows) => {
    if (Object.keys(rows).length) {
      // Show Button
      setShowDelete(true);
      setSelectedRows(rows);
    } else {
      setShowDelete(false);
    }
  }

  const deleteBatchPassengers = () => {
    //
    if (!selectedRows) {
      return;
    };

    setIsLoading(true);
    setPassengers([]);
    setDeleteBtnDisabled(true);
    UsersServices.deletePassengerBatch(selectedRows)
      .then((response) => {
        if (response === null || !response.IsValid) {
          throw response.Message;
        }
        if (response.IsValid && response.Data !== undefined) {
          fetchPassengers();
          setDialogVisible(false);
          setDeleteBtnDisabled(false);
        } else {
          throw response.Message;
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  return (
    <React.Fragment>
      <Grid container className={classes.header} direction="column">
        <Grid item sm={12} xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container className={classes.header} direction="row" justifyContent="space-between">
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <Paper elevation={1} className={classes.paper}>
                    <SearchIcon className={classes.searchIcon} />
                    <InputBase onChange={ event => setSearch(event.target.value)} placeholder="Buscar" className={classes.searchInput} />
                    <Divider className={classes.divider} orientation="vertical" />
                    <Button
                      fullWidth
                      size="small"
                      variant="contained"
                      color="secondary"
                      className={classes.filterBtn}
                      startIcon={<SearchIcon />}
                      onClick={handleSearch}
                    >
                      Buscar
                    </Button>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                  { showDelete && 
                    <IconButton 
                      onClick={() => setDialogVisible(true)}
                      aria-label="delete" 
                      className={classes.deleteIcon} 
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    className={classes.actionBtn}
                    startIcon={<PersonAddIcon />}
                    component={Link}
                    to="/passengers/create"
                  >
                    Nuevo Pasajero
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    className={classes.actionBtn}
                    startIcon={<GroupAddIcon />}
                    component={Link}
                    to="/passengers/batch"
                  >
                    Multiples Pasajeros
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} xs={12}>
          <TableSelectable
            columns={columns}
            data={searchResults.length > 0 ? searchResults : passengers}
            isLoading={isLoading}
            rowSelection={handleRowSelection}
          />
        </Grid>
      </Grid>
      <Dialog
        open={dialogVisible}
        onClose={() => setDialogVisible(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Esta seguro que desea eliminar estos Pasajeros?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogVisible(false)} color="primary">
            Cancelar
          </Button>
          <Button disabled={deleteBtnDisabled} onClick={deleteBatchPassengers} color="primary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
