import React from 'react';

import Grid from '@material-ui/core/Grid';

import DashboardLayout from '../../layouts/dashboard';
import { DriversEdit } from '../../../components/Drivers/DriversEdit';

export const DriversEditPage = () => {
  return (
    <DashboardLayout title={'Unidades'}>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs>
          <DriversEdit />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};
