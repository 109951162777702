import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';

import * as yup from 'yup';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Alert, AlertTitle } from '@material-ui/lab';

import UsersServices from './../../services/api/users';
import { getLoggedUserId } from './../../services/session';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  box: {
    display: 'flex',
  },
  avatar: {
    width: '110px',
    height: '110px',
    flexGrow: 0,
    flexShrink: 0,
    marginLeft: 'auto',
  },
}));

export const UserProfile = () => {
  const classes = useStyles();

  const [user, setUser] = useState({});
  const [refreshUser, setRefreshUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [requestError, setRequestError] = useState({
    error: false,
    errorDescription: null,
  });

  let loginSchema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().required(),
    phone_1: yup.string().required(),
    address: yup.string(),
    phone_2: yup.string(),
    password: yup.string().required(),
  });

  const { handleSubmit, errors, control, reset, register } = useForm({
    validationSchema: loginSchema,
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    setRequestError({ error: false, errorDescription: null });

    //Submit Request
    setTimeout(function () {
      //Request
      UsersServices.update(user.id, data)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }

          if (response.IsValid && response.Data !== undefined) {
            setRefreshUser(true);
          } else {
            throw response.Message;
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setRequestError({
            error: true,
            errorDescription: e.status ? e.Data.Message : e,
          });
          setIsLoading(false);
        });
    }, 500);
  };

  useEffect(() => {
    setIsLoading(true);

    setTimeout(function () {
      //Request
      UsersServices.getLoggedUser(getLoggedUserId())
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }

          //Get Routes
          if (response && response.Data !== undefined) {
            setUser({
              id: response.Data.UsuarioID,
              name: response.Data.Nombre ? response.Data.Nombre : '',
              email: response.Data.Email ? response.Data.Email : '',
              address: response.Data.Direccion ? response.Data.Direccion : '',
              phone_1: response.Data.Telefono1 ? response.Data.Telefono1 : '',
              phone_2: response.Data.Telefono2 ? response.Data.Telefono2 : '',
              password: response.Data.Contraseña
                ? response.Data.Contraseña
                : '',
            });

            reset({
              id: response.Data.UsuarioID,
              name: response.Data.Nombre ? response.Data.Nombre : '',
              address: response.Data.Direccion ? response.Data.Direccion : '',
              email: response.Data.Email ? response.Data.Email : '',
              phone_1: response.Data.Telefono1 ? response.Data.Telefono1 : '',
              phone_2: response.Data.Telefono2 ? response.Data.Telefono2 : '',
              expiration_date: moment(response.Data.FechaExpiracion).isValid()
                ? moment(response.Data.FechaExpiracion)
                : moment().add(1, 'M'),
              companyId: response.Data.ClienteID ? response.Data.ClienteID : '',
              clientId: response.Data.TipoUsuarioID
                ? response.Data.TipoUsuarioID
                : '',
              status: response.Data.Estatus,
              username: response.Data.NombreUsuario,
              password: response.Data.Contraseña,
              created_at: response.Data.FechaCreacion,
              categoryUserId: response.Data.CategoriaUsuarioID,
            });

            setRefreshUser(false);

            setIsLoading(false);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 500);
  }, [refreshUser]);

  return (
    <React.Fragment>
      <Grid container spacing={2} direction="row" alignContent="center">
        <Grid item sm={4} xs={12}>
          <Card className={classes.root}>
            <CardContent>
              <div className={classes.box}>
                <div>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {user.position}
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {user.name}
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    {user.phone1}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {user.email}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {user.client}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {user.type_user}
                  </Typography>
                </div>
                <Avatar className={classes.avatar} />
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={8} xs={12}>
          <Card className={classes.card} style={{ background: '#f4f6f8' }}>
            <CardContent>
              {isLoading ? (
                <Skeleton count={10} height={50} />
              ) : (
                <form
                  className={classes.form}
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <input
                    type="hidden"
                    readOnly
                    name="id"
                    value={user.id}
                    ref={register}
                  />

                  <Controller
                    as={<TextField />}
                    control={control}
                    rules={{ required: true }}
                    name="name"
                    value={user.name || ''}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    id="name"
                    label="Nombre Completo"
                    autoComplete="name"
                    autoFocus
                    error={errors.name ? true : false}
                  />

                  <Controller
                    as={<TextField />}
                    control={control}
                    name="email"
                    value={user.email || ''}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="email"
                    label="Email"
                    autoComplete="email"
                    error={errors.email ? true : false}
                  />
                  <Controller
                    as={<TextField />}
                    control={control}
                    name="address"
                    value={user.address || ''}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="address"
                    label="address"
                    autoComplete="address"
                    error={errors.address ? true : false}
                  />
                  <Controller
                    as={<TextField />}
                    control={control}
                    name="phone_1"
                    value={user.phone_1 || ''}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="phone1"
                    label="Telefono 1"
                    autoComplete="phone_1"
                    error={errors.phone_1 ? true : false}
                  />

                  <Controller
                    as={<TextField />}
                    control={control}
                    name="phone_2"
                    value={user.phone_2 || ''}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="phone_2"
                    label="Telefono 2"
                    autoComplete="phone_2"
                    error={errors.phone_2 ? true : false}
                  />

                  <Controller
                    as={<TextField />}
                    control={control}
                    rules={{ required: true }}
                    name="password"
                    value={user.password}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="password"
                    label="Contraseña"
                    autoComplete="password"
                    error={errors.password ? true : false}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Guardar Cambios
                  </Button>
                </form>
              )}
              {requestError.error && (
                <Grid container spacing={1} direction="row" justifyContent="flex-end">
                  <Grid item xs>
                    <Alert severity="error">
                      <AlertTitle>Algo salió mal</AlertTitle>
                      {requestError.errorDescription}
                    </Alert>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
