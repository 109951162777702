import React, { useState, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';

import Skeleton from 'react-loading-skeleton';
import { DropzoneArea } from 'material-ui-dropzone';
import moment from 'moment';

import { DataGrid } from '@mui/x-data-grid';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { green, red } from '@material-ui/core/colors';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

import Can from './../../Can';

import UsersServices from './../../../services/api/users';

import useRole from './../../../hooks/useRole';
import useCompanies from './../../../hooks/useCompanies';
import useClients from './../../../hooks/useClients';
import useRoutes from '../../../hooks/useRoutes';
import useRoutesCompanies from '../../../hooks/useRoutesCompanies';

import loading from './../../../assets/images/loading.gif' 

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: '10px'
  },
  th: {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
  tableCell: {
    padding: '4px !important',
  },
  cardContent: {
    paddingBottom: '16px !important',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
  select: {
    width: '100%',
    marginTop: '1em',
    marginBottom: '1em',
  },
  clientSelect: {
    marginBottom: '12px',
  },
  toast: {
    width: '100%',
    marginTop: '15px',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  previewChip: {
    minWidth: '100%',
    maxWidth: '100%'
  },
  errorCard: {
    marginTop: '10px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const RedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

const GreenButton = withStyles((theme) => ({
  root: {
    color: 'white', //theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

export const PassengersBatch = () => {

  const history = useHistory();
  const classes = useStyles();

  const role = useRole();
  const [isLoading, setIsLoading] = useState(false);
  const [isExtendedLoading, setExtendedLoading] = useState(true);
  const [requestError, setRequestError] = useState({ error: false, errorDescription: null });
  const [showTable, setShowTable] = useState(false);
  const [showSelectors, setShowSelectors] = useState(false);
  const [showUploadCard, setShowUploadCard] = useState(true);
  const [showUploadButtons, setShowUploadButtons] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();
  const [processedData, setProcessedData] = useState([]);

  const [company, setCompany] = useState([]);
  const [client, setClient] = useState([]);
  const [route, setRoute] = useState(undefined);
  const [clientSelectDisabled, setClientSelectDisabled] = useState(true);
  const [routeSelectDisabled, setRouteSelectDisabled] = useState(true);
  const [companyError, setCompanyError] = useState(false);
  const [clientError, setClientError] = useState(false);
  
  const companies = useCompanies();
  const clients = useClients(company);
  const routes = useRoutesCompanies(client);

  const onSubmit = (event) => {
    event.preventDefault();

    setIsLoading(true);
    setShowTable(false);
    setShowSelectors(false);
    setShowUploadCard(false);
    
    UsersServices.createBatchPassengers( uploadedFile ).then((response) => {
      if (response === null || !response.IsValid) {
        throw response.Message;
      }
      if (response.IsValid && response.Data !== undefined) {
        setShowUploadCard(false);

        let transformedData = response.Data.map( item => {
          return {...item, id: item.NombreUsuario}
        });
        
        setProcessedData(transformedData);
        setShowTable(true);
        setShowSelectors(true);

        if (role === 'client') {
          setCompany('');
          setClientSelectDisabled(false);
        }
        setIsLoading(false);
      } else {
        throw response.Message;
      }
    }).catch((error) => {
      setRequestError({
        error: true,
        errorDescription: error.status ? error.Data.Message : error,
      });
      setIsLoading(false);
    });
  }

  const handleFileChange = (files) => {
    if (files.length){
      setUploadedFile(files[0]);
      setShowUploadButtons(true);
    } else {
      setShowUploadButtons(false);
    }
  };

  const handleRowChange = useCallback(
    ({ id, field, value }) => {
      let response = processedData.map( item => {
        if (item.NombreUsuario === id) {
          return { ...item, [field]: value }
        } else {
          return {...item}
        }
      });
      setProcessedData(response);
    },
    [processedData],
  );

  const submitPassengers = () => {

    let valid = true;

    if(role !== 'client'){
      if (company.length === 0) {
        valid = false;
        setCompanyError(true);
      } else {
        setCompanyError(false);
      }
    }
    
    if(client.length === 0) {
      valid = false;
      setClientError(true);
    } else {
      setClientError(false);
    }

    if (processedData.length === 0) {
      valid = false;
    }

    if (!valid){
      return;
    }

    setExtendedLoading(false);
    setIsLoading(true);
    setShowTable(false);
    setShowSelectors(false);

    const expirationDate = moment().add(6, 'M').utc().format();

    const formData = processedData.map((item) => {
      return {
        Nombre: item.Nombre,
        Direccion: item.Direccion,
        Email: item.Email,
        Telefono1: item.Telefono1,
        FechaExpiracion: expirationDate,
        Puesto: 'Pasajero',
        EstaActivo: true,
        Contraseña: item.Contraseña,
        NombreUsuario: item.NombreUsuario,
        FechaCreacion: new Date(),
        ClienteID: company,
        TipoUsuarioID: client,
        CategoriaUsuarioID: 3,
      };
    });

    setRequestError({ error: false, errorDescription: null});

    const data = {
      ...( route && {rutasId: [ route ]}),
      listUsuarios: formData
    }

    UsersServices.createBatchPassengersInsert(data)
      .then((response) => {
        if (response === null || !response.IsValid) {
          throw response.Message;
        }
        if (response.IsValid && response.Data !== undefined) {
          setIsLoading(false);
          history.push(`/passengers`);
        } else {
          throw response.Message;
        }
      })
      .catch((e) => {
        console.error(e);
        setRequestError({ error: true, errorDescription: e.status ? e.Data.Message : e});
        setIsLoading(false);
        setShowTable(true);
        setShowSelectors(true);
      });
  }

  const RowMenuCell = (props) => {
    const { api, id } = props;
    const classes = useStyles();
  
    const handleDeleteClick = (event) => {
      event.stopPropagation();
      api.updateRows([{ id, _action: 'delete' }]);
      const result = processedData.filter( item => item.NombreUsuario !== id);
      
      setProcessedData(result);
    };
  
    return (
      <div className={classes.root}>
        <IconButton onClick={handleDeleteClick} size="small" aria-label="delete" className={classes.margin}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
    );
  }

  const columns = [
    { field: 'Nombre', headerName: 'Nombre', width: 180, editable: true },
    { field: 'Direccion', headerName: 'Direción', width: 180, editable: true },
    { field: 'Email', headerName: 'Correo Electronico', width: 180, editable: true },
    { field: 'Telefono1', headerName: 'Telefono', width: 180, editable: true },
    { field: 'NombreUsuario', headerName: 'Nombre de Usuario', width: 180, editable: true },
    { field: 'Contraseña', headerName: 'Contraseña', width: 180, editable: true },
    {
      field: 'actions',
      headerName: 'Acciones',
      renderCell: RowMenuCell,
      sortable: false,
      width: 100,
      headerAlign: 'center',
      filterable: false,
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true,
    },
  ];
  
  return (
    <>
      { showUploadCard && 
        <Card className={classes.card} style={{ background: '#f4f6f8' }}>
          <CardContent className={classes.cardContent}>
            <form className={classes.form} onSubmit={onSubmit} noValidate>
              <>
                <Grid
                  container
                  spacing={1}
                  direction="column">
                  <Grid item xs sm>
                    <DropzoneArea
                      filesLimit={1}
                      showPreviews={true}
                      showPreviewsInDropzone={false}
                      useChipsForPreview
                      showAlerts={false}
                      previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                      previewChipProps={{classes: { root: classes.previewChip } }}
                      previewText="Archivo que se subira al servidor"
                      acceptedFiles={['.xls', '.xlsx']}
                      dropzoneText={"Arrastre y suelte su archivo aqui o de click."}
                      onChange={(files) => handleFileChange(files)}
                    />
                  </Grid>
                  <Grid item xs sm>
                    { showUploadButtons && 
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="flex-end"
                      >
                        <Grid item>
                          <GreenButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.button}
                            startIcon={<SaveIcon />}
                          >
                            Procesar Archivo
                          </GreenButton>
                        </Grid>
                        <Grid item>
                          <RedButton
                            component={Link}
                            to="/passengers"
                            variant="contained"
                            color="secondary"
                            size="large"
                            className={classes.button}
                            startIcon={<CancelIcon />}
                          >
                            Cancelar
                          </RedButton>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </>
            </form>
          </CardContent>
        </Card>
      }
      { requestError.error && 
        <Card className={classes.card, classes.errorCard} style={{ background: '#f4f6f8' }}>
          <CardContent className={classes.cardContent}>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-end"
            >
              <Grid item xs>
                <Alert severity="error">
                  <AlertTitle>Algo salió mal</AlertTitle>
                  {requestError.errorDescription}
                </Alert>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      }
      { showTable && 
        <Card className={classes.card, classes.table } style={{ background: '#f4f6f8' }}>
          <CardContent className={classes.cardContent}>
            <DataGrid 
              className={classes.dataGrid}
              rows={processedData}
              columns={columns}
              autoHeight={true}
              onCellEditCommit={handleRowChange}
              editMode="cell" />
          </CardContent>
        </Card>
      }
      { showSelectors && 
        <Card className={classes.card, classes.table } style={{ background: '#f4f6f8' }}>
          <CardContent className={classes.cardContent}>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Can
                    role={role}
                    perform="companies:read"
                    yes={() => (
                      <FormControl 
                        variant="outlined"
                        error={companyError}
                        className={`${classes.formControl} ${classes.select}`}>
                        <InputLabel id="company-label">Compañia</InputLabel>
                        <Select
                          labelId="company-label"
                          id="company"
                          value={company}
                          onChange={ (event) => { 
                            setCompany(event.target.value); 
                            setClientSelectDisabled(false);
                          }}
                          label="Compañia"
                        >
                          { companies.map( item => (
                            <MenuItem key={item.ClienteID} value={item.ClienteID}>
                              {item.NombreCliente}
                            </MenuItem> 
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    no={() => ''}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Can
                  role={role}
                  perform="clients:read"
                  yes={() => (
                    <FormControl 
                      variant="outlined" 
                      disabled={clientSelectDisabled}
                      error={clientError} 
                      className={`${classes.formControl} ${classes.select}`}>
                      <InputLabel id="client-label">Cliente</InputLabel>
                      <Select
                        labelId="client-label"
                        id="client"
                        value={client}
                        onChange={(event) => {
                          setClient(event.target.value);
                          setCompany(event.currentTarget.getAttribute('data-company'));
                          setRouteSelectDisabled(false);
                        }}
                        label="Cliente"
                      >
                        { clients.map( item => (
                          <MenuItem key={item.TipoUsuarioID} data-company={item.ClienteID} value={item.TipoUsuarioID}>
                            {item.Nombre}
                          </MenuItem> 
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  no={() => ''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Can
                  role={role}
                  perform="clients:read"
                  yes={() => (
                    <FormControl 
                        variant="outlined"
                        disabled={routeSelectDisabled}
                        error={companyError}
                        className={`${classes.formControl} ${classes.select}`}>
                        <InputLabel id="route-label">Ruta</InputLabel>
                        <Select
                          labelId="route-label"
                          id="route"
                          value={route}
                          onChange={ (event) => setRoute(event.target.value)}
                          label="Ruta"
                        >
                          { routes.map( item => (
                            <MenuItem key={item.RutaID} value={item.RutaID}>
                              {item.Nombre}
                            </MenuItem> 
                          ))}
                        </Select>
                      </FormControl>
                  )}
                  no={() => ''}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item>
                <GreenButton
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth={true}
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  onClick={() => submitPassengers()}
                >
                  Guardar Nuevos Pasajeros
                </GreenButton>
              </Grid>
              <Grid item>
                <RedButton
                  component={Link}
                  to="/passengers"
                  variant="contained"
                  color="secondary"
                  size="large"
                  fullWidth={true}
                  className={classes.button}
                  startIcon={<CancelIcon />}>
                  Cancelar
                </RedButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      }
      { isLoading &&
        <Card className={classes.card, classes.table } style={{ background: '#f4f6f8' }}>
          <CardContent className={classes.cardContent}>
            <Grid
              container
              spacing={1}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <img src={loading} alt="loading" />
              </Grid>
              <Grid item>
                <Typography variant="h4" component="h2">
                  { !isExtendedLoading 
                    ? 'Estamos creando los nuevos pasajeros.'
                    : 'Estamos cargando su archivo al sistema.'
                  }
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" component="h2">
                  Este proceso puede tardar varios minutos, por favor espere.
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      }
    </>
  )
}
