import { useState, useEffect } from 'react';

import ClientsService from './../services/api/clients';

export default function useCompanies(companyId = null) {

  const [clients, setClients] = useState([]);

  useEffect(() => {
    ClientsService.getList(companyId !== '' ? companyId : null)
      .then((response) => {
        if (response === null || !response.IsValid) {
          throw response.Message;
        }
        //Get Companies
        if (response && response.Data !== undefined) {
          setClients(response.Data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [companyId]);

  return clients;
}