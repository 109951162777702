import request from './../request';
import moment from 'moment';
import { getAuthorizationToken } from './../session';

function getUserDetail(userId) {
  return request({
    url: `/usuario/usuarios/${userId}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function getDrivers() {
  return request({
    url: '/usuario/usuarios/?categoriaUsuarioID=2',
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function update(userId, data) {
  return request({
    url: `/usuario/updateUsuario/`,
    method: 'PUT',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      UsuarioID: userId,
      Nombre: data.name,
      Direccion: data.address,
      Email: data.email,
      Telefono1: data.phone_1,
      Telefono2: data.phone_2,
      Contraseña: data.password,
    },
  });
}

function getPassengers() {
  return request({
    url: '/usuario/usuarios/?categoriaUsuarioID=3',
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function createPassenger(data) {
  return request({
    url: '/usuario/usuarios/',
    method: 'POST',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      Nombre: data.name,
      Direccion: data.address,
      Email: data.email,
      Telefono1: data.phone1,
      Telefono2: data.phone2,
      FechaExpiracion: moment(data.expiration_date).utc(),
      Puesto: 'Pasajero',
      EstaActivo: data.status,
      Contraseña: data.password,
      NombreUsuario: data.username,
      FechaCreacion: new Date(),
      ClienteID: data.companyId,
      TipoUsuarioID: data.clientId,
      CategoriaUsuarioID: 3,
      rutasDisponiblesPorUsuario: data.routes ? data.routes.map((_item) => {
        return { 
          RutaID: _item,
        }
      }) : []
    },
  });
}

function createBatchPassengers(data) {
  let formData = new FormData();

  formData.append('fileName', data);

  return request({
    url: '/Usuario/UploadUsersBatch',
    method: 'POST',
    timeout: 60 * 10 * 1000,
    headers: {
      Authorization: getAuthorizationToken(),
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
}

function createBatchPassengersInsert(data) {
  return request({
    url: '/Usuario/InsertarUsuariosBatch',
    method: 'POST',
    timeout: 60 * 10 * 1000,
    headers: {
      Authorization: getAuthorizationToken(),
    },
    data: data,
  });
}

function getPassenger(userId) {
  return request({
    url: `/usuario/usuarios/${userId}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
};

function getPassengerAvailableRoutes(userId) {
  return request({
    url: `/Ruta/rutasDisponiblesPorUsuario/${userId}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function getPassengerAvailableRoutesByClient(clientId) {
  return request({
    url: `/Ruta/rutasDisponiblesPorTipoUsuarioID/${clientId}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function updatePassenger(passengerId, data) {

  return request({
    url: `/usuario/usuarios/`,
    method: 'PUT',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      UsuarioID: passengerId,
      Nombre: data.name,
      Direccion: data.address,
      Email: data.email,
      Telefono1: data.phone1,
      Telefono2: data.phone2,
      Puesto: 'Pasajero',
      FechaExpiracion: moment(data.expiration_date).utc(),
      ClienteID: data.companyId,
      TipoUsuarioID: data.clientId,
      CategoriaUsuarioID: 3,
      EstaActivo: data.status,
      Contraseña: data.password,
      NombreUsuario: data.username,
      FechaCreacion: data.created_at,
      rutasDisponiblesPorUsuario: data.routes ? data.routes.map((_item) => {
        return { 
          UsuarioID: parseInt(passengerId),
          RutaID: _item,
        }
      }) : []
    },
  });
}

function deletePassenger(passengerId) {
  return request({
    url: `/usuario/usuarios/`,
    method: 'DELETE',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      UsuarioID: passengerId,
    },
  });
}

function deletePassengerBatch(ids) {
  return request({
    url: `/usuario/DeleteUsersBatch/`,
    method: 'DELETE',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      usuariosID: ids,
    },
  });
}

function getLoggedUser() {
  return request({
    url: '/usuario/me',
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

const UsersService = {
  getDrivers,
  getUserDetail,
  getPassengers,
  createPassenger,
  createBatchPassengers,
  createBatchPassengersInsert,
  getPassenger,
  getPassengerAvailableRoutes,
  getPassengerAvailableRoutesByClient,
  updatePassenger,
  deletePassenger,
  deletePassengerBatch,
  getLoggedUser,
  update,
};

export default UsersService;
