import React, { useEffect, useState } from 'react';
import { APP_URL } from './../../../shared/constants';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import 'moment/locale/es';

import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import { makeStyles } from '@material-ui/core/styles';

import { useTable, usePagination, useSortBy } from 'react-table';

import Card from '@material-ui/core/Card';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import DescriptionIcon from '@material-ui/icons/Description';

import SearchIcon from '@material-ui/icons/Search';
import { CardContent } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';

import { useForm } from 'react-hook-form';

import TablePagination from '@material-ui/core/TablePagination';

import { green, red } from '@material-ui/core/colors';

import ReportsServices from './../../../services/api/reports';
import UsersServices from './../../../services/api/users';

import { RouteSelect } from './../../Common/Selects/Route';

import { getSessionCookie, getLoggedUserId } from './../../../services/session';

const useStyles = makeStyles((theme) => ({
  body: {
    boxSizing: 'border-box',
  },
  table: {
    minWidth: 650,
  },
  th: {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
  searchIcon: {
    marginRight: '16px',
  },
  paper: {
    width: '100%',
    height: '56px',
    display: 'flex',
    padding: '0px 16px',
    alignItems: 'center',
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: '1',
    maxWidth: '480px',
    flexBasis: '480px',
  },
  searchButton: {
    marginLeft: '16px',
    marginRight: '5px',
  },
  searchContainer: {
    marginBottom: '1em',
    overflow: 'visible',
  },
  searchInput: {
    width: '100%',
  },
  cardContent: {
    paddingBottom: '1em !important',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
  paginatorContainer: {
    marginTop: '1em',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    fontWeight: 800,
  },
  pos: {
    marginBottom: 12,
  },
  analyticsIcons: {
    fontSize: 80,
    marginLeft: '1.5rem',
  },
  paperModal: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function Table({ columns, data, pageCount: controlledPageCount, loading }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
    },
    useSortBy,
    usePagination
  );

  const handleChangeRows = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    gotoPage(0);
  };

  const handlePageChange = (event, newPage) => {
    gotoPage(newPage);
  };

  // Render the UI for your table
  return (
    <React.Fragment>
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps(
                    column.getSortByToggleProps([
                      {
                        className: column.className,
                        style: column.style,
                      },
                    ])
                  )}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {loading && (
            <TableRow key="skeletonRow" width={100}>
              <TableCell colSpan="9" align="center">
                <Skeleton count={10} height={50} />
              </TableCell>
            </TableRow>
          )}
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                      ])}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </MaUTable>
      <Grid
        container
        direction="column"
        alignContent="flex-end"
        alignItems="flex-end"
        style={{ marginTop: '1em', marginBottom: '1em' }}
      >
        <Grid item>
          <TablePagination
            component="div"
            page={pageIndex}
            count={rows.length}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRows}
            onPageChange={handlePageChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export const ReportsTravels = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'REPORTE DE VIAJES',
        columns: [
          {
            Header: 'CLIENTE',
            accessor: 'Cliente',
          },
          {
            Header: 'VIAJE/RUTA',
            accessor: 'Ruta',
          },
          {
            Header: 'FECHA',
            accessor: 'Fecha',
            style: {
              textAlign: 'center',
            },
          },
          {
            Header: 'HORA INICIO',
            accessor: (d) => {
              return moment(d.HoraInicio, 'HH:mm:ss').local().format('hh:mm a');
            },
            style: {
              textAlign: 'center',
            },
          },
          {
            Header: 'HORA FIN',
            accessor: (d) => {
              return moment(d.HoraFin, 'HH:mm:ss').format('hh:mm a');
            },
          },
          {
            Header: 'No. PASAJEROS',
            accessor: (d) => {
              return d.NumeroPasajeros === null ? '-' : d.NumeroPasajeros;
            },
            style: {
              textAlign: 'center',
            },
          },
          {
            Header: 'CONDUCTOR',
            accessor: 'NombreConductor',
          },
          {
            Header: 'UNIDAD',
            accessor: 'NombreUnidad',
            style: {
              textAlign: 'center',
            },
          },
          {
            Header: 'ACCION',
            accessor: (d) => {
              return (
                <Tooltip title="Ver detalle">
                  <IconButton
                    color="secondary"
                    size="small"
                    href={`/reports/passengers/JornadaID=${d.JornadaID}`}
                    aria-label="see detail"
                  >
                    <ZoomInIcon />
                  </IconButton>
                </Tooltip>
              );
            },
            style: {
              textAlign: 'center',
            },
          },
        ],
      },
    ],
    []
  );

  const classes = useStyles();
  let session = getSessionCookie();

  const [focusedInput, setFocusedInput] = React.useState();

  const [startDate, setStartDate] = React.useState(
    moment().subtract(1, 'week')
  );
  const [endDate, setEndDate] = React.useState(moment());

  const [results, setResults] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [routeId, setRouteId] = React.useState('all');
  const [pageCount, setPageCount] = React.useState(0);
  const [clientId, setClientId] = React.useState(undefined);
  const [filters, setFilters] = React.useState('');
  const [role, setRole] = React.useState('logged');

  const { control } = useForm();

  //Handle Session Role
  useEffect(() => {
    let session = getSessionCookie();
    if (
      session !== undefined &&
      session.role !== undefined &&
      session.role !== null
    ) {
      setRole(session.role);
    }
  }, [role]);

  const handleRouteChange = (value) => {
    setRouteId(value);
  };

  useEffect(() => {
    setIsLoading(true);

    setTimeout(function () {
      UsersServices.getLoggedUser(getLoggedUserId())
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }

          if (response && response.Data !== undefined) {
            let filtersString = '';
            if (response.Data.ClienteID) {
              setClientId(response.Data.ClienteID);
              filtersString =
                filtersString + `ClienteID=${response.Data.ClienteID}&`;
            }

            //fechaInicio
            filtersString =
              filtersString +
              `fechaInicio=${startDate
                .startOf('day')
                .format('YMMDD H:mm:ss')}&`;
            //fechaFin
            if (endDate !== '') {
              filtersString =
                filtersString +
                `fechaFin=${endDate.endOf('day').format('YMMDD H:mm:ss')}`;
            }

            setFilters(filtersString);
            //Request
            ReportsServices.getTravels(filtersString)
              .then((response) => {
                if (response === null || !response.IsValid) {
                  throw response.Message;
                }
                setIsLoading(false);
                //Get Routes
                if (response && response.Data && response.Data.length > 0) {
                  setPageCount(Math.ceil(response.Data.length / 10));
                  setResults(response.Data);
                  setIsLoading(false);
                }
              })
              .catch((e) => {
                setIsLoading(false);
              });
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 500);
  }, [session.role]);

  const handleSearchClick = () => {
    setIsLoading(true);
    setResults([]);

    let filtersString = '';
    //RutaID
    if (routeId !== 'all') {
      filtersString = filtersString + `RutaID=${routeId}&`;
    }

    if (session.role !== 'superadmin' && clientId !== undefined) {
      filtersString = filtersString + `ClienteID=${clientId}&`;
    }

    //fechaInicio
    filtersString =
      filtersString +
      `fechaInicio=${startDate.startOf('day').format('YMMDD H:mm:ss')}&`;

    //fechaFin
    if (endDate !== '') {
      filtersString =
        filtersString +
        `fechaFin=${endDate.endOf('day').format('YMMDD H:mm:ss')}`;
    }

    setFilters(filtersString);

    setTimeout(function () {
      //Request
      ReportsServices.getTravels(filtersString)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          setIsLoading(false);
          //Get Routes
          if (response && response.Data && response.Data.length > 0) {
            setResults(response.Data);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 500);
  };

  const handleReportDownloadPdf = () => {
    // Get Report values
    // Fetch
    ReportsServices.getTravelsReport(filters)
      .then((response) => {
        if (response === null || !response.IsValid) {
          throw response.Message;
        }
        //Get Routes
        if (response && response.Data) {
          var win = window.open(
            `${APP_URL}${response.Data.nombreArchivo}`,
            '_blank'
          );
          win.focus();
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Card
        className={classes.searchContainer}
        style={{ background: '#f4f6f8', marginTop: '15px' }}
      >
        <CardContent>
          <Grid
            container
            className={classes.header}
            alignContent="center"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs>
              <div className={classes.searchBox}>
                <Paper elevation={1} className={classes.paper}>
                  <SearchIcon className={classes.searchIcon} />
                  <InputBase
                    className={classes.searchInput}
                    placeholder="Buscar"
                  />
                </Paper>
              </div>
            </Grid>

            <Grid item>
              <RouteSelect
                allRoutes={true}
                routeId={routeId}
                control={control}
                error={false}
                parentCallback={handleRouteChange}
              />
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ paddingLeft: '15px' }}
              >
                <DateRangePicker
                  startDate={startDate} // momentPropTypes.momentObj or null,
                  startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                  endDate={endDate} // momentPropTypes.momentObj or null,
                  isOutsideRange={(day) => moment().diff(day) < 0}
                  endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={(focusedInput) =>
                    setFocusedInput(focusedInput)
                  } // PropTypes.func.isRequired,
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                onClick={handleSearchClick}
                size="large"
                variant="contained"
                color="secondary"
                className={classes.searchButton}
              >
                Buscar
              </Button>
              {results.length > 0 && (
                <IconButton
                  onClick={handleReportDownloadPdf}
                  color="secondary"
                  aria-label="download report"
                  component="span"
                >
                  <DescriptionIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card style={{ background: '#f4f6f8' }}>
        <CardContent>
          <Grid container className={classes.header} direction="column">
            <Grid item sm={12} xs={12}>
              <TableContainer component={Paper}>
                <Table columns={columns} data={results} loading={isLoading} />
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
