import React, { useEffect, useState } from 'react';

import Skeleton from 'react-loading-skeleton';
import { Controller } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import RoutesService from './../../../../services/api/routes';

const useStyles = makeStyles({
  select: {
    width: '100%',
    marginTop: '1em',
    marginBottom: '1em',
  },
  formControl: {
    width: '100%',
    marginTop: '1.1em',
  },
});

export const RouteSelect = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [routes, setRoutes] = useState([]);
  const [routeId, setRouteID] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    setIsLoading(true);

    setTimeout(function () {
      //Request
      if(props.companyId){
        RoutesService.getListByClient(props.companyId)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          //
          //Get Routes
          if (response && response.Data !== undefined) {
            if (!isCancelled) {
              setRouteID(props.routeId);
              setIsLoading(false);
              setRoutes(response.Data);
            }
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
      }
      else{
        RoutesService.getList()
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          //
          //Get Routes
          if (response && response.Data !== undefined) {
            if (!isCancelled) {
              setRouteID(props.routeId);
              setIsLoading(false);
              setRoutes(response.Data);
            }
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
      }
    }, 1000);

    return () => {
      isCancelled = true;
    };
  }, [props.companyId]);

  const handleChange = (event) => {
    setRouteID(event.target.value);
    props.parentCallback(event.target.value);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Skeleton count={1} height={50} className={classes.formControl} />
      ) : (
        <Controller
          as={
            <FormControl variant="outlined" className={classes.select}>
              <InputLabel id="routeLabel">Ruta</InputLabel>
              <Select
                labelId="routeLabel"
                id="route"
                autoWidth
                value={routeId}
                onChange={handleChange}
                label="Ruta"
              >
                {props.allRoutes && (
                  <MenuItem selected value="all">
                    Todas las Rutas
                  </MenuItem>
                )}
                {routes && routes.map((route) => (
                  <MenuItem key={route.RutaID} value={route.RutaID}>
                    {route.Nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          className={classes.select}
          name="route"
          control={props.control}
          error={props.error}
        />
      )}
    </React.Fragment>
  );
};
