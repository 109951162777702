import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import DeleteIcon from '@material-ui/icons/Delete';

import Chip from '@material-ui/core/Chip';
import { green, red } from '@material-ui/core/colors';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import StopsService from './../../../services/api/stops';

import { StopsModalDetail } from './../../Stops/StopsModalDetail';
import { RouteAssign } from './../../Stops/RouteAssign';
import { RouteStopEdit } from './../../Stops/RouteStopEdit';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  th: {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
  header: {
    marginBottom: '20px',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
  orientationIcon: {
    width: '15px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const RouteStopsList = (props) => {
  const classes = useStyles();

  let { routeId } = useParams();

  const [stops, setStops] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [open, setOpen] = React.useState(false);
  const [modalDetailStatus, setModalDetailStatus] = React.useState(false);
  const [modalAssignStatus, setModalAssignStatus] = React.useState(false);
  const [modalEditStatus, setModalEditStatus] = React.useState(false);
  const [stopId, setStopId] = React.useState(undefined);
  const [routeStopId, setRouteStopId] = React.useState(undefined);

  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  const handleClickSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleAssignModal = () => {
    setModalAssignStatus(true);
  };

  const handleAssignClose = () => {
    setModalAssignStatus(false);
  };

  const handleEditClose = () => {
    setModalEditStatus(false);
  };

  const handleDeleteRouteStopClick = (clickedRouteStopId) => {
    setRouteStopId(clickedRouteStopId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStopDetailClicked = (clickedRouteStopId, clickedStopId) => {
    setRouteStopId(clickedRouteStopId);
    setStopId(clickedStopId);
    setModalDetailStatus(true);
  };

  const handleStopEditClicked = (clickedRouteStopId, clickedStopId) => {
    setRouteStopId(clickedRouteStopId);
    setStopId(clickedStopId);
    setModalEditStatus(true);
  };

  const handleModalDetailClose = () => {
    setModalDetailStatus(false);
  };

  const handleModalEditClose = () => {
    setModalEditStatus(false);
    //Trigger Fetch stops
    fetchStops();
  };

  const handleDelete = (routeStopId) => {
    setTimeout(function () {
      //Request
      StopsService.unassignRouteStop(routeStopId)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          if (response.IsValid && response.Data !== undefined) {
            setTimeout(function () {
              //Request
              StopsService.getStopsRoutes(routeId)
                .then((response) => {
                  if (response === null || !response.IsValid) {
                    throw response.Message;
                  }
                  setIsLoading(false);
                  //Get Routes
                  if (response && response.Data !== undefined) {
                    handleClickSnackBar();
                    setStops(response.Data);
                  }
                })
                .catch((e) => {
                  setIsLoading(false);
                });
            }, 500);
            handleClose();
          } else {
            throw response.Message;
          }
        })
        .catch((e) => {
          handleClose();
          setIsLoading(false);
        });
    }, 500);
  };

  useEffect(() => {
    setIsLoading(true);

    fetchStops();
  }, [routeId]);

  const fetchStops = () => {
    console.log('fetchStops');
    setTimeout(function () {
      //Request
      StopsService.getStopsRoutes(routeId)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          setIsLoading(false);
          //Get Routes
          if (response && response.Data !== undefined) {
            setStops(response.Data);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 500);
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        direction="row"
        alignContent="center"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Grid item>
          <h2>Paradas</h2>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            onClick={handleAssignModal}
            size="large"
            variant="contained"
            color="primary"
            className={classes.filterBtn}
          >
            Asignar nueva parada
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.th} align="center">
                ID
              </TableCell>
              <TableCell className={classes.th} align="left">
                Nombre
              </TableCell>
              <TableCell className={classes.th} align="center">
                Orden
              </TableCell>
              <TableCell className={classes.th} align="center">
                Hora
              </TableCell>
              <TableCell className={classes.th} align="center">
                Estado
              </TableCell>
              <TableCell className={classes.th} align="center">
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stops.map((row) => (
              <TableRow key={row.RutaParadaID}>
                <TableCell align="center">{row.RutaParadaID}</TableCell>
                <TableCell align="left">{row.Nombre}</TableCell>
                <TableCell align="center">{row.Orden}</TableCell>
                <TableCell align="center">{row.Horario}</TableCell>
                <TableCell align="center">
                  {row.EstaActivo ? (
                    <Chip
                      icon={<CheckIcon style={{ color: green[700] }} />}
                      label="Activa"
                      className={classes.chipActive}
                    />
                  ) : (
                    <Chip
                      icon={<CloseIcon style={{ color: red[700] }} />}
                      label="Inactiva"
                      className={classes.chipInactive}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  <Tooltip title="Ver detalle">
                    <IconButton
                      color="secondary"
                      size="small"
                      onClick={() =>
                        handleStopDetailClicked(row.RutaParadaID, row.ParadaID)
                      }
                      aria-label="see detail"
                    >
                      <ZoomInIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Editar">
                    <IconButton
                      color="secondary"
                      size="small"
                      onClick={() =>
                        handleStopEditClicked(row.RutaParadaID, row.ParadaID)
                      }
                      aria-label="see detail"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remover Parada">
                    <IconButton
                      color="secondary"
                      size="small"
                      onClick={() =>
                        handleDeleteRouteStopClick(row.RutaParadaID)
                      }
                      aria-label="unassign stop"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Esta seguro que desea remover esta Parada?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => handleDelete(routeStopId)}
            color="primary"
            autoFocus
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Detail Modal */}
      <Modal
        aria-labelledby="detail-route-stop"
        aria-describedby="detail-route-stop"
        className={classes.modal}
        open={modalDetailStatus}
        onClose={handleModalDetailClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalDetailStatus}>
          <div className={classes.modalPaper}>
            <StopsModalDetail
              clickedStopId={stopId}
              clickedRouteStopId={routeStopId}
              modalStatusHandler={handleModalDetailClose}
            />
          </div>
        </Fade>
      </Modal>

      {/* Assign Modal */}
      <Modal
        aria-labelledby="assign-route-stop"
        aria-describedby="assign-route-stop"
        className={classes.modal}
        open={modalAssignStatus}
        onClose={handleAssignClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalAssignStatus}>
          <div className={classes.modalPaper}>
            <RouteAssign
              routeId={routeId}
              modalStatusHandler={handleAssignClose}
              refreshListHandler={fetchStops}
            />
          </div>
        </Fade>
      </Modal>

      {/* Edit Modal */}
      <Modal
        aria-labelledby="edit-route-stop"
        aria-describedby="edit-route-stop"
        className={classes.modal}
        open={modalEditStatus}
        onClose={handleEditClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalEditStatus}>
          <div className={classes.modalPaper}>
            <RouteStopEdit
              clickedRouteId={routeId}
              clickedStopId={stopId}
              clickedRouteStopId={routeStopId}
              modalStatusHandler={handleModalEditClose}
              refreshListHandler={fetchStops}
            />
          </div>
        </Fade>
      </Modal>

      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          Parada eliminada con exito!
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};
