import request from './../request';
import moment from 'moment';
import { getAuthorizationToken } from './../session';

function getList(companyId = null) {
  let requestUrl;
  if (companyId === null || companyId === '') {
    requestUrl = '/TipoUsuario/TipoUsuarios';
  } else {
    requestUrl = `/TipoUsuario/TipoUsuariosPorClienteID/${companyId}`;
  }

  return request({
    url: requestUrl,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function create(data) {
  return request({
    url: '/usuario/usuarios/',
    method: 'POST',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      Nombre: data.name,
      Direccion: data.address,
      Email: data.email,
      Telefono1: data.phone1,
      Telefono2: data.phone2,
      FechaExpiracion: moment(data.expiration_date).utc(),
      Puesto: 'Chofer',
      EstaActivo: data.status,
      Contraseña: data.password,
      NombreUsuario: data.username,
      FechaCreacion: new Date(),
      ClienteCiudadID: 1,
      ClienteID: 1,
      CategoriaUsuarioID: 2,
      TipoUsuarioID: 1,
    },
  });
}

function update(driverId, data) {
  return request({
    url: `/usuario/usuarios/`,
    method: 'PUT',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      UsuarioID: driverId,
      Nombre: data.name,
      Direccion: data.address,
      Email: data.email,
      Telefono1: data.phone1,
      Telefono2: data.phone2,
      FechaExpiracion: moment(data.expiration_date).utc(),
      ClienteCiudadID: 1,
      ClienteID: 1,
      CategoriaUsuarioID: 2,
      TipoUsuarioID: 1,
      EstaActivo: data.status,
      Contraseña: data.password,
      NombreUsuario: data.username,
      FechaCreacion: data.created_at,
    },
  });
}

function deleteItem(driverId) {
  return request({
    url: `/usuario/usuarios/`,
    method: 'DELETE',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      UsuarioID: driverId,
    },
  });
}

const ClientsService = {
  getList,
  create,
  update,
  deleteItem,
};

export default ClientsService;
