import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import Skeleton from 'react-loading-skeleton';

import Can from './../../Can';

import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { green, red } from '@material-ui/core/colors';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert, AlertTitle } from '@material-ui/lab';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplyIcon from '@material-ui/icons/Reply';

import RouteCoordinates from './../RouteCoordinates';
import { GenericSelect } from './../../Common/Selects/Generic';
import { CompanySelect } from './../../Common/Selects/Company';

import RoutesService from './../../../services/api/routes';
import ClientsService from './../../../services/api/clients';

import { getSessionCookie } from './../../../services/session';

const useStyles = makeStyles({
  table: {
    marginTop: '10px',
  },
  cardContent: {
    padding: '0px !important',
  },
  card: {
    marginTop: '1rem',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
  select: {
    width: '100%',
    marginTop: '1em',
    marginBottom: '1em',
  },
  clientSelect: {
    marginBottom: '12px',
  },
});

const RedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

const GreenButton = withStyles((theme) => ({
  root: {
    color: 'white', //theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

export const RoutesEdit = () => {
  const classes = useStyles();
  let history = useHistory();
  let { routeId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState({
    error: false,
    errorDescription: null,
  });
  const [role, setRole] = React.useState('logged');

  const [route, setRoute] = React.useState({
    id: routeId,
    status: true,
    start_time: new Date(),
    end_time: new Date(),
  });

  const [open, setOpen] = React.useState(false);
  const [clients, setClients] = React.useState([]);

  //Handle Session Role
  useEffect(() => {
    let session = getSessionCookie();
    if (
      session !== undefined &&
      session.role !== undefined &&
      session.role !== null
    ) {
      setRole(session.role);
    }
  }, [role]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStartTimeChange = (date) => {
    setRoute({ ...route, start_time: date });
  };

  const handleEndTimeChange = (date) => {
    setRoute({ ...route, end_time: date });
  };

  const handleStatusChange = (event) => {
    setValue('status', event.target.value);
    setRoute({ ...route, status: event.target.value });
  };

  const handleClientChange = (value) => {
    setValue('clientId', value);
    setRoute({ ...route, clientId: value });
  };

  const handleCompanyChange = (value) => {
    setValue('companyId', value);
    setRoute({ ...route, companyId: value });
  };

  const handleBackClick = () => {
    history.goBack();
  };

  let routeSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    duration: yup.string().required(),
    start_time: yup.string().required(),
    end_time: yup.string().required(),
    start_place: yup.string().required(),
    end_place: yup.string().required(),
    frequency: yup.number().positive(),
    status: yup.boolean().required(),
    clientId: yup.number().required(),
    companyId: yup.number(),
  });

  const { handleSubmit, errors, control, setValue, reset } = useForm({
    validationSchema: routeSchema,
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    setRequestError({ error: false, errorDescription: null });

    //Request
    setTimeout(function () {
      RoutesService.update(routeId, data)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          if (response.IsValid && response.Data !== undefined) {
            history.push(`/routes/detail/${response.Data.RutaID}`);
          } else {
            throw response.Message;
          }
        })
        .catch((e) => {
          setRequestError({
            error: true,
            errorDescription: e.status ? e.Data.Message : e,
          });
          setIsLoading(false);
        });
    }, 1000);
  };

  const handleDelete = () => {
    setTimeout(function () {
      //Request
      RoutesService.deleteItem(routeId)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          if (response.IsValid && response.Data !== undefined) {
            setOpen(false);
            history.push(`/routes/`);
          } else {
            throw response.Message;
          }
        })
        .catch((e) => {
          setRequestError({
            error: true,
            errorDescription: e.status ? e.Data.Message : e,
          });
          setOpen(false);
          setIsLoading(false);
        });
    }, 1000);
  };

  useEffect(() => {
    setIsLoading(true);

    setTimeout(function () {
      //Request
      RoutesService.get(routeId)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          //Get Routes
          if (response && response.Data !== undefined) {
            let startTime = moment(response.Data.HoraInicio, 'HH:mm A');
            let endTime = moment(response.Data.HoraFin, 'HH:mm A');

            reset({
              name: response.Data.Nombre ? response.Data.Nombre : '',
              description: response.Data.Descripcion
                ? response.Data.Descripcion
                : '',
              start_place: response.Data.Inicio ? response.Data.Inicio : '',
              end_place: response.Data.Fin ? response.Data.Fin : '',
              duration: response.Data.Duracion ? response.Data.Duracion : '',
              frecuency: response.Data.Frecuencia
                ? response.Data.Frecuencia
                : '',
              start_time: startTime,
              end_time: endTime,
              clientId: response.Data.TipoUsuarioID
                ? response.Data.TipoUsuarioID
                : '',
              companyId: response.Data.ClienteID ? response.Data.ClienteID : '',
              status: response.Data.EstaActivo
                ? response.Data.EstaActivo
                : false,
            });

            setRoute({
              id: response.Data.RutaID,
              name: response.Data.Nombre,
              description: response.Data.Descripcion,
              start_place: response.Data.Inicio,
              end_place: response.Data.Fin,
              duration: response.Data.Duracion,
              frecuency: response.Data.Frecuencia,
              start_time: startTime,
              end_time: endTime,
              clientId: response.Data.TipoUsuarioID,
              companyId: response.Data.ClienteID,
              status: response.Data.EstaActivo,
            });
            setIsLoading(false);
          } else if (response.Data === null) {
            history.push(`/routes/`);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 500);
  }, [routeId, history, reset]);

  //Handle Clientes Select Content
  useEffect(() => {
    let isCancelled = false;
    setTimeout(function () {
      let session = getSessionCookie();

      if (
        session !== undefined &&
        session.role !== undefined &&
        session.role !== null
      ) {
        setRole(session.role);
      }
      //Request
      if (route.companyId !== '' || session.role === 'client') {
        ClientsService.getList(route.companyId !== '' ? route.companyId : null)
          .then((response) => {
            if (response === null || !response.IsValid) {
              throw response.Message;
            }
            //
            if (response && response.Data !== undefined) {
              if (!isCancelled) {
                setClients(response.Data);
              }
            }
          })
          .catch((e) => {
            setIsLoading(false);
          });
      }
    }, 0);

    return () => {
      isCancelled = true;
    };
  }, [route.companyId]);

  return (
    <React.Fragment>
      <Card className={classes.card} style={{ background: '#f4f6f8' }}>
        <CardContent>
          <form
            className={classes.form}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            {isLoading ? (
              <Skeleton count={5} height={50} />
            ) : (
              <React.Fragment>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignContent="center"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Grid item sm={6} xs={12}>
                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="name"
                      value={route.name}
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="name"
                      label="Nombre de la Ruta"
                      autoComplete="name"
                      autoFocus
                      error={errors.name ? true : false}
                    />
                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="description"
                      defaultValue=""
                      value={route.description}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="description"
                      label="Descripcion"
                      autoComplete="description"
                      error={errors.description ? true : false}
                    />
                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="duration"
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="duration"
                      label="Duracion de la Ruta"
                      autoComplete="duration"
                      error={errors.duration ? true : false}
                    />
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                    >
                      <Grid item xs>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Controller
                            as={
                              <KeyboardTimePicker
                                inputVariant="outlined"
                                margin="normal"
                                label="Hora de inicio de la ruta"
                                id="start_time"
                                name="start_time"
                                fullWidth
                                autoOk
                                minutesStep={5}
                                value={route.start_time}
                                onChange={handleStartTimeChange}
                              />
                            }
                            control={control}
                            name="start_time"
                            required
                            error={errors.start_time ? true : false}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Controller
                            as={
                              <KeyboardTimePicker
                                inputVariant="outlined"
                                margin="normal"
                                id="end_time"
                                name="end_time"
                                label="Hora de fin de la ruta"
                                fullWidth
                                value={route.end_time}
                                onChange={handleEndTimeChange}
                              />
                            }
                            control={control}
                            className={classes.clientSelect}
                            name="end_time"
                            required
                            error={errors.end_time ? true : false}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>

                    <Can
                      role={role}
                      perform="companies:read"
                      yes={() => (
                        <CompanySelect
                          parentCallback={handleCompanyChange}
                          companyId={route.companyId}
                          control={control}
                          error={errors.companyId ? true : false}
                        />
                      )}
                      no={() => ''}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="start_place"
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="start_place"
                      label="Inicio de la Ruta"
                      autoComplete="inicio"
                      error={errors.start_place ? true : false}
                    />
                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="end_place"
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="end_place"
                      label="Fin de la Ruta"
                      autoComplete="end_place"
                      error={errors.end_place ? true : false}
                    />
                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="frecuency"
                      value={route.frecuency}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="frecuency"
                      label="Frecuencia de la Ruta"
                      autoComplete="frecuency"
                      error={errors.frecuency ? true : false}
                    />

                    <Controller
                      as={
                        <FormControl
                          variant="outlined"
                          className={classes.select}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Estado
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            autoWidth
                            value={route.status}
                            onChange={handleStatusChange}
                            label="Estado"
                          >
                            <MenuItem value={true}>Activo</MenuItem>
                            <MenuItem value={false}>Inactivo</MenuItem>
                          </Select>
                        </FormControl>
                      }
                      className={classes.select}
                      name="status"
                      control={control}
                    />

                    <Can
                      role={role}
                      perform="clients:read"
                      yes={() => (
                        <GenericSelect
                          className={classes.clientSelect}
                          parentCallback={handleClientChange}
                          parentExtraCallback={handleCompanyChange}
                          itemSelected={route.clientId}
                          itemKey="TipoUsuarioID"
                          label="Clientes"
                          itemValue="TipoUsuarioID"
                          itemText="Nombre"
                          inputName="clientId"
                          itemExtra="ClienteID"
                          items={clients.length > 0 ? clients : []}
                          control={control}
                          error={errors.clientId ? true : false}
                        />
                      )}
                      no={() => ''}
                    />

                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <GreenButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.button}
                          startIcon={<SaveIcon />}
                        >
                          Guardar Cambios
                        </GreenButton>
                      </Grid>
                      <Grid item>
                        <RedButton
                          variant="contained"
                          color="secondary"
                          size="large"
                          onClick={handleClickOpen}
                          className={classes.button}
                          startIcon={<DeleteIcon />}
                        >
                          Eliminar
                        </RedButton>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={handleBackClick}
                          variant="contained"
                          color="secondary"
                          size="large"
                          className={classes.button}
                          startIcon={<ReplyIcon />}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {requestError.error && (
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-end"
                  >
                    <Grid item xs>
                      <Alert severity="error">
                        <AlertTitle>Algo salió mal</AlertTitle>
                        {requestError.errorDescription}
                      </Alert>
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            )}
          </form>
        </CardContent>
      </Card>

      <Card className={classes.card} style={{ background: '#f4f6f8' }}>
        <CardContent>
          <RouteCoordinates routeId={routeId} />
        </CardContent>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Esta seguro que desea eliminar esta Ruta?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
