import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import SaveIcon from '@material-ui/icons/Save';
import ReplyIcon from '@material-ui/icons/Reply';

import { green, red } from '@material-ui/core/colors';

import StopsService from './../../../services/api/stops';

import { StopSelect } from './../../Common/Selects/Stop';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  select: {
    width: '100%',
  },
  formControl: {
    marginTop: '0px',
  },
}));

const GreenButton = withStyles((theme) => ({
  root: {
    color: 'white', //theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

export const RouteStopEdit = (props) => {
  const classes = useStyles();

  let {
    clickedRouteId,
    clickedStopId,
    clickedRouteStopId,
    modalStatusHandler,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState({
    error: false,
    errorDescription: null,
  });

  const [stopId, setStopId] = useState();
  const [routeStop, setRouteStop] = useState({
    status: true,
    orientation: true,
  });

  let stopSchema = yup.object().shape({
    order: yup.string().required(),
    time: yup.string().required(),
    orientation: yup.string(),
    status: yup.string(),
  });

  const { handleSubmit, errors, control, setValue, reset } = useForm({
    validationSchema: stopSchema,
  });

  const handleStopChange = (value) => {
    setStopId(value);
  };

  const handleStatusChange = (event) => {
    setValue('status', event.target.value);
    setRouteStop({ ...routeStop, status: event.target.value });
  };

  const handleOrientationChange = (event) => {
    setValue('orientation', event.target.value);
    setRouteStop({ ...routeStop, orientation: event.target.value });
  };

  const handleCancelClick = () => {
    props.modalStatusHandler();
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    setRequestError({ error: false, errorDescription: null });

    setTimeout(function () {
      //Request
      StopsService.updateRouteStop(
        clickedRouteStopId,
        clickedRouteId,
        stopId,
        data
      )
        .then((response) => {
          if (response === null) {
            throw new Error('Request error');
          }
          if (response.IsValid && response.Data !== undefined) {
            handleCancelClick();
          } else {
            throw response.Message;
          }
        })
        .catch((e) => {
          console.log(e);
          setRequestError({
            error: true,
            errorDescription: e.status ? e.data.Message : e,
          });
          setIsLoading(false);
        });
    }, 1000);
  };

  useEffect(() => {
    setIsLoading(true);

    setTimeout(function () {
      //Request
      StopsService.getRouteStopsDetail(clickedRouteStopId)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          //Get Routes
          if (response && response.Data !== undefined) {
            setRouteStop({
              id: response.Data[0].ParadaRutaID,
              time: response.Data[0].Horario,
              order: response.Data[0].Orden,
              orientation: response.Data[0].Orientacion,
              status: response.Data[0].EstaActivoRutaParada,
            });

            // setValue('order', response.Data[0].Orden);
            reset({
              order: response.Data[0].Orden ? response.Data[0].Orden : '',
              time: response.Data[0].Horario ? response.Data[0].Horario : '',
              orientation: response.Data[0].Orientacion
                ? response.Data[0].Orientacion
                : 1,
              status: response.Data[0].EstaActivoRutaParada
                ? response.Data[0].EstaActivoRutaParada
                : '',
            });

            setStopId(response.Data[0].ParadaRelacionID);

            setIsLoading(false);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 500);
  }, []);

  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        direction="row"
        alignContent="center"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography>Editar Parada</Typography>
        </Grid>
      </Grid>

      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {isLoading ? (
          <Skeleton count={1} height={50} />
        ) : (
          <React.Fragment>
            <Grid
              container
              spacing={1}
              direction="row"
              alignContent="center"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Grid item xs>
                <StopSelect
                  routeId={clickedRouteId}
                  stopId={clickedStopId}
                  control={control}
                  error={errors.routeId}
                  parentCallback={handleStopChange}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              direction="row"
              alignContent="center"
              alignItems="flex-start"
              justifyContent="space-between"
              style={{ marginTop: '5px' }}
            >
              <Grid item sm={3} xs={12}>
                <Controller
                  as={<TextField />}
                  control={control}
                  rules={{ required: true }}
                  name="order"
                  value="1234123"
                  variant="outlined"
                  margin="none"
                  fullWidth
                  required
                  id="order"
                  label="Orden"
                  autoComplete="Orden"
                  error={errors.order ? true : false}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <Controller
                  as={<TextField />}
                  control={control}
                  rules={{ required: true }}
                  name="time"
                  value={routeStop.time}
                  variant="outlined"
                  margin="none"
                  fullWidth
                  required
                  id="time"
                  label="Hora de la Parada"
                  autoComplete="Hora"
                  error={errors.time ? true : false}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <Controller
                  as={
                    <FormControl variant="outlined" className={classes.select}>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Orientación
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        autoWidth
                        value={routeStop.orientation}
                        onChange={handleOrientationChange}
                        label="Orientación"
                      >
                        <MenuItem value={true}>Ida</MenuItem>
                        <MenuItem value={false}>Vuelta</MenuItem>
                      </Select>
                    </FormControl>
                  }
                  className={classes.select}
                  name="orientation"
                  control={control}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <Controller
                  as={
                    <FormControl variant="outlined" className={classes.select}>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Estado
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        autoWidth
                        value={routeStop.status}
                        onChange={handleStatusChange}
                        label="Estado"
                      >
                        <MenuItem value={true}>Activo</MenuItem>
                        <MenuItem value={false}>Inactivo</MenuItem>
                      </Select>
                    </FormControl>
                  }
                  className={classes.select}
                  name="status"
                  control={control}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} direction="row" justifyContent="flex-end">
              <Grid item>
                <Button
                  onClick={handleCancelClick}
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  startIcon={<ReplyIcon />}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <GreenButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<SaveIcon />}
                >
                  Guardar Cambios
                </GreenButton>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </form>
    </React.Fragment>
  );
};
