/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, forwardRef, useEffect} from 'react';

import Skeleton from 'react-loading-skeleton';

import { useTable, usePagination, useRowSelect, useSortBy } from 'react-table';

import TableContainer from '@material-ui/core/TableContainer';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { Pagination } from './pagination';

import './styles.css';

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

export const TableSelectable = ({ columns, data, isLoading, rowSelection }) => {
  // Use the state and functions returned from useTable to build your UI
  const { 
    getTableProps, 
    headerGroups, 
    rows,
    prepareRow, 
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    gotoPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 }, // Pass our hoisted table state
    },
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  );

  useEffect( () => {
    const response = selectedFlatRows.map(
      d => d.original
    );
    rowSelection(response.map(item => item.UsuarioID));
  }, [selectedFlatRows])

  const handleChangeRows = (event) => {
    setPageSize(parseInt(event.target.value, 15));
    gotoPage(0);
  };

  const handlePageChange = (event, newPage) => {
    gotoPage(newPage);
  };

  // Render the UI for your table
  return (
    <TableContainer component={Paper}>
      <MaUTable {...getTableProps()} >
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          <TableRow 
            className={`${isLoading ? 'fadeIn' : 'fadeOut'}`} 
            key="skeletonRow" 
            width={100}>

            <TableCell colSpan="10" align="center">
              <Skeleton count={15} height={40} />
            </TableCell>
          </TableRow>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow className="fadeIn" {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </MaUTable>
      <Grid
        container
        direction="column"
        alignContent="flex-end"
        alignItems="flex-end"
        style={{ marginTop: '1em', marginBottom: '1em' }}
      >
        <Grid item>
          {rows.length > 0 && 
            <TablePagination
              rowsPerPageOptions={[15, 30, 50, 100]}
              component="div"
              page={pageIndex}
              count={rows.length}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRows}
              onPageChange={handlePageChange}
              ActionsComponent={Pagination}
            />
          }
        </Grid>
      </Grid>
    </TableContainer>
  );
}