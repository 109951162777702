import request from './../request';
import { getAuthorizationToken } from './../session';

function getList() {
  return request({
    url: '/unidad/unidades/',
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function get(vehicleId) {
  return request({
    url: `/unidad/unidades/${vehicleId}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function create(data) {
  return request({
    url: '/unidad/unidades/',
    method: 'POST',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      Numero: data.number,
      Titular: data.headline,
      TipoUnidadID: 1,
      ChoferUsuarioID: parseInt(data.driver),
      RutaID: parseInt(data.route),
      EstaActivo: data.status,
      FechaCreacion: new Date(),
      Imei: data.imei,
      ClienteID: data.clientId,
    },
  });
}

function update(vehicleId, data) {
  return request({
    url: `/unidad/unidades/`,
    method: 'PUT',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      UnidadID: parseInt(vehicleId),
      Numero: data.number,
      Titular: data.headline,
      Imei: data.imei,
      TipoUnidadID: 1,
      ChoferUsuarioID: data.driver,
      TipoUsuarioID: data.clientId,
      RutaID: data.route,
      EstaActivo: data.status,
      FechaCreacion: new Date(),
    },
  });
}

function deleteItem(vehicleId) {
  return request({
    url: `/unidad/unidades/`,
    method: 'DELETE',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      UnidadID: vehicleId,
    },
  });
}

function getVehiclesByRoute(routeId) {
  return request({
    url: `/unidad/obtenerUnidadesPorRutaID/${routeId}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

const VehiclesService = {
  get,
  getList,
  create,
  update,
  deleteItem,
  getVehiclesByRoute
};

export default VehiclesService;
