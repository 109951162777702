import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabPanel } from './TabPanel';

import { RoutesCoordinatesList } from './../RoutesCoordinatesList';
import { RoutesCoordinatesMap } from './../RoutesCoordinatesMap';

import RoutesService from './../../../services/api/routes';

export const RouteCoordinatesPanel = () => {
  const [value, setValue] = React.useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [routeCoordinates, setRouteCoordinates] = useState([]);

  let { routeId } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setIsLoading(true);

    setTimeout(function () {
      //Request
      RoutesService.getRoutePoints(routeId)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          setIsLoading(false);
          //Get Routes
          if (response && response.Data && response.Data.length > 0) {
            setRouteCoordinates(response.Data);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 500);
  }, []);

  return (
    <React.Fragment>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          <Tab label="Mapa de la Ruta" />
          <Tab label="Lista de Coordenadas" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {isLoading ? (
          <Skeleton count={1} height={450} />
        ) : (
          <RoutesCoordinatesMap routeCoordinates={routeCoordinates} />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {isLoading ? (
          <Skeleton count={10} height={50} />
        ) : (
          <RoutesCoordinatesList routeCoordinates={routeCoordinates} />
        )}
      </TabPanel>
    </React.Fragment>
  );
};
