import React, { Component } from 'react';

import GoogleMapReact from 'google-map-react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import { green, red } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import CircularProgress from '@material-ui/core/CircularProgress';

import SaveIcon from '@material-ui/icons/Save';

import RoutesService from './../../../services/api/routes';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GreenButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const defaultProps = {
  center: {
    lat: 23.3869794,
    lng: -103.7031745,
  },
  zoom: 5,
};

class RouteCoordinates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      baseGoingServerMarkers: [],
      baseBackServerMarkers: [],
      map: null,
      maps: null,
      routeId: this.props.routeId,
      polylineGoing: null,
      polyGoingCoords: [],
      polylineBack: null,
      polyBackCoords: [],
      routeDirection: '1',
      successAlert: false,
      errorAlert: false,
      locked: false,
    };

    this.setMapClickListener = this.setMapClickListener.bind(this);
    this.addLastSessionMarker = this.addLastSessionMarker.bind(this);
    this.processServerMarkers = this.processServerMarkers.bind(this);
    this.submitCoordinates = this.submitCoordinates.bind(this);
    this.drawPolyLines = this.drawPolyLines.bind(this);
  }

  showSuccessAlert = () => {
    this.setState({ successAlert: true });
  };

  showErrorAlert = () => {
    this.setState({ errorAlert: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ successAlert: false, errorAlert: false });
  };

  handleMapLoaded = (map, maps) => {
    var polyGoingCoords = new maps.MVCArray();
    var polyBackCoords = new maps.MVCArray();

    var polylineGoing = new maps.Polyline({
      map: map,
      strokeColor: green[600],
      strokeOpacity: 1.0,
      editable: true,
      strokeWeight: 4,
      path: polyGoingCoords,
    });

    var polylineBack = new maps.Polyline({
      map: map,
      strokeColor: red[600],
      strokeOpacity: 1.0,
      editable: true,
      strokeWeight: 4,
      path: polyBackCoords,
    });

    maps.event.addListener(
      polylineGoing,
      'rightclick',
      function (e) {
        // Check if click was on a vertex control point
        if (e.vertex === undefined || this.state.locked) {
          return;
        }

        if (!polylineGoing.getPath() || e.vertex === undefined) {
          this.close();
          return;
        }

        let objGoing = this.state.baseGoingServerMarkers.find(
          (obj) => obj.LatLngKey === `${e.latLng.lat()}-${e.latLng.lng()}`
        );
        if (objGoing) {
          this.removePathVertex({
            RutaCoordenadaID: objGoing.RutaCoordenadaID,
          });
        }
        //Find in base array
        polylineGoing.getPath().removeAt(e.vertex);
      }.bind(this)
    );

    maps.event.addListener(
      polylineBack,
      'rightclick',
      function (e) {
        // Check if click was on a vertex control point
        if (e.vertex === undefined || this.state.locked) {
          return;
        }

        if (!polylineBack.getPath() || e.vertex === undefined) {
          this.close();
          return;
        }

        let objBack = this.state.baseBackServerMarkers.find(
          (obj) => obj.LatLngKey === `${e.latLng.lat()}-${e.latLng.lng()}`
        );
        if (objBack) {
          this.removePathVertex({
            RutaCoordenadaID: objBack.RutaCoordenadaID,
          });
        }
        //Find in base array
        polylineBack.getPath().removeAt(e.vertex);
      }.bind(this)
    );

    if (this.state !== undefined) {
      let routeId = this.state.routeId;
      RoutesService.getRoutePoints(routeId)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          //Get Routes
          if (response && response.Data !== undefined) {
            this.processServerMarkers(response.Data);
          } else if (response.Data === null) {
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    this.setState(
      {
        map: map,
        maps: maps,
        polylineGoing: polylineGoing,
        polyGoingCoords: polyGoingCoords,
        polylineBack: polylineBack,
        polyBackCoords: polyBackCoords,
      },
      () => this.setMapClickListener()
    );
  };

  setMapClickListener = () => {
    let maps = this.state.maps;
    let map = this.state.map;

    maps.event.addListener(
      map,
      'click',
      function (event) {
        let lat = event.latLng.lat();
        let lng = event.latLng.lng();

        this.addLastSessionMarker(lat, lng);
      }.bind(this)
    );
  };

  addLastSessionMarker = (lat, lng) => {
    //This should be the only way to add markers to the array
    this.drawPolyLines(lat, lng);
  };

  drawPolyLines = (lat, lng) => {
    let maps = this.state.maps;
    let polyGoingCoords = this.state.polyGoingCoords;
    let polyBackCoords = this.state.polyBackCoords;

    if (!this.state.locked) {
      if (this.state.routeDirection === '1') {
        polyGoingCoords.push(new maps.LatLng(lat, lng));
      } else if (this.state.routeDirection === '2') {
        polyBackCoords.push(new maps.LatLng(lat, lng));
      }
    }

    this.setState({
      polyGoingCoords: polyGoingCoords,
      polyBackCoords: polyBackCoords,
    });
  };

  handleRadioChange = (e) => {
    this.setState({ routeDirection: e.target.value });
  };

  processServerMarkers = (results) => {
    //Create Original array
    let markersGoingArray = [];
    let markersBackArray = [];
    let polyGoingCoords = this.state.polyGoingCoords;
    let polyBackCoords = this.state.polyBackCoords;
    let maps = this.state.maps;

    for (let serverMarker of results) {
      if (serverMarker.Orientacion === 1) {
        markersGoingArray.push({
          LatLngKey: `${serverMarker.Latitud}-${serverMarker.Longitud}`,
          RutaCoordenadaID: serverMarker.RutaCoordenadaID,
          Latitud: serverMarker.Latitud,
          Longitud: serverMarker.Longitud,
          Orden: serverMarker.Orden,
          Orientacion: serverMarker.Orientacion,
          EstaActivo: serverMarker.EstaActivo,
        });

        polyGoingCoords.push(
          new maps.LatLng(serverMarker.Latitud, serverMarker.Longitud)
        );
      } else if (serverMarker.Orientacion === 2) {
        markersBackArray.push({
          LatLngKey: `${serverMarker.Latitud}-${serverMarker.Longitud}`,
          RutaCoordenadaID: serverMarker.RutaCoordenadaID,
          Latitud: serverMarker.Latitud,
          Longitud: serverMarker.Longitud,
          Orden: serverMarker.Orden,
          Orientacion: serverMarker.Orientacion,
          EstaActivo: serverMarker.EstaActivo,
        });

        polyBackCoords.push(
          new maps.LatLng(serverMarker.Latitud, serverMarker.Longitud)
        );
      }
    }

    this.setState({
      polyGoingCoords: polyGoingCoords,
      polyBackCoords: polyBackCoords,
      baseGoingServerMarkers: markersGoingArray,
      baseBackServerMarkers: markersBackArray,
    });
  };

  removePathVertex = (data) => {
    let polylineGoing = this.state.polylineGoing;
    let polylineBack = this.state.polylineBack;

    this.setState({ locked: true });

    polylineGoing.setEditable(false);
    polylineBack.setEditable(false);

    setTimeout(() => {
      RoutesService.deleteRoutePoints(data)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          //Get Routes
          if (response && response.Data !== undefined) {
            this.showSuccessAlert();
            this.setState({ locked: false });
            polylineGoing.setEditable(true);
            polylineBack.setEditable(true);
          } else if (response.Data === null) {
            this.showErrorAlert();
            this.setState({ locked: false });
            polylineGoing.setEditable(true);
            polylineBack.setEditable(true);
          }
        })
        .catch((e) => {
          console.log(e);
          this.showErrorAlert();
          this.setState({ locked: false });
          polylineGoing.setEditable(true);
          polylineBack.setEditable(true);
        });
    }, 1000);
  };

  submitCoordinates = () => {
    let data = [];
    let polylineGoing = this.state.polylineGoing;
    let polylineBack = this.state.polylineBack;
    let baseGoingMarkers = this.state.baseGoingServerMarkers;
    let baseBackMarkers = this.state.baseBackServerMarkers;

    let routeId = this.state.routeId;

    this.setState({ locked: true });

    polylineGoing.setEditable(false);
    polylineBack.setEditable(false);

    let pathGoing = polylineGoing.getPath();
    let pathBack = polylineBack.getPath();

    let cursorGoing = 0;
    let cursorBack = 0;
    let globalCursor = 0;

    if (pathGoing.length >= baseGoingMarkers.length) {
      pathGoing.forEach((point) => {
        if (baseGoingMarkers[cursorGoing] !== undefined) {
          data.push({
            RutaID: routeId,
            RutaCoordenadaID: baseGoingMarkers[cursorGoing].RutaCoordenadaID,
            Latitud: point.lat(),
            Longitud: point.lng(),
            Orden: globalCursor + 1,
            Orientacion: baseGoingMarkers[cursorGoing].Orientacion,
            EstaActivo: baseGoingMarkers[cursorGoing].EstaActivo,
          });
        } else {
          data.push({
            RutaID: routeId,
            Latitud: point.lat(),
            Longitud: point.lng(),
            Orden: globalCursor + 1,
            Orientacion: 1,
            EstaActivo: 1,
          });
        }

        globalCursor = globalCursor + 1;
        cursorGoing = cursorGoing + 1;
      });
    }

    if (pathBack.length >= baseBackMarkers.length) {
      pathBack.forEach((point) => {
        if (baseBackMarkers[cursorBack] !== undefined) {
          data.push({
            RutaID: routeId,
            RutaCoordenadaID: baseBackMarkers[cursorBack].RutaCoordenadaID,
            Latitud: point.lat(),
            Longitud: point.lng(),
            Orden: globalCursor + 1,
            Orientacion: baseBackMarkers[cursorBack].Orientacion,
            EstaActivo: baseBackMarkers[cursorBack].EstaActivo,
          });
        } else {
          data.push({
            RutaID: routeId,
            Latitud: point.lat(),
            Longitud: point.lng(),
            Orden: globalCursor + 1,
            Orientacion: 2,
            EstaActivo: 1,
          });
        }
        cursorBack = cursorBack + 1;
        globalCursor = globalCursor + 1;
      });
    }

    if (data != null) {
      setTimeout(() => {
        RoutesService.createRoutePoints(data)
          .then((response) => {
            if (response === null || !response.IsValid) {
              throw response.Message;
            }
            //Get Routes
            if (response && response.Data !== undefined) {
              this.setState({ locked: false });
              polylineGoing.setEditable(true);
              polylineBack.setEditable(true);
              this.showSuccessAlert();
            } else if (response.Data === null) {
              this.showErrorAlert();
              this.setState({ locked: false });
              polylineGoing.setEditable(true);
              polylineBack.setEditable(true);
            }
          })
          .catch((e) => {
            console.log(e);
            this.showErrorAlert();
            this.setState({ locked: false });
            polylineGoing.setEditable(true);
            polylineBack.setEditable(true);
          });
      }, 1000);
    }
  };

  render() {
    return (
      <React.Fragment>
        <Grid
          container
          spacing={2}
          direction="row"
          alignContent="center"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Grid item>
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                value="1"
                checked={this.state.routeDirection === '1'}
                control={<GreenRadio color="primary" />}
                onChange={this.handleRadioChange}
                label="Ida"
                labelPlacement="start"
              />
              <FormControlLabel
                value="2"
                checked={this.state.routeDirection === '2'}
                control={<RedRadio color="primary" />}
                onChange={this.handleRadioChange}
                label="Regreso"
                labelPlacement="start"
              />
            </RadioGroup>
          </Grid>
          <Grid item style={{ position: 'relative' }}>
            <GreenButton
              type="button"
              onClick={this.submitCoordinates}
              variant="contained"
              color="primary"
              size="large"
              disabled={this.state.locked}
              style={{ color: 'white' }}
              startIcon={<SaveIcon />}
            >
              Guardar Coordenadas
            </GreenButton>
            {this.state.locked && (
              <CircularProgress
                size={24}
                style={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          direction="row"
          alignContent="center"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Grid item xs>
            <div style={{ height: '80vh', width: '100%' }}>
              <GoogleMapReact
                draggable={true}
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_MAPS_KEY,
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) =>
                  this.handleMapLoaded(map, maps)
                }
              ></GoogleMapReact>
            </div>
          </Grid>
        </Grid>
        <Snackbar
          open={this.state.successAlert}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <Alert onClose={this.handleClose} severity="success">
            Los cambios se guardaron con Exito.
          </Alert>
        </Snackbar>

        <Snackbar
          open={this.state.errorAlert}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <Alert onClose={this.handleClose} severity="error">
            Lo sentimos, ocurrio un error, por favor intente de nuevo mas tarde.
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

export default RouteCoordinates;
