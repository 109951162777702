import React from 'react';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import DefaultLayout from './../layouts/default';

import logo from './../../assets/images/logo.png';

export const LandingPage = () => {
  return (
    <DefaultLayout title={'Home'}>
      <Grid container spacing={0} direction="row">
        <Grid container item alignItems="center" justifyContent="center">
          <img
            style={{ width: '90%', maxWidth: '332px' }}
            src={logo}
            alt="Enrutate Logo"
          />
        </Grid>
        <Grid container item alignItems="center" justifyContent="center">
          <Typography variant="overline" display="block" gutterBottom>
            <Link to="/login">Iniciar Sesion</Link>
          </Typography>
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};
