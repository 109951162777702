import React, { useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import 'moment/locale/es';

import 'react-dates/initialize';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';

import IconButton from '@material-ui/core/IconButton';
import MapIcon from '@material-ui/icons/Room';
import SearchIcon from '@material-ui/icons/Search';
import DescriptionIcon from '@material-ui/icons/Description';

import { Table } from '../../Common/Table/table.js'

import ReportsServices from './../../../services/api/reports';
import RoutesServices from './../../../services/api/routes';
import UsersServices from './../../../services/api/users';

import useRole from '../../../hooks/useRole.js';
import { MapBox } from '../../Common/MapBox/index.js';
import { getLoggedUserId } from '../../../services/session.js';

import { APP_URL } from '../../../shared/constants.js';

import { useStyles } from './styles';

export const ReportsCapacity = () => {

  const classes = useStyles();

  const role = useRole();
  const [passengers, setPassengers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [routeId, setRouteId] = useState('all');
  const [startDate, setStartDate] = useState(moment().subtract(2, 'week'));
  const [endDate, setEndDate] = useState(moment());
  const [focusedInput, setFocusedInput] = useState();
  const [clientId, setClientId] = useState();
  const [filterQuery, setFilterQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalLatitude, setModalLatitude] = useState();
  const [modalLongitude, setModalLongitude] = useState();

  const [routes, setRoutes]= useState([]);

  const columns = useMemo(
    () => [
    {
      Header: 'ID',
      accessor: 'ViajeID',
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'PASAJERO',
      accessor: 'NombrePasajero',
    },
    {
      Header: 'HORA',
      accessor: (d) => {
        return moment(d.Fecha).local().format('hh:mm a');
      },
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'FECHA',
      accessor: (d) => {
        return moment(d.Fecha).local().format('DD/MM/YYYY');
      },
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'RUTA',
      accessor: 'Ruta',
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'FOLIO VIAJE',
      accessor: 'Folio',
    },
    {
      Header: 'UBICACIÓN',
      accessor: (d) => {
        return (
          <IconButton
            color="secondary"
            size="small"
            onClick={() => handleOpenModal(d.Latitud, d.Longitud)}
            aria-label="show map"
          >
            <MapIcon />
          </IconButton>
        );
      },
      style: {
        textAlign: 'center',
      },
    },
  ], []);

  useEffect(() => {
    fetchPassengers();
  }, []);

  const fetchPassengers = () => {
    //Request
    UsersServices.getLoggedUser(getLoggedUserId())
      .then((response) => {
        if (response === null || !response.IsValid) {
          throw response.Message;
        }

        if (response && response.Data !== undefined) {
          let tempClientId = response.Data.ClienteID;

          let filtersString = '';
          
          if (role !== 'superadmin' && tempClientId) {
            filtersString = filtersString + `ClienteID=${tempClientId}&`;
          }

          //fechaInicio
          filtersString =
            filtersString +
            `fechaInicio=${startDate
              .startOf('day')
              .format('YMMDD H:mm:ss')}&`;
          //fechaFin
          if (endDate !== '') {
            filtersString =
              filtersString +
              `fechaFin=${endDate.endOf('day').format('YMMDD H:mm:ss')}`;
          }

          setFilterQuery(filtersString);
          setClientId(tempClientId);
          //Get Passengers
          searchRequest(filtersString);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    RoutesServices.getList()
      .then((response) => {
        if (response === null || !response.IsValid) {
          throw response.Message;
        }
        // Get Routes
        if (response && response.Data !== undefined) {
          // setIsLoading(false);
          setRoutes(response.Data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleRouteChange = (event) => {
    setRouteId(event.target.value);
  }

  const handleSearchBtn = () => {
    setIsLoading(true);
    setSearchResults([]);

    let filtersString = '';
    //RutaID
    if (routeId !== 'all') {
      filtersString = filtersString +`RutaID=${routeId}&`;
    }

    if (role !== 'superadmin' && clientId !== undefined) {
      filtersString = filtersString + `ClienteID=${clientId}&`;
    }

    //fechaInicio
    filtersString =
      filtersString +
      `fechaInicio=${startDate.startOf('day').format('YMMDD H:mm:ss')}&`;

    //fechaFin
    if (endDate !== '') {
      filtersString =
        filtersString +
        `fechaFin=${endDate.endOf('day').format('YMMDD H:mm:ss')}`;
    }

    setFilterQuery(filtersString);
    //Get Passengers
    searchRequest(filtersString);
  };

  const handleReportDownloadPdf = () => {
    // Get Report values
    ReportsServices.getCapacityReport(filterQuery)
      .then((response) => {
        if (response === null || !response.IsValid) {
          throw response.Message;
        }
        if (response && response.Data) {
          var win = window.open(
            `${APP_URL}/${response.Data.nombreArchivo}`,
            '_blank'
          );
          win.focus();
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const handleOpenModal = (latitude, longitude) => {
    setIsModalOpen(true);
    setModalLatitude(latitude);
    setModalLongitude(longitude);
  };

  const searchRequest = useCallback((filtersString) => {
    ReportsServices.getCapacity(filtersString)
      .then((response) => {
        if (response === null || !response.IsValid) {
          throw response.Message;
        }
        setIsLoading(false);
        //Get Routes
        if (response && response.Data && response.Data.length > 0) {
          setSearchResults(response.Data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error)
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <Grid spacing={2} container className={classes.container} direction="column">
        <Grid item sm={12} xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container className={classes.filterContainer}
                spacing={1} 
                direction="row" 
                style={{ 
                  display: "flex", 
                  justifyContent: "space-between", 
                  alignItems: "center" }}>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <Paper elevation={1} className={classes.paper}>
                    <SearchIcon className={classes.searchIcon} />
                    <InputBase 
                      onChange={ event => setSearch(event.target.value)} 
                      placeholder="Buscar" 
                      size="small"
                      className={classes.searchInput} 
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3} style={{ display: "flex", justifyContent: "center" }}>
                  <FormControl variant="outlined" className={classes.selectRoute}>
                    <InputLabel id="filter-route-label">Ruta</InputLabel>
                    <Select
                      labelId="filter-route-label"
                      id="filterRoute"
                      value={routeId}
                      size="small"
                      style={{height: '45px'}}
                      onChange={handleRouteChange}
                      label="Ruta"
                    >
                      <MenuItem value="all">
                        <em>Todas las Rutas</em>
                      </MenuItem>
                      {routes && routes.map((route) => (
                        <MenuItem key={route.RutaID} value={route.RutaID}>
                          {route.Nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3} style={{ 
                  display: "flex", 
                  justifyContent: "center",
                  maxHeight: '50px'
                  }}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <DateRangePicker
                      startDate={startDate} // momentPropTypes.momentObj or null,
                      startDateId="filter_range_start_date" // PropTypes.string.isRequired,
                      endDate={endDate} // momentPropTypes.momentObj or null,
                      isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                      minimumNights={0}
                      endDateId="filter_range_end_date" // PropTypes.string.isRequired,
                      onDatesChange={({ startDate, endDate }) => {
                        setStartDate(startDate);
                        setEndDate(endDate);
                      }}
                      focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={(focusedInput) =>
                        setFocusedInput(focusedInput)
                      } // PropTypes.func.isRequired,
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={1} xl={1} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    disabled={passengers.length > 0}
                    onClick={handleReportDownloadPdf}
                    color="secondary"
                    aria-label="download report"
                    component="span"
                  >
                    <DescriptionIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={1} xl={1} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    className={classes.actionBtn}
                    startIcon={<SearchIcon />}
                    onClick={handleSearchBtn}
                  >
                    Buscar
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Table
            columns={columns}
            data={searchResults.length > 0 ? searchResults : passengers}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paperModal}>
          <MapBox latitude={modalLatitude} longitude={modalLongitude} />
        </div>
      </Modal>
    </>
  )
}