import React, { useState, useEffect } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';

import Helmet from 'react-helmet';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';

import HomeIcon from '@material-ui/icons/Home';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PersonIcon from '@material-ui/icons/Person';

import Can from './../../../components/Can';
import Breadcrumbs from './../../../components/Breadcrumbs';

import logo_dashboard from './../../../assets/images/logo_dashboard.png';

import {
  getSessionCookie,
  destroySessionCookie,
} from './../../../services/session';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  paper: {
    margin: '-2rem -1.5rem 2rem -2rem',
    padding: '1.5rem 6rem 1rem 2rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'all .2s ease-in-out',
    position: 'relative',
  },
  container: {
    padding: theme.spacing(3),
  },
  appBarSpacer: theme.mixins.toolbar,
}));

export default function DashboardLayout(props) {
  let history = useHistory();
  let location = useLocation();

  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [role, setRole] = useState('logged');
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    destroySessionCookie();

    history.replace('/login');
  };

  const activeRoute = (route) => {
    return location.pathname.includes(route);
  };

  const drawer = (
    <React.Fragment>
      <div className={classes.toolbar}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <img
              src={logo_dashboard}
              alt="logo dashboard"
              style={{ width: '170px', marginTop: '1.5em' }}
            />
          </Grid>
        </Grid>
      </div>
      <Divider />
      <List>
        <ListItem
          className={classes.listButton}
          button
          key="Inicio"
          selected={activeRoute('/dashboard')}
          component={Link}
          to="/dashboard"
        >
          <ListItemIcon>
            <HomeIcon className={classes.iconRoot} />
          </ListItemIcon>
          <ListItemText primary="Inicio" />
        </ListItem>

        <Can
          role={role}
          perform="routes:visit"
          yes={() => (
            <ListItem
              button
              key="Rutas"
              component={Link}
              className={classes.listButton}
              selected={activeRoute('/routes')}
              to="/routes"
            >
              <ListItemIcon>
                <CallSplitIcon className={classes.iconRoot} />
              </ListItemIcon>
              <ListItemText primary="Rutas" />
            </ListItem>
          )}
          no={() => ''}
        />
        <Can
          role={role}
          perform="stops:visit"
          yes={() => (
            <ListItem
              button
              key="Paradas"
              component={Link}
              className={classes.listButton}
              selected={activeRoute('/stops')}
              to="/stops"
            >
              <ListItemIcon>
                <DepartureBoardIcon className={classes.iconRoot} />
              </ListItemIcon>
              <ListItemText primary="Paradas" />
            </ListItem>
          )}
          no={() => ''}
        />
        <Can
          role={role}
          perform="vehicles:visit"
          yes={() => (
            <ListItem
              className={classes.listButton}
              button
              key="Unidades"
              component={Link}
              selected={activeRoute('/vehicles')}
              to="/vehicles"
            >
              <ListItemIcon>
                <AirportShuttleIcon className={classes.iconRoot} />
              </ListItemIcon>
              <ListItemText primary="Unidades" />
            </ListItem>
          )}
          no={() => ''}
        />
        <Can
          role={role}
          perform="drivers:visit"
          yes={() => (
            <ListItem
              button
              className={classes.listButton}
              key="Choferes"
              component={Link}
              selected={activeRoute('/drivers')}
              to="/drivers"
            >
              <ListItemIcon>
                <ContactMailIcon className={classes.iconRoot} />
              </ListItemIcon>
              <ListItemText primary="Choferes" />
            </ListItem>
          )}
          no={() => ''}
        />

        <Can
          role={role}
          perform="passengers:visit"
          yes={() => (
            <ListItem
              button
              className={classes.listButton}
              key="Pasajeros"
              component={Link}
              selected={activeRoute('/passengers')}
              to="/passengers"
            >
              <ListItemIcon>
                <PersonIcon className={classes.iconRoot} />
              </ListItemIcon>
              <ListItemText primary="Pasajeros" />
            </ListItem>
          )}
          no={() => ''}
        />

        <Can
          role={role}
          perform="notifications:visit"
          yes={() => (
            <ListItem
              button
              className={classes.listButton}
              key="Notificaciónes"
              component={Link}
              selected={activeRoute('/notifications')}
              to="/notifications"
            >
              <ListItemIcon>
                <NotificationsActiveIcon className={classes.iconRoot} />
              </ListItemIcon>
              <ListItemText primary="Notificaciones" />
            </ListItem>
          )}
          no={() => ''}
        />

        <Can
          role={role}
          perform="reports:visit"
          yes={() => (
            <ListItem
              button
              className={classes.listButton}
              key="Reportes"
              component={Link}
              selected={activeRoute('/reports')}
              to="/reports"
            >
              <ListItemIcon>
                <AssessmentIcon className={classes.iconRoot} />
              </ListItemIcon>
              <ListItemText primary="Reportes" />
            </ListItem>
          )}
          no={() => ''}
        />

        <Divider />

        <ListItem
          button
          key="Mi Perfil"
          className={classes.listButton}
          component={Link}
          selected={activeRoute('/profile')}
          to="/profile"
        >
          <ListItemIcon>
            <AccountBoxIcon className={classes.iconRoot} />
          </ListItemIcon>
          <ListItemText primary="Mi Perfil" />
        </ListItem>
        <ListItem button key="Cerrar Sesion" onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon className={classes.iconRoot} />
          </ListItemIcon>
          <ListItemText primary="Cerrar Sesion" />
        </ListItem>
      </List>
    </React.Fragment>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  //Handler Location Sidebar Highlighted Menu
  useEffect(() => {
    let session = getSessionCookie();
    if (
      session !== undefined &&
      session.role !== undefined &&
      session.role !== null
    ) {
      setRole(session.role);
    } else {
      //Noup!
      destroySessionCookie();
      history.push('/login');
    }
  }, [role, history]);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.title} | Enrútate</title>
      </Helmet>

      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              {props.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <div className={classes.container}>
            <Breadcrumbs />
            {props.children}
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}
