import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import DefaultLayout from './../layouts/default';

import logo from './../../assets/images/logo.png';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export const NotFoundPage = () => {
  const classes = useStyles();

  return (
    <DefaultLayout title={'Home'}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item>
          <img src={logo} alt="Enrutate Logo" />
        </Grid>
        <Grid item>
          <Paper elevation={3} className={classes.paper}>
            <h1>Pagina no encontrada</h1>
          </Paper>
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};
