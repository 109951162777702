import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import { green, red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';

import { Typography, CardContent } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ReplyIcon from '@material-ui/icons/Reply';

import UsersService from './../../../services/api/users';

const useStyles = makeStyles({
  table: {
    marginTop: '10px',
  },
  cardContent: {
    padding: '0px !important',
  },
  card: {
    marginTop: '1rem',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
    marginRight: '10px',
    marginBottom: '2px'
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
});

export const PassengersDetail = () => {
  const classes = useStyles();

  let { passengerId } = useParams();

  const [passenger, setPassenger] = React.useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(function () {
      //Request
      UsersService.getPassenger(passengerId)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          setIsLoading(false);
          //Get Passenger
          if (response && response.Data !== undefined) {
            setPassenger(response.Data);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 1000);
  }, [passengerId]);

  return (
    <React.Fragment>
      <Card style={{ background: '#f4f6f8' }}>
        <CardContent>
          {isLoading ? (
            <Skeleton count={1} height={50} />
          ) : (
            <Grid
              container
              spacing={2}
              direction="row"
              alignContent="center"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={9}>
                <Typography variant="h6" display="block" gutterBottom>
                  {passenger.Nombre}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  ID: {passenger.UsuarioID}
                </Typography>
              </Grid>
              <Grid>
                <Tooltip title="Regresar">
                  <IconButton
                    color="secondary"
                    size="medium"
                    href={`/passengers/`}
                    aria-label="back to routes"
                  >
                    <ReplyIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Editar">
                  <IconButton
                    color="secondary"
                    size="medium"
                    href={`/passengers/edit/${passenger.UsuarioID}`}
                    aria-label="edit route"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      <Card className={classes.card} style={{ background: '#f4f6f8' }}>
        <CardContent>
          <Grid
            container
            spacing={2}
            direction="row"
            alignContent="center"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Grid item sm={12} xs={12}>
              {isLoading ? (
                <Skeleton count={10} height={50} />
              ) : (
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Compañia
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {passenger.NombreCliente}
                      </TableCell>
                    </TableRow>
                    <TableRow selected>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Nombre de Usuario
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {passenger.NombreUsuario}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Email
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{passenger.Email}</TableCell>
                    </TableRow>
                    <TableRow selected>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Dirección
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {passenger.Direccion}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Telefono 1
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {passenger.Telefono1}
                      </TableCell>
                    </TableRow>
                    <TableRow selected>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Fecha Expiración
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {moment(passenger.FechaExpiracion).isValid()
                          ? moment(passenger.FechaExpiracion).format(
                              'DD/MM/YYYY'
                            )
                          : passenger.FechaExpiracion}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Estado
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {passenger.EstaActivo ? (
                          <Chip
                            icon={<CheckIcon style={{ color: green[700] }} />}
                            label="Activo"
                            className={classes.chipActive}
                          />
                        ) : (
                          <Chip
                            icon={<CloseIcon style={{ color: red[700] }} />}
                            label="Inactivo"
                            className={classes.chipInactive}
                          />
                        )}
                      </TableCell>

                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Rutas Asignadas
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {passenger.rutasDisponiblesPorUsuario &&
                          passenger.rutasDisponiblesPorUsuario.map( _item => 
                            <Chip
                              key={_item.RutaID}
                              label={_item.NombreRuta}
                              className={classes.chipActive}
                            />
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
