import { useState, useEffect } from 'react';

import { getSessionCookie } from './../services/session';

export default function useRole() {
  const [role, setRole] = useState('logged');

  //Handle Session Role
  useEffect(() => {
    let session = getSessionCookie();
    if (
      session !== undefined &&
      session.role !== undefined &&
      session.role !== null
    ) {
      setRole(session.role);
    }
  }, [role]);

  return role;
}