import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import SaveIcon from '@material-ui/icons/Save';
import ReplyIcon from '@material-ui/icons/Reply';

import { green, red } from '@material-ui/core/colors';

import StopsService from './../../../services/api/stops';

import { StopSelect } from './../../Common/Selects/Stop';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  select: {
    width: '100%',
  },
  formControl: {
    marginTop: '0px',
  },
}));

const GreenButton = withStyles((theme) => ({
  root: {
    color: 'white', //theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

export const RouteAssign = (props) => {
  const classes = useStyles();

  let { routeId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState({
    error: false,
    errorDescription: null,
  });

  const [stopId, setStopId] = useState();

  const [routeStop, setRouteStop] = useState({
    status: true,
    orientation: true,
  });

  let stopSchema = yup.object().shape({
    order: yup.string().required(),
    time: yup.string().required(),
    orientation: yup.string(),
    status: yup.string(),
    stop: yup.string(),
  });

  const { handleSubmit, errors, control, setValue } = useForm({
    validationSchema: stopSchema,
  });

  const handleStopChange = (value) => {
    setStopId(value);
    setValue('stop', value);
    setRouteStop({ ...routeStop, stop: value });
  };

  const handleStatusChange = (event) => {
    setValue('status', event.target.value);
    setRouteStop({ ...routeStop, status: event.target.value });
  };

  const handleOrientationChange = (event) => {
    setValue('orientation', event.target.value);
    setRouteStop({ ...routeStop, orientation: event.target.value });
  };

  const handleCancelClick = () => {
    props.modalStatusHandler();
  };

  const onSubmit = (data) => {
    data.status = routeStop.status;

    setIsLoading(true);
    setRequestError({ error: false, errorDescription: null });

    setTimeout(function () {
      //Request
      StopsService.assignRouteStop(routeId, stopId, data)
        .then((response) => {
          if (response === null) {
            throw new Error('Request error');
          }
          if (response.IsValid && response.Data !== undefined) {
            handleCancelClick();
            props.refreshListHandler();
            // history.push(`/stops/detail/${response.Data.RutaID}`);
          } else {
            throw response.Message;
          }
        })
        .catch((e) => {
          console.log(e);
          setRequestError({
            error: true,
            errorDescription: e.status ? e.data.Message : e,
          });
          setIsLoading(false);
        });
    }, 500);
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        direction="row"
        alignContent="center"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography>Asignar nueva ruta</Typography>
        </Grid>
      </Grid>

      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Grid
          container
          spacing={1}
          direction="row"
          alignContent="center"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Grid item xs>
            <StopSelect
              routeId={routeId}
              control={control}
              error={errors.routeId}
              parentCallback={handleStopChange}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          direction="row"
          alignContent="center"
          alignItems="flex-start"
          justifyContent="space-between"
          style={{ marginTop: '5px' }}
        >
          <Grid item sm={3} xs={12}>
            <Controller
              as={<TextField />}
              control={control}
              rules={{ required: true }}
              name="order"
              defaultValue=""
              variant="outlined"
              margin="none"
              fullWidth
              required
              id="order"
              label="Orden"
              autoComplete="Orden"
              error={errors.order ? true : false}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Controller
              as={<TextField />}
              control={control}
              rules={{ required: true }}
              name="time"
              defaultValue=""
              variant="outlined"
              margin="none"
              fullWidth
              required
              id="time"
              label="Hora de la Parada"
              autoComplete="Hora"
              error={errors.time ? true : false}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Controller
              as={
                <FormControl variant="outlined" className={classes.select}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Orientación
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    autoWidth
                    value={routeStop.orientation}
                    onChange={handleOrientationChange}
                    label="Orientación"
                  >
                    <MenuItem value={true}>Ida</MenuItem>
                    <MenuItem value={false}>Vuelta</MenuItem>
                  </Select>
                </FormControl>
              }
              className={classes.select}
              name="orientation"
              control={control}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Controller
              as={
                <FormControl variant="outlined" className={classes.select}>
                  <InputLabel id="status-select-label">Estado</InputLabel>
                  <Select
                    labelId="status-select-label"
                    id="status-select"
                    autoWidth
                    value={routeStop.status}
                    onChange={handleStatusChange}
                    label="Estado"
                  >
                    <MenuItem value={true}>Activo</MenuItem>
                    <MenuItem value={false}>Inactivo</MenuItem>
                  </Select>
                </FormControl>
              }
              className={classes.select}
              name="status"
              control={control}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} direction="row" justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={handleCancelClick}
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<ReplyIcon />}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <GreenButton
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<SaveIcon />}
            >
              Asignar Parada
            </GreenButton>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};
