import { useState, useEffect } from 'react';

import UsersServices from './../services/api/users';

export default function useDrivers() {
  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    UsersServices.getDrivers()
      .then((response) => {
        if (response === null || !response.IsValid) {
          throw response.Message;
        }
        //Get Passengers
        if (response && response.Data && response.Data.length > 0) {
          setDrivers(response.Data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return drivers;
}