import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import Chip from '@material-ui/core/Chip';
import { green, red } from '@material-ui/core/colors';

import goingMarkerIcon from './../../../assets/images/maps/ic_location_map_start.png';
import backMarkerIcon from './../../../assets/images/maps/ic_location_map_end.png';

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: '565px',
  },
  table: {
    minWidth: 650,
  },
  th: {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
  header: {
    marginBottom: '20px',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
  orientationIcon: {
    width: '15px',
  },
});

export const RoutesCoordinatesList = (props) => {
  const classes = useStyles();

  const handleOrientation = (orientation) => {
    if (orientation === 1) {
      return (
        <img
          src={goingMarkerIcon}
          className={classes.orientationIcon}
          alt="Go Icon"
        />
      );
    } else if (orientation === 2) {
      return (
        <img
          src={backMarkerIcon}
          className={classes.orientationIcon}
          alt="Back Icon"
        />
      );
    }
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.th} align="center">
                ID
              </TableCell>
              <TableCell className={classes.th} align="left">
                Orden
              </TableCell>
              <TableCell className={classes.th} align="center">
                Orientacion
              </TableCell>
              <TableCell className={classes.th} align="center">
                Coordenadas (Latitud, Longitud)
              </TableCell>
              <TableCell className={classes.th} align="center">
                Estado
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.routeCoordinates.length > 0 &&
              props.routeCoordinates.map((row) => (
                <TableRow key={row.RutaCoordenadaID}>
                  <TableCell align="center">{row.RutaCoordenadaID}</TableCell>
                  <TableCell align="left">{row.Orden}</TableCell>
                  <TableCell align="center">
                    {handleOrientation(row.Orientacion)}
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption" display="block" gutterBottom>
                      {row.Latitud}, {row.Longitud}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {row.EstaActivo ? (
                      <Chip
                        icon={<CheckIcon style={{ color: green[700] }} />}
                        label="Activa"
                        className={classes.chipActive}
                      />
                    ) : (
                      <Chip
                        icon={<CloseIcon style={{ color: red[700] }} />}
                        label="Inactiva"
                        className={classes.chipInactive}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};
