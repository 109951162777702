import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

import Can from './../../Can';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Input, TextField } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { green, red } from '@material-ui/core/colors';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import { Alert, AlertTitle } from '@material-ui/lab';
import Chip from '@material-ui/core/Chip';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

import { CompanySelect } from './../../Common/Selects/Company';
import { GenericSelect } from './../../Common/Selects/Generic';

import { getSessionCookie } from './../../../services/session';

import UsersServices from './../../../services/api/users';
import ClientsService from './../../../services/api/clients';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const RedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

const GreenButton = withStyles((theme) => ({
  root: {
    color: 'white', //theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

const localeMap = {
  es: esLocale,
};

export const PassengersCreate = () => {
  const classes = useStyles();
  let history = useHistory();
  const [locale] = useState('es');

  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [passengerRoutes, setPassengerRoutes] = useState([]);
  const [selectedRoutes, setSelectedRoutes] = useState([]);
  const [requestError, setRequestError] = useState({
    error: false,
    errorDescription: null,
  });
  const [role, setRole] = React.useState('logged');

  const [passenger, setPassenger] = React.useState({
    status: true,
    clientId: '',
    companyId: ''
  });

  //Handle Session Role
  useEffect(() => {
    let session = getSessionCookie();
    if (
      session !== undefined &&
      session.role !== undefined &&
      session.role !== null
    ) {
      setRole(session.role);
    }
  }, [role]);

  const handleStatusChange = (event) => {
    setValue('status', event.target.value);
    setPassenger({ ...passenger, status: event.target.value });
  };

  const handleExpirationDateChange = (date) => {
    setValue('expiration_date', date);
    setPassenger({ ...passenger, expiration_date: date });
  };

  let userSchema = yup.object().shape({
    name: yup.string().required(),
    address: yup.string(),
    email: yup.string(),
    phone1: yup.string(),
    phone2: yup.string(),
    expiration_date: yup.string().required(),
    username: yup.string().required(),
    password: yup.string().required(),
    status: yup.boolean().required(),
    companyId: yup.number(),
    clientId: yup.number().required(),
  });

  const { handleSubmit, errors, control, setValue } = useForm({
    validationSchema: userSchema,
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    setRequestError({ error: false, errorDescription: null });

    //Request
    setTimeout(function () {
      //Request
      UsersServices.createPassenger(data)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          if (response.IsValid && response.Data !== undefined) {
            history.push(`/passengers/detail/${response.Data.UsuarioID}`);
          } else {
            throw response.Message;
          }
        })
        .catch((e) => {
          setRequestError({
            error: true,
            errorDescription: e.status ? e.Data.Message : e,
          });
          setIsLoading(false);
        });
    }, 500);
  };

  const handleCompanyChange = (value) => {
    setValue('companyId', value);
    setPassenger({ ...passenger, companyId: value });
  };

  const handleClientChange = (value) => {
    setValue('clientId', value);
    setPassenger({ ...passenger, clientId: value });
    fetchAvailableRoutes(value);
  };

  const handleRouteChange = (event) => {
    setSelectedRoutes(event.target.value);
    setValue('routes', event.target.value);
    setPassenger({ ...passenger, routes: event.target.value });
  };

  const fetchAvailableRoutes = (clientId) => {
    UsersServices.getPassengerAvailableRoutesByClient(clientId)
      .then((response) => {
        if (response === null || !response.IsValid) {
          throw response.Message;
        }
        //Get Passenger
        if (response && response.Data !== undefined) {
          setPassengerRoutes(response.Data);
          setIsLoading(false);
        } else if (response.Data === null) {
          history.push(`/passengers/`);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  //Handle Clientes Select Content
  useEffect(() => {
    let session = getSessionCookie();

    if (
      session !== undefined &&
      session.role !== undefined &&
      session.role !== null
    ) {
      setRole(session.role);
    }
    //Request
    if (passenger.companyId !== '' || session.role === 'client') {
      ClientsService.getList(passenger.companyId !== '' ? passenger.companyId : null)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          //
          if (response && response.Data !== undefined) {
            setClients(response.Data);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }

  }, [passenger.companyId]);

  return (
    <React.Fragment>
      <Card className={classes.card} style={{ background: '#f4f6f8' }}>
        <CardContent>
          <form
            className={classes.form}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            {isLoading ? (
              <Skeleton count={5} height={50} />
            ) : (
              <React.Fragment>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignContent="center"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Grid item sm={6} xs={12}>
                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="name"
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="name"
                      label="Nombre del Pasajero"
                      autoComplete="name"
                      autoFocus
                      error={errors.name ? true : false}
                    />

                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="username"
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="username"
                      label="Nombre de Usuario"
                      autoComplete="username"
                      error={errors.username ? true : false}
                    />

                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="address"
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="address"
                      label="Dirección"
                      autoComplete="address"
                      error={errors.address ? true : false}
                    />

                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="phone1"
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="phone1"
                      label="Telefono 1"
                      autoComplete="phone1"
                      error={errors.phone1 ? true : false}
                    />

                    <Can
                      role={role}
                      perform="companies:read"
                      yes={() => (
                        <CompanySelect
                          parentCallback={handleCompanyChange}
                          companyId={passenger.companyId}
                          control={control}
                          error={errors.companyId ? true : false}
                        />
                      )}
                      no={() => ''}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="email"
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email"
                      autoComplete="email"
                      error={errors.email ? true : false}
                    />

                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="password"
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="password"
                      label="Contraseña"
                      autoComplete="password"
                      error={errors.password ? true : false}
                    />

                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={localeMap[locale]}
                    >
                      <Controller
                        as={
                          <KeyboardDatePicker
                            inputVariant="outlined"
                            margin="normal"
                            label="Fecha de Expiracion"
                            id="expiration_date"
                            name="expiration_date"
                            fullWidth
                            disablePast={true}
                            format={'dd/MM/yyyy'}
                            autoOk
                            value={passenger.expiration_date || ''}
                            onChange={handleExpirationDateChange}
                          />
                        }
                        control={control}
                        name="expiration_date"
                        required
                        error={errors.expiration_date ? true : false}
                      />
                    </MuiPickersUtilsProvider>

                    <Controller
                      as={
                        <FormControl
                          variant="outlined"
                          className={classes.select}
                        >
                          <InputLabel id="status-label">Estado</InputLabel>
                          <Select
                            labelId="status-label"
                            id="status"
                            autoWidth
                            value={passenger.status}
                            onChange={handleStatusChange}
                            label="Estado"
                          >
                            <MenuItem value={true}>Activo</MenuItem>
                            <MenuItem value={false}>Inactivo</MenuItem>
                          </Select>
                        </FormControl>
                      }
                      className={classes.select}
                      name="status"
                      defaultValue={passenger.status}
                      control={control}
                    />

                    <Can
                      role={role}
                      perform="clients:read"
                      yes={() => (
                        <GenericSelect
                          className={classes.clientSelect}
                          parentCallback={handleClientChange}
                          parentExtraCallback={handleCompanyChange}
                          itemKey="TipoUsuarioID"
                          label="Clientes"
                          itemValue="TipoUsuarioID"
                          itemText="Nombre"
                          inputName="clientId"
                          itemExtra="ClienteID"
                          items={clients.length > 0 ? clients : []}
                          control={control}
                          error={errors.clientId ? true : false}
                        />
                      )}
                      no={() => ''}
                    />

                    <Controller
                      as={
                        <FormControl variant="outlined" className={classes.select}>
                          <InputLabel id="demo-mutiple-chip-label-label">Rutas</InputLabel>
                            <Select
                              labelId="demo-mutiple-chip-label-label"
                              id="demo-mutiple-chip-label"
                              name="routes"
                              multiple
                              value={selectedRoutes || []}
                              onChange={handleRouteChange}
                              input={<Input id="select-multiple-chip" />}
                              renderValue={(selectedRoutes) => (
                                <div className={classes.chips}>
                                  {selectedRoutes.map((value) => {
                                    if(passengerRoutes.find( (_item) => _item.RutaID === value)){
                                      return <Chip key={value} label={passengerRoutes.find( (_item) => _item.RutaID === parseInt(value)).Nombre} className={classes.chip} />
                                    }
                                  })}
                                </div>
                              )}
                            >
                              {passengerRoutes.length === 0 
                                ? 
                                  <MenuItem key="empty_routes">
                                    No hay rutas disponibles
                                  </MenuItem>
                                :
                                passengerRoutes.map( (_item) => {
                                  return (
                                    <MenuItem key={_item.RutaID} value={_item.RutaID}>
                                      {_item.Nombre}
                                    </MenuItem>
                                  )
                                })
                              }
                              
                          </Select>
                        </FormControl>
                      }
                      className={classes.select}
                      name="routes"
                      defaultValue={passenger.routes}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs sm>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <GreenButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.button}
                          startIcon={<SaveIcon />}
                        >
                          Guardar Cambios
                        </GreenButton>
                      </Grid>
                      <Grid item>
                        <RedButton
                          component={Link}
                          to="/passengers"
                          variant="contained"
                          color="secondary"
                          size="large"
                          className={classes.button}
                          startIcon={<CancelIcon />}
                        >
                          Cancelar
                        </RedButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {requestError.error && (
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-end"
                  >
                    <Grid item xs>
                      <Alert severity="error">
                        <AlertTitle>Algo salió mal</AlertTitle>
                        {requestError.errorDescription}
                      </Alert>
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            )}
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
