import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import Can from './../../Can';

import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { green, red } from '@material-ui/core/colors';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import { Alert, AlertTitle } from '@material-ui/lab';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

import { MultipleSelect } from './../../Common/Selects/Multiple';
import { CompanySelect } from './../../Common/Selects/Company';

import NotificationsService from './../../../services/api/notifications';
import ClientsService from './../../../services/api/clients';

import { getSessionCookie } from './../../../services/session';

const useStyles = makeStyles({
  table: {
    marginTop: '10px',
  },
  cardContent: {
    padding: '0px !important',
  },
  card: {
    marginTop: '1rem',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
  select: {
    width: '100%',
    marginTop: '1em',
    marginBottom: '1em',
  },
  clientSelect: {
    marginBottom: '12px',
  },
});

const RedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

const GreenButton = withStyles((theme) => ({
  root: {
    color: 'white', //theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

export const NotificationCreate = () => {
  const classes = useStyles();
  let history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState({
    error: false,
    errorDescription: null,
  });
  const [role, setRole] = React.useState('logged');

  const [notification, setNotification] = React.useState({
    id: 0,
    clientId: '',
    companyId: '',
    submit_date: new Date(),
  });
  const [clients, setClients] = React.useState([]);

  const handleSubmitDateChange = (date) => {
    setNotification({ ...notification, submit_date: date });
  };

  let notificationSchema = yup.object().shape({
    title: yup.string().required(),
    content: yup.string().required(),
    submit_date: yup.string().required(),
    companyId: yup.number(),
    clientId: yup.number().required(),
  });

  const { handleSubmit, errors, control, setValue } = useForm({
    validationSchema: notificationSchema,
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    setRequestError({ error: false, errorDescription: null });

    setTimeout(function () {
      //Request
      NotificationsService.create(data)
        .then((response) => {
          if (response === null) {
            throw new Error('Request error');
          }
          if (response.IsValid && response.Data !== undefined) {
            history.push(`/notifications/detail/${response.Data.NotificacionID}`);
          } else {
            throw response.Message;
          }
        })
        .catch((e) => {
          console.log(e);
          setRequestError({
            error: true,
            errorDescription: e.status ? e.data.Message : e,
          });
          setIsLoading(false);
        });
    }, 1000);
  };

  const handleClientChange = (value) => {
    setValue('clientId', value);
    setNotification({ ...notification, clientId: value });
  };

  const handleCompanyChange = (value) => {
    setValue('companyId', value);
    setNotification({ ...notification, companyId: parseInt(value) });
  };

  //Handle Clientes Select Content
  useEffect(() => {
    let isCancelled = false;
    setTimeout(function () {
      let session = getSessionCookie();

      if (
        session !== undefined &&
        session.role !== undefined &&
        session.role !== null
      ) {
        setRole(session.role);
      }
      //Request
      if (notification.companyId !== '' || session.role === 'client') {
        ClientsService.getList(notification.companyId !== '' ? notification.companyId : null)
          .then((response) => {
            if (response === null || !response.IsValid) {
              throw response.Message;
            }
            //
            if (response && response.Data !== undefined) {
              if (!isCancelled) {
                setClients(response.Data);
              }
            }
          })
          .catch((e) => {
            setIsLoading(false);
          });
      }
    }, 0);

    return () => {
      isCancelled = true;
    };
  }, [notification.companyId]);

  return (
    <React.Fragment>
      <Card className={classes.card} style={{ background: '#f4f6f8' }}>
        <CardContent>
          <form
            className={classes.form}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            {isLoading ? (
              <Skeleton count={5} height={50} />
            ) : (
              <React.Fragment>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignContent="center"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Grid item sm={6} xs={12}>
                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="title"
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="title"
                      label="Titulo de la notificación"
                      autoComplete="title"
                      autoFocus
                      error={errors.title ? true : false}
                    />
                    <Controller
                      as={<TextField />}
                      control={control}
                      rules={{ required: true }}
                      name="content"
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="content"
                      label="Texto de la notificación"
                      autoComplete="content"
                      multiline
                      rows={4}
                      error={errors.content ? true : false}
                    />

                  </Grid>

                  <Grid item sm={6} xs={12}>
                    
                  <Can
                      role={role}
                      perform="companies:read"
                      yes={() => (
                        <CompanySelect
                          parentCallback={handleCompanyChange}
                          companyId={notification.companyId}
                          control={control}
                          error={errors.companyId ? true : false}
                        />
                      )}
                      no={() => ''}
                    />

                    <Can
                      role={role}
                      perform="clients:read"
                      yes={() => (
                        <MultipleSelect
                          className={classes.clientSelect}
                          parentCallback={handleClientChange}
                          parentExtraCallback={handleCompanyChange}
                          itemKey="TipoUsuarioID"
                          label="Clientes"
                          itemValue="TipoUsuarioID"
                          itemText="Nombre"
                          inputName="clientId"
                          itemExtra="ClienteID"
                          items={clients.length > 0 ? clients : []}
                          control={control}
                          error={errors.clientId ? true : false}
                        />
                      )}
                      no={() => ''}
                    />

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Controller
                            as={
                              <DateTimePicker
                                inputVariant="outlined"
                                margin="normal"
                                id="submit_date"
                                name="submit_date"
                                label="Programar envio"
                                fullWidth
                                disablePast
                                value={notification.submit_date}
                                onChange={handleSubmitDateChange}
                              />
                            }
                            control={control}
                            name="submit_date"
                            required
                            error={errors.submit_date ? true : false}
                          />
                        </MuiPickersUtilsProvider>

                    

                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <GreenButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.button}
                          startIcon={<SaveIcon />}
                        >
                          Guardar Cambios
                        </GreenButton>
                      </Grid>
                      <Grid item>
                        <RedButton
                          component={Link}
                          to="/notifications"
                          variant="contained"
                          color="secondary"
                          size="large"
                          className={classes.button}
                          startIcon={<CancelIcon />}
                        >
                          Cancelar
                        </RedButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {requestError.error && (
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-end"
                  >
                    <Grid item xs>
                      <Alert severity="error">
                        <AlertTitle>Algo salió mal</AlertTitle>
                        {requestError.errorDescription}
                      </Alert>
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            )}
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
