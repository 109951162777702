import React from 'react';
import { Link } from 'react-router-dom';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';
import RvHookupIcon from '@material-ui/icons/RvHookup';

import { CardContent } from '@material-ui/core';

export const Reports = () => {
  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Reporte de Viajes
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Encontrarás los viajes que han sido realizados por los
                conductores en las diferentes rutas disponibles.
                &nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                component={Link}
                to="/reports/travels"
                style={{ marginLeft: 'auto' }}
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<RvHookupIcon color="secondary" />}
              >
                Ver Reporte
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Reporte de Aforo
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Encontrarás el detalle de los pasajeros que han ingresado a las
                unidades de las diferentes rutas disponibles.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                component={Link}
                to="/reports/capacity"
                style={{ marginLeft: 'auto' }}
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<AirlineSeatReclineExtraIcon color="secondary" />}
              >
                Ver Reporte
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
