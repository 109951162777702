import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';

import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import EditIcon from '@material-ui/icons/Edit';
import { CardContent, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import Pagination from '@material-ui/lab/Pagination';

import Chip from '@material-ui/core/Chip';
import { green, red } from '@material-ui/core/colors';

import DriversService from './../../../services/api/drivers';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  th: {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
  searchIcon: {
    marginRight: '16px',
  },
  paper: {
    height: '42px',
    display: 'flex',
    padding: '0px 16px',
    alignItems: 'center',
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: '1',
    maxWidth: '480px',
    flexBasis: '480px',
  },
  searchButton: {
    marginLeft: '16px',
    marginRight: '5px',
  },
  searchContainer: {
    marginBottom: '1em',
  },
  cardContent: {
    paddingBottom: '1em !important',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
  paginatorContainer: {
    marginTop: '1em',
  },
});

export const DriversList = () => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    status: '',
  });

  const [drivers, setDrivers] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = React.useState(false);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setIsLoading(true);

    DriversService.getList({ cancelToken: source.token })
      .then((response) => {
        if (response === null || !response.IsValid) {
          throw response.Message;
        }
        setIsLoading(false);
        //Get Drivers
        if (response && response.Data && response.Data.length > 0) {
          setDrivers(response.Data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          console.log('axios Cancel');
        } else {
          throw Error(e);
        }
        setIsLoading(false);
      });

    return () => {
      console.log('cleanup');
      source.cancel();
    };
  }, []);

  const handleFilters = (event) => {
    setFilters(filters ? false : true);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <React.Fragment>
      <Card
        className={classes.searchContainer}
        style={{ background: '#f4f6f8' }}
      >
        <CardContent>
          <Grid
            container
            className={classes.header}
            alignContent="center"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <div className={classes.searchBox}>
                <Paper elevation={1} className={classes.paper}>
                  <SearchIcon className={classes.searchIcon} />
                  <InputBase placeholder="Buscar" />
                </Paper>
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  className={classes.searchButton}
                >
                  Buscar
                </Button>
                <Button
                  onClick={handleFilters}
                  size="large"
                  color="secondary"
                  startIcon={<FilterListIcon />}
                  variant="outlined"
                >
                  Filtros
                </Button>
              </div>
            </Grid>
            <Grid item>
              <Button
                component={Link}
                to="/drivers/create"
                size="large"
                variant="contained"
                color="primary"
              >
                Crear Nuevo Chofer
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {filters && (
        <Card
          className={classes.searchContainer}
          style={{ background: '#f4f6f8' }}
        >
          <CardContent className={classes.cardContent}>
            <Grid
              container
              className={classes.header}
              alignContent="center"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  Filtros Avanzados &nbsp;
                </Typography>
              </Grid>
              <Grid item>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-status-native-simple">
                    Estado
                  </InputLabel>
                  <Select
                    native
                    value={state.status}
                    onChange={handleChange}
                    label="Status"
                    inputProps={{
                      name: 'status',
                      id: 'outlined-status-native-simple',
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value={true}>Activo</option>
                    <option value={false}>Inactivo</option>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      <Card style={{ background: '#f4f6f8' }}>
        <CardContent>
          <Grid container className={classes.header} direction="column">
            <Grid item sm={12} xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.th} align="center">
                        ID
                      </TableCell>
                      <TableCell className={classes.th} align="left">
                        Nombre
                      </TableCell>
                      <TableCell className={classes.th} align="center">
                        Nombre de Usuario
                      </TableCell>
                      <TableCell className={classes.th} align="center">
                        Email
                      </TableCell>
                      <TableCell className={classes.th} align="center">
                        Teléfono
                      </TableCell>
                      <TableCell className={classes.th} align="center">
                        Estado
                      </TableCell>
                      <TableCell className={classes.th} align="center">
                        Acciones
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading && (
                      <TableRow key="skeletonRow" width={100}>
                        <TableCell colSpan="8" align="center">
                          <Skeleton count={8} height={50} />
                        </TableCell>
                      </TableRow>
                    )}
                    {drivers.length <= 0 && !isLoading && (
                      <TableRow key="skeletonRowSinResultados" width={100}>
                        <TableCell colSpan="8" align="center">
                          <h2>Sin Resultados</h2>
                        </TableCell>
                      </TableRow>
                    )}
                    {drivers.map((row) => (
                      <TableRow key={row.UsuarioID}>
                        <TableCell align="center">{row.UsuarioID}</TableCell>
                        <TableCell align="left">{row.Nombre}</TableCell>
                        <TableCell align="center">
                          {row.NombreUsuario}
                        </TableCell>
                        <TableCell align="center">{row.Email}</TableCell>
                        <TableCell align="center">{row.Telefono1}</TableCell>

                        <TableCell align="center">
                          {row.EstaActivo ? (
                            <Chip
                              icon={<CheckIcon style={{ color: green[700] }} />}
                              label="Activo"
                              className={classes.chipActive}
                            />
                          ) : (
                            <Chip
                              icon={<CloseIcon style={{ color: red[700] }} />}
                              label="Inactivo"
                              className={classes.chipInactive}
                            />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Ver detalle">
                            <IconButton
                              color="secondary"
                              size="small"
                              href={`/drivers/detail/${row.UsuarioID}`}
                              aria-label="see detail"
                            >
                              <ZoomInIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Editar">
                            <IconButton
                              color="secondary"
                              size="small"
                              href={`/drivers/edit/${row.UsuarioID}`}
                              aria-label="edit route"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            alignContent="flex-end"
            alignItems="flex-end"
            className={classes.paginatorContainer}
          >
            <Grid item>
              <Pagination count={10} variant="outlined" shape="rounded" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
