import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import { isUserLogged } from './services/session';

// import DashboardLayout from './views/layouts/dashboard';

/* Pages */
import { NotFoundPage } from './views/NotFoundPage';
import { LandingPage } from './views/LandingPage';
import { LoginPage } from './views/LoginPage';
import { DashboardPage } from './views/DashboardPage';
import { ProfilePage } from './views/ProfilePage';
import { RoutesPage } from './views/RoutesPage';
import { RouteDetailsPage } from './views/RoutesPage/RouteDetailsPage';
import { RoutesEditPage } from './views/RoutesPage/RouteEditPage';
import { RoutesCreatePage } from './views/RoutesPage/RouteCreatePage';
import { StopsPage } from './views/StopsPage';
import { StopsCreatePage } from './views/StopsPage/StopsCreatePage';
import { StopsDetailPage } from './views/StopsPage/StopsDetailPage';
import { StopsEditPage } from './views/StopsPage/StopsEditPage';

import { PassengersPage } from './views/PassengersPage';
import { PassengerCreatePage } from './views/PassengersPage/PassengerCreatePage';
import { PassengerBatchPage } from './views/PassengersPage/PassengerBatchPage';
import { PassengerDetailPage } from './views/PassengersPage/PassengerDetailPage';
import { PassengerEditPage } from './views/PassengersPage/PassengerEditPage';

import { VehiclesPage } from './views/VehiclesPage';
import { VehiclesCreatePage } from './views/VehiclesPage/VehiclesCreatePage';
import { VehiclesDetailsPage } from './views/VehiclesPage/VehiclesDetailPage';
import { VehiclesEditPage } from './views/VehiclesPage/VehiclesEditPage';
import { DriversPage } from './views/DriversPage';
import { DriversDetailsPage } from './views/DriversPage/DriversDetailsPage';
import { DriversEditPage } from './views/DriversPage/DriversEditPage';
import { DriversCreatePage } from './views/DriversPage/DriversCreatePage';
import { ReportsPage } from './views/ReportsPage';
import { ReportsTravelsPage } from './views/ReportsPage/TravelsPage';
import { ReportsCapacityPage } from './views/ReportsPage/CapacityPage';
import { ReportsPassengersPage } from './views/ReportsPage/PassengersPage';
import { NotificationsPage } from './views/NotificationsPage';
import { NotificationCreatePage } from './views/NotificationsPage/NotificationCreatePage';
import { NotificationDetailsPage } from './views/NotificationsPage/NotificationDetailsPage';
import { NotificationEditPage } from './views/NotificationsPage/NotificationEditPage';

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isUserLogged() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes = () => {
  return (
    <Router>
      <Route>
        <Switch>
          <PrivateRoute exact path="/dashboard">
            <DashboardPage />
          </PrivateRoute>

          <PrivateRoute exact path="/routes">
            <RoutesPage />
          </PrivateRoute>

          <PrivateRoute exact path="/routes/detail/:routeId/">
            <RouteDetailsPage />
          </PrivateRoute>

          <PrivateRoute exact path="/routes/create/">
            <RoutesCreatePage />
          </PrivateRoute>

          <PrivateRoute exact path="/routes/edit/:routeId">
            <RoutesEditPage />
          </PrivateRoute>

          <PrivateRoute exact path="/passengers">
            <PassengersPage />
          </PrivateRoute>

          <PrivateRoute exact path="/passengers/create/">
            <PassengerCreatePage />
          </PrivateRoute>

          <PrivateRoute exact path="/passengers/batch/">
            <PassengerBatchPage />
          </PrivateRoute>

          <PrivateRoute exact path="/passengers/detail/:passengerId/">
            <PassengerDetailPage />
          </PrivateRoute>

          <PrivateRoute exact path="/passengers/edit/:passengerId">
            <PassengerEditPage />
          </PrivateRoute>

          <PrivateRoute exact path="/stops">
            <StopsPage />
          </PrivateRoute>

          <PrivateRoute exact path="/stops/create">
            <StopsCreatePage />
          </PrivateRoute>

          <PrivateRoute exact path="/stops/detail/:stopId">
            <StopsDetailPage />
          </PrivateRoute>

          <PrivateRoute exact path="/stops/edit/:stopId">
            <StopsEditPage />
          </PrivateRoute>

          <PrivateRoute exact path="/drivers">
            <DriversPage />
          </PrivateRoute>

          <PrivateRoute exact path="/drivers/detail/:driverId">
            <DriversDetailsPage />
          </PrivateRoute>

          <PrivateRoute exact path="/drivers/edit/:driverId">
            <DriversEditPage />
          </PrivateRoute>

          <PrivateRoute exact path="/drivers/create">
            <DriversCreatePage />
          </PrivateRoute>

          <PrivateRoute exact path="/vehicles">
            <VehiclesPage />
          </PrivateRoute>

          <PrivateRoute exact path="/vehicles/create/">
            <VehiclesCreatePage />
          </PrivateRoute>

          <PrivateRoute exact path="/vehicles/detail/:vehicleId/">
            <VehiclesDetailsPage />
          </PrivateRoute>

          <PrivateRoute exact path="/vehicles/edit/:vehicleId/">
            <VehiclesEditPage />
          </PrivateRoute>

          <PrivateRoute exact path="/reports">
            <ReportsPage />
          </PrivateRoute>

          <PrivateRoute exact path="/reports/travels">
            <ReportsTravelsPage />
          </PrivateRoute>

          <PrivateRoute exact path="/reports/capacity">
            <ReportsCapacityPage />
          </PrivateRoute>

          <PrivateRoute exact path="/reports/passengers/:query/">
            <ReportsPassengersPage />
          </PrivateRoute>

          <PrivateRoute exact path="/notifications">
            <NotificationsPage />
          </PrivateRoute>

          <PrivateRoute exact path="/notifications/create">
            <NotificationCreatePage />
          </PrivateRoute>

          <PrivateRoute exact path="/notifications/detail/:notificationId/">
            <NotificationDetailsPage />
          </PrivateRoute>

          <PrivateRoute exact path="/notifications/edit/:notificationId/">
            <NotificationEditPage />
          </PrivateRoute>

          <PrivateRoute path="/profile">
            <ProfilePage />
          </PrivateRoute>

          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/" component={LandingPage} />

          <Route component={NotFoundPage} />
        </Switch>
      </Route>
    </Router>
  );
};

export default Routes;
