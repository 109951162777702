import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import { green, red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';

import { Typography, CardContent } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ReplyIcon from '@material-ui/icons/Reply';

import VehiclesService from './../../../services/api/vehicles';

const useStyles = makeStyles({
  table: {
    marginTop: '10px',
  },
  cardContent: {
    padding: '0px !important',
  },
  card: {
    marginTop: '1rem',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
});

export const VehiclesDetail = () => {
  const classes = useStyles();

  let { vehicleId } = useParams();

  const [vehicle, setVehicle] = React.useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(function () {
      //Request
      VehiclesService.get(vehicleId)
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          setIsLoading(false);
          //Get Routes
          if (response && response.Data !== undefined) {
            setVehicle(response.Data);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 1000);
  }, [vehicleId]);

  return (
    <React.Fragment>
      <Card style={{ background: '#f4f6f8' }}>
        <CardContent>
          {isLoading ? (
            <Skeleton count={1} height={50} />
          ) : (
            <Grid
              container
              spacing={2}
              direction="row"
              alignContent="center"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={9}>
                <Typography variant="h6" display="block" gutterBottom>
                  {vehicle.Numero}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  ID: {vehicle.UnidadID}
                </Typography>
              </Grid>
              <Grid>
                <Tooltip title="Regresar">
                  <IconButton
                    color="secondary"
                    size="medium"
                    href={`/vehicles/`}
                    aria-label="back to routes"
                  >
                    <ReplyIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Editar">
                  <IconButton
                    color="secondary"
                    size="medium"
                    href={`/vehicles/edit/${vehicle.UnidadID}`}
                    aria-label="edit route"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      <Card className={classes.card} style={{ background: '#f4f6f8' }}>
        <CardContent>
          <Grid
            container
            spacing={2}
            direction="row"
            alignContent="center"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Grid item sm={12} xs={12}>
              {isLoading ? (
                <Skeleton count={10} height={50} />
              ) : (
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Titular
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{vehicle.Titular}</TableCell>
                    </TableRow>
                    <TableRow selected>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Tipo Unidad
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{vehicle.TipoUnidad}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Chofer
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {vehicle.NombreChofer}
                      </TableCell>
                    </TableRow>
                    <TableRow selected>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Ruta
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{vehicle.NombreRuta}</TableCell>
                    </TableRow>
                    <TableRow >
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Fecha Creacion
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {vehicle.FechaCreacion}
                      </TableCell>
                    </TableRow>
                    <TableRow selected>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          IMEI
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {vehicle.Imei}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Cliente
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {vehicle.Titular}
                      </TableCell>
                    </TableRow>
                    <TableRow selected>
                      <TableCell>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          Estado
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {vehicle.EstaActivo ? (
                          <Chip
                            icon={<CheckIcon style={{ color: green[700] }} />}
                            label="Activa"
                            className={classes.chipActive}
                          />
                        ) : (
                          <Chip
                            icon={<CloseIcon style={{ color: red[700] }} />}
                            label="Inactiva"
                            className={classes.chipInactive}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
