import React, { useState, useEffect } from "react";

import { makeStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/DeleteForever';

import useVehicles from "../../../hooks/useVehicles";
import useDrivers from "../../../hooks/useDrivers";

import VehiclesService from "../../../services/api/vehicles";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  header: {
    marginBottom: '1rem'
  },
  formControl: {
    width: '100%'
  }
});

export default function VehiclesModal(props) {

  const classes = useStyles();

  const [selectedVehicle, setSelectedVehicle] =  useState('');
  const [selectedDriver, setSelectedDriver] =  useState('');
  const [assignedVehicles, setSetAssignedVehicles] =  useState('');

  const vehicles = useVehicles();
  const drivers = useDrivers();

  useEffect( () => {
    fetchVehicles();
  }, [])

  const handleVehicleChange = (event) => {
    // Get Vehicle Object
    const vehicle = vehicles.find(item => (item.UnidadID === event.target.value));

    if (vehicle) {
      setSelectedVehicle(vehicle.UnidadID);

      if (vehicle.ChoferUsuarioID) {
        setSelectedDriver(vehicle.ChoferUsuarioID);
      }
    }
  }

  const handleDriverChange = (event) => {
    setSelectedDriver(event.target.value);
  }

  const handleAssignDriver = () => {
    if (!selectedVehicle || !selectedDriver) {
      return ;
    }

    // Assign route to vehicle
    const vehicle = vehicles.find(item => (item.UnidadID === selectedVehicle));

    const data  = {
      UnidadID: selectedVehicle,
      number: vehicle.Numero,
      headline: vehicle.Titular,
      imei: vehicle.Imei,
      driver: selectedDriver,
      clientId: vehicle.TipoUsuarioID,
      route: props.selectedRoute,
      status: vehicle.EstaActivo,
    };

    updateVehicle(data, selectedVehicle);
  }

  const handleRemoveVehicle = (vehicleId) => {
    const vehicle = vehicles.find(item => (item.UnidadID === vehicleId));

    const data  = {
      UnidadID: vehicleId,
      number: vehicle.Numero,
      headline: vehicle.Titular,
      imei: vehicle.Imei,
      driver: vehicle.ChoferUsuarioID,
      clientId: vehicle.TipoUsuarioID,
      route: null,
      status: vehicle.EstaActivo,
    };

    updateVehicle(data, vehicleId);
  }

  const updateVehicle = (data, vehicleId) => {
    VehiclesService.update(vehicleId, data)
      .then((response) => {
        if (response === null || !response.IsValid) {
          throw response.Message;
        }
        if (response.IsValid && response.Data !== undefined) {
          fetchVehicles();
          setSelectedDriver('');
          setSelectedVehicle('');
        } else {
          throw response.Message;
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  const fetchVehicles = () => {
    VehiclesService.getVehiclesByRoute(props.selectedRoute).then((response) => {
      if (response === null || !response.IsValid) {
        throw response.Message;
      }
      //Get assignedVehicles
      if (response && response.Data && response.Data.length > 0) {
        setSetAssignedVehicles(response.Data);
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }

  return (
    <>
      <Grid container className={classes.header} direction="row" spacing={3}>
        <Grid item sm={7} xs={7} md={7}>
          <FormControl variant="outlined"  className={classes.formControl}>
            <InputLabel id="vehicles-label">Unidades Disponibles</InputLabel>
            <Select
              labelId="vehicles-label"
              id="vehicles"
              defaultValue=""
              value={selectedVehicle}
              onChange={handleVehicleChange}
              label="Unidades Disponibles"
              displayEmpty={false}
            >
              { vehicles.length > 0 &&
                vehicles.map(item => {
                  return (
                    <MenuItem 
                      key={'vehicle_'+item.UnidadID} 
                      value={item.UnidadID} 
                      data-driver-name={item.NombreChofer} 
                      data-driver-id={item.ChoferUsuarioID} >
                        {item.Numero}
                      </MenuItem>
                  )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={5} xs={5}  md={5} className={classes.filterBox}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="driver-select-label">Chofer</InputLabel>
            <Select
              labelId="driver-select-label"
              id="drivers-select"
              defaultValue=""
              value={selectedDriver}
              onChange={handleDriverChange}
              label="Chofer"
            >
              {drivers.length > 0 && 
                drivers.map(item => {
                return (
                  <MenuItem key={'driver_'+item.UsuarioID} value={item.UsuarioID}>{item.Nombre}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container className={classes.header} direction="row" spacing={3} justifyContent="flex-end">
        <Grid item className={classes.filterBox}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            startIcon={<AddIcon />}
            onClick={handleAssignDriver}
          >
            Asignar unidad
          </Button>
        </Grid>
      </Grid>
    
      <TableContainer component={'div'}>
        <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Numero</TableCell>
            <TableCell align="left">Chofer</TableCell>
            <TableCell align="center">Titular</TableCell>
            <TableCell align="center">Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assignedVehicles.length > 0 && 
            assignedVehicles.map(item => (
              <TableRow key={item.UnidadID}>
                <TableCell component="th" scope="row" align="center">{item.Numero}</TableCell>
                <TableCell align="left">{item.NombreChofer}</TableCell>
                <TableCell align="center">{item.Titular}</TableCell>
                <TableCell align="center">
                  <Tooltip title="Remover">
                    <IconButton
                      color="secondary"
                      size="small"
                      aria-label="remove vehicle"
                      onClick={() => handleRemoveVehicle(item.UnidadID)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}