import request from './../request';
import { getAuthorizationToken } from './../session';

function getCapacity(filters, signal) {
  return request({
    url: `/reportes/ReporteAforo?${filters}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
    signal,
  });
}

function getTravels(filters, signal) {
  return request({
    url: `/reportes/ReporteJornadas?${filters}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
    signal,
  });
}

function getCapacityReport(filters, signal) {
  return request({
    url: `/reportes/ExportarReporteAforo?${filters}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
    signal,
  });
}

function getTravelsReport(filters, signal) {
  return request({
    url: `/reportes/ExportarReporteJornada?${filters}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
    signal,
  });
}

const ReportsService = {
  getCapacity,
  getTravels,
  getCapacityReport,
  getTravelsReport,
};

export default ReportsService;
