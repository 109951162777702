import request from './../request';
import { getAuthorizationToken } from './../session';

import moment from 'moment';

function getList() {
  return request({
    url: '/Notificaciones/Notificacion',
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function get(notificationId) {
  return request({
    url: `/Notificaciones/Notificacion/${notificationId}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function create(data) {
  return request({
    url: '/Notificaciones/Notificacion/',
    method: 'POST',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      Titulo: data.title,
      Contenido: data.content,
      ClienteID: data.companyId,
      TipoUsuariosIDs: [data.clientId],
      FechaEnvio: moment(data.submit_date).utc(),
    },
  });
}

function update(notificationId, data) {
  return request({
    url: `/Notificaciones/Notificacion/`,
    method: 'PUT',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      NotificacionID: notificationId,
      Titulo: data.title,
      Contenido: data.content,
      ClienteID: data.companyId,
      TipoUsuariosIDs: data.clientId,
      EstaActivo: data.status,
      FechaEnvio: moment(data.submit_date).utc(),
    },
  });
}

function deleteItem(notificationId) {
  return request({
    url: `/Notificaciones/Notificacion/`,
    method: 'DELETE',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      NotificacionID: notificationId,
    },
  });
}

const NotificationsService = {
  get,
  getList,
  create,
  update,
  deleteItem,
};

export default NotificationsService;
