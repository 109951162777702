import React from 'react';

import { Grid } from '@material-ui/core';

import DashboardLayout from './../layouts/dashboard';
import { UserProfile } from '../../components/UserProfile';

export const ProfilePage = () => {
  return (
    <DashboardLayout title={'Mi Perfil'}>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs>
          <UserProfile />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};
