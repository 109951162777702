import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import TimelineIcon from '@material-ui/icons/Timeline';
import TimerIcon from '@material-ui/icons/Timer';

import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';

import Grid from '@material-ui/core/Grid';

import { BarChart } from '../../components/Common/Charts/BarChart';
import { DoughnutChart } from '../../components/Common/Charts/DoughnutChart';

import DashboardLayout from './../layouts/dashboard';
import logo from './../../assets/images/logo.png';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardContent: {
    paddingBottom: '0px !important',
  },
  analyticsIcons: {
    fontSize: 80,
    marginLeft: '1.5rem',
  },
});

export const DashboardPage = () => {
  const classes = useStyles();
  const cursor =
    "data:image/svg+xml;utf8, <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' style='font-size:24px'><text y='50'>🚌</text></svg>";
  const cursorEnrutate = `url("${cursor}"), auto`;

  return (
    <DashboardLayout title={'Inicio'}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <img
            style={{
              cursor: cursorEnrutate,
            }}
            src={logo}
            alt="Enrutate Logos"
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );

  // return (
  //   <DashboardLayout title={'Inicio'}>
  //     <Grid
  //       container
  //       spacing={2}
  //       direction="row"
  //       alignItems="center"
  //       justifyContent="center"
  //     >
  //       <Grid item xs={12} sm={12} lg={3} xl={3}>
  //         <Card>
  //           <CardContent className={classes.cardContent}>
  //             <Grid container direction="row">
  //               <Grid item xs={6}>
  //                 <Typography
  //                   className={classes.title}
  //                   color="textSecondary"
  //                   gutterBottom
  //                 >
  //                   Total <br />
  //                   Pasajeros
  //                 </Typography>
  //                 <Typography variant="h5" component="h2">
  //                   736
  //                 </Typography>
  //                 <Typography className={classes.pos} color="textSecondary">
  //                   Ultimos 7 Días
  //                 </Typography>
  //               </Grid>
  //               <Grid item xs={6}>
  //                 <AirlineSeatReclineExtraIcon
  //                   color="primary"
  //                   className={classes.analyticsIcons}
  //                 />
  //               </Grid>
  //             </Grid>
  //           </CardContent>
  //         </Card>
  //       </Grid>
  //       <Grid item xs={12} sm={12} lg={3} xl={3}>
  //         <Card>
  //           <CardContent className={classes.cardContent}>
  //             <Grid container direction="row">
  //               <Grid item xs={6}>
  //                 <Typography
  //                   className={classes.title}
  //                   color="textSecondary"
  //                   gutterBottom
  //                 >
  //                   Viajes <br />
  //                   Completados
  //                 </Typography>
  //                 <Typography variant="h5" component="h2">
  //                   89
  //                 </Typography>
  //                 <Typography className={classes.pos} color="textSecondary">
  //                   Ultimos 7 Días
  //                 </Typography>
  //               </Grid>
  //               <Grid item xs={6}>
  //                 <DoneOutlineIcon
  //                   color="primary"
  //                   className={classes.analyticsIcons}
  //                 />
  //               </Grid>
  //             </Grid>
  //           </CardContent>
  //         </Card>
  //       </Grid>
  //       <Grid item xs={12} sm={12} lg={3} xl={3}>
  //         <Card>
  //           <CardContent className={classes.cardContent}>
  //             <Grid container direction="row">
  //               <Grid item xs={6}>
  //                 <Typography
  //                   className={classes.title}
  //                   color="textSecondary"
  //                   gutterBottom
  //                 >
  //                   Kilometros <br />
  //                   Recorridos
  //                 </Typography>
  //                 <Typography variant="h5" component="h2">
  //                   2,420
  //                 </Typography>
  //                 <Typography className={classes.pos} color="textSecondary">
  //                   Ultimos 7 Días
  //                 </Typography>
  //               </Grid>
  //               <Grid item xs={6}>
  //                 <TimelineIcon
  //                   color="primary"
  //                   className={classes.analyticsIcons}
  //                 />
  //               </Grid>
  //             </Grid>
  //           </CardContent>
  //         </Card>
  //       </Grid>
  //       <Grid item xs={12} sm={12} lg={3} xl={3}>
  //         <Card>
  //           <CardContent className={classes.cardContent}>
  //             <Grid container direction="row">
  //               <Grid item xs={6}>
  //                 <Typography
  //                   className={classes.title}
  //                   color="textSecondary"
  //                   gutterBottom
  //                 >
  //                   Tiempo <br />
  //                   Recorrido
  //                 </Typography>
  //                 <Typography variant="h5" component="h2">
  //                   52h
  //                 </Typography>
  //                 <Typography className={classes.pos} color="textSecondary">
  //                   Ultimos 7 Días
  //                 </Typography>
  //               </Grid>
  //               <Grid item xs={6}>
  //                 <TimerIcon
  //                   color="primary"
  //                   className={classes.analyticsIcons}
  //                 />
  //               </Grid>
  //             </Grid>
  //           </CardContent>
  //         </Card>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={2}
  //       direction="row"
  //       alignItems="flex-start"
  //       justifyContent="flex-start"
  //     >
  //       <Grid item xs={12} sm={12} lg={6} xl={6}>
  //         <Card>
  //           <CardContent className={classes.cardContent}>
  //             <BarChart />
  //             <br />
  //           </CardContent>
  //         </Card>
  //       </Grid>
  //       <Grid item xs={12} sm={12} lg={6} xl={6}>
  //         <Card>
  //           <CardContent className={classes.cardContent}>
  //             <DoughnutChart />
  //             <br />
  //           </CardContent>
  //         </Card>
  //       </Grid>
  //     </Grid>

  //     <Grid
  //       container
  //       spacing={0}
  //       direction="column"
  //       alignItems="center"
  //       justifyContent="center"
  //       style={{ minHeight: '100vh' }}
  //     >
  //       <Grid item>
  //         <img
  //           style={{
  //             cursor: cursorEnrutate,
  //           }}
  //           src={logo}
  //           alt="Enrutate Logos"
  //         />
  //       </Grid>
  //     </Grid>
  //   </DashboardLayout>
  // );
};
