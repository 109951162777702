import { green, red } from '@material-ui/core/colors';

export const styles = {
  table: {
    marginTop: '10px',
  },
  cardContent: {
    padding: '0px !important',
  },
  card: {
    marginTop: '1rem',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
  select: {
    width: '100%',
    marginTop: '1rem',
    marginBottom: '8px',
  },
  clientSelect: {
    marginBottom: '12px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}