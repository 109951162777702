import request from './../request';

function signin({ username, password }) {
  return request({
    url: '/Sesion/IniciarSesion',
    method: 'POST',
    data: {
      CategoriaID: 2,
      contraseña: password,
      usuario: username,
    },
  });
}

const AuthenticationService = {
  signin,
};

export default AuthenticationService;
