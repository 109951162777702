import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';

import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import EditIcon from '@material-ui/icons/Edit';
import { CardContent, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

// import Pagination from '@material-ui/lab/Pagination';

import Chip from '@material-ui/core/Chip';
import { green, red } from '@material-ui/core/colors';

import StopsService from './../../../services/api/stops';

const useStyles = makeStyles({
  th: {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
  searchIcon: {
    marginRight: '16px',
  },
  paper: {
    height: '42px',
    display: 'flex',
    padding: '0px 16px',
    alignItems: 'center',
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: '1',
  },
  searchButton: {
    marginLeft: '16px',
    marginRight: '5px',
  },
  searchContainer: {
    marginBottom: '1em',
  },
  cardContent: {
    paddingBottom: '1em !important',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
  paginatorContainer: {
    marginTop: '1em',
  },
  filtersBox: {
    marginBottom: '-30px',
  },
  filterBtn: {
    marginTop: '3px',
  },
  table: {
    overflowX: 'auto',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
});

export const StopsList = () => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    status: '',
  });

  const [stops, setStops] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = React.useState(false);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(function () {
      //Request
      StopsService.getList()
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          setIsLoading(false);
          //Get Routes
          if (response && response.Data && response.Data.length > 0) {
            setStops(response.Data);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 1000);
  }, []);

  const handleFilters = (event) => {
    setFilters(filters ? false : true);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} direction="row" alignContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card
            className={classes.searchContainer}
            style={{ background: '#f4f6f8' }}
          >
            <CardContent>
              <Grid
                container
                alignContent="center"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={12} lg={3} xl={3} sm={12} md={12}>
                  <Paper elevation={1} className={classes.paper}>
                    <SearchIcon className={classes.searchIcon} />
                    <InputBase placeholder="Buscar" />
                  </Paper>
                </Grid>
                <Grid item xs={12} lg={1}>
                  <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    color="secondary"
                    className={classes.filterBtn}
                  >
                    Buscar
                  </Button>
                </Grid>
                <Grid item xs={12} lg={1}>
                  <Button
                    fullWidth
                    onClick={handleFilters}
                    size="large"
                    color="secondary"
                    startIcon={<FilterListIcon />}
                    className={classes.filterBtn}
                    variant="outlined"
                  >
                    Filtros
                  </Button>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={12} lg={3}>
                  <Button
                    fullWidth
                    component={Link}
                    to="/stops/create"
                    size="large"
                    variant="contained"
                    color="primary"
                    className={classes.filterBtn}
                  >
                    Crear Nueva Parada
                  </Button>
                </Grid>
              </Grid>
              {/* FilterButtons */}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={filters}>
            <Card
              className={classes.searchContainer}
              style={{ background: '#f4f6f8' }}
            >
              <CardContent className={classes.cardContent}>
                <Grid
                  container
                  className={classes.header}
                  alignContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h6" gutterBottom>
                      Filtros Avanzados &nbsp;
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="outlined-status-native-simple">
                        Estado
                      </InputLabel>
                      <Select
                        native
                        value={state.status}
                        onChange={handleChange}
                        label="Status"
                        inputProps={{
                          name: 'status',
                          id: 'outlined-status-native-simple',
                        }}
                      >
                        <option aria-label="None" value="" />
                        <option value={true}>Activo</option>
                        <option value={false}>Inactivo</option>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Collapse>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        alignContent="center"
        className={classes.filtersBox}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card style={{ background: '#f4f6f8' }}>
            <CardContent>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.th} align="center">
                      ID
                    </TableCell>
                    <TableCell className={classes.th} align="left">
                      Nombre
                    </TableCell>
                    <TableCell className={classes.th} align="center">
                      Ubicación
                    </TableCell>
                    <TableCell className={classes.th} align="center">
                      Estado
                    </TableCell>
                    <TableCell className={classes.th} align="center">
                      Acciones
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading && (
                    <TableRow key="skeletonRow" width={100}>
                      <TableCell colSpan="6" align="center">
                        <Skeleton count={5} height={50} />
                      </TableCell>
                    </TableRow>
                  )}
                  {stops.length <= 0 && !isLoading && (
                    <TableRow key="skeletonRowSinResultados" width={100}>
                      <TableCell colSpan="6" align="center">
                        <h2>Sin Resultados</h2>
                      </TableCell>
                    </TableRow>
                  )}
                  {stops.map((row) => (
                    <TableRow key={row.ParadaID}>
                      <TableCell align="center">{row.ParadaID}</TableCell>
                      <TableCell align="left">{row.Nombre}</TableCell>

                      <TableCell align="center">
                        {row.Latitud + ' / ' + row.Longitud}{' '}
                      </TableCell>
                      <TableCell align="center">
                        {row.EstaActivo ? (
                          <Chip
                            icon={<CheckIcon style={{ color: green[700] }} />}
                            label="Activa"
                            className={classes.chipActive}
                          />
                        ) : (
                          <Chip
                            icon={<CloseIcon style={{ color: red[700] }} />}
                            label="Inactiva"
                            className={classes.chipInactive}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Ver detalle">
                          <IconButton
                            color="secondary"
                            size="small"
                            href={`/stops/detail/${row.ParadaID}`}
                            aria-label="see detail"
                          >
                            <ZoomInIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Editar">
                          <IconButton
                            color="secondary"
                            size="small"
                            href={`/stops/edit/${row.ParadaID}`}
                            aria-label="edit route"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
