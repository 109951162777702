
import { makeStyles } from '@material-ui/core/styles';

import { green, red } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme) => ({
  container: {

  },
  filterContainer: {
    // border: '1px solid red',
    marginBottom: '-16px'
  },
  paper: {
    height: '42px',
    display: 'flex',
    padding: '0px 16px',
    alignItems: 'center',
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: '1',
  },


  body: {
    boxSizing: 'border-box',
  },
  table: {
    minWidth: 650,
  },
  th: {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
  searchIcon: {
    marginRight: '16px',
  },
  searchButton: {
    marginLeft: '16px',
    marginRight: '5px',
  },
  searchContainer: {
    overflow: 'visible',
  },
  searchInput: {
    width: '100%',
  },
  selectRoute: {
    width: '100%',
    padding: '5px 0px'
  },
  card: {
    overflow: 'visible!important'
  },
  cardContent: {
    paddingBottom: '1em !important',
  },
  chipActive: {
    backgroundColor: green[100],
    color: green[700],
  },
  chipInactive: {
    backgroundColor: red[100],
    color: red[700],
  },
  paginatorContainer: {
    marginTop: '1em',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    fontWeight: 800,
  },
  pos: {
    marginBottom: 12,
  },
  analyticsIcons: {
    fontSize: 80,
    marginLeft: '1.5rem',
  },
  paperModal: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  right: {
    textAlign: 'right',
  },
  reactDatepickerPopper: {
    zIndex: '9999 !important'
  },
  mapStyle: {
    
  }
}));
