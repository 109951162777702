import { useState, useEffect } from 'react';

import RoutesService from './../services/api/routes';

export default function useRoutesCompanies(companyId = null) {

  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    RoutesService.getListByCompany(companyId)
      .then((response) => {
        if (response === null || !response.IsValid) {
          throw response.Message;
        }
        // Get Routes
        if (response && response.Data !== undefined) {
          setRoutes(response.Data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [companyId]);

  return routes;
}