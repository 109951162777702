import React, { useEffect, useState } from 'react';

import Skeleton from 'react-loading-skeleton';
import { Controller } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import UsersService from './../../../../services/api/users';

const useStyles = makeStyles({
  select: {
    width: '100%',
    marginTop: '1em',
    marginBottom: '1em',
  },
  formControl: {
    width: '100%',
    marginTop: '1.1em',
  },
});

export const DriverSelect = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [userId, setUserID] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    setIsLoading(true);

    setTimeout(function () {
      //Request
      UsersService.getDrivers()
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          //
          //Get Routes
          if (response && response.Data !== undefined) {
            if (!isCancelled) {
              setUserID(props.routeId);
              setIsLoading(false);
              setUsers(response.Data);
            }
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 500);

    return () => {
      isCancelled = true;
    };
  }, []);

  const handleChange = (event) => {
    setUserID(event.target.value);
    props.parentCallback(event.target.value);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Skeleton count={1} height={50} className={classes.formControl} />
      ) : (
        <Controller
          as={
            <FormControl variant="outlined" className={classes.select}>
              <InputLabel id="driverLabel">Chofer</InputLabel>
              <Select
                labelId="driverLabel"
                id="driver"
                autoWidth
                value={userId}
                onChange={handleChange}
                label="Chofer"
              >
                {users.map((user) => (
                  <MenuItem key={user.UsuarioID} value={user.UsuarioID}>
                    {user.Nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          className={classes.select}
          name="driver"
          control={props.control}
          error={props.error}
        />
      )}
    </React.Fragment>
  );
};
