import { useState, useEffect } from 'react';

import CompaniesService from './../services/api/companies';

export default function useCompanies() {

  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    let isCancelled = false;

    setTimeout(function () {
      //Request
      CompaniesService.getCityClients()
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          //Get Companies
          if (response && response.Data !== undefined) {
            if (!isCancelled) {
              setCompanies(response.Data);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, 0);

    return () => {
      isCancelled = true;
    };
  }, []);

  return companies;
}