import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import DefaultLayout from './../layouts/default';

import { LoginForm } from '../../components/LoginForm';

import logo from './../../assets/images/logo.png';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px',
  },
  logo: {
    width: '250px',
    marginTop: theme.spacing(8),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const LoginPage = () => {
  const classes = useStyles();

  return (
    <DefaultLayout title={'Iniciar sesion'}>
      <Container component="main" maxWidth="xs">
        <Grid item xs={12} align="center">
          <img src={logo} className={classes.logo} alt="Enrutate Logo" />
        </Grid>
        <Paper elevation={10} className={classes.paper}>
          <Typography component="h1" variant="button">
            Iniciar Sesión
          </Typography>
          <LoginForm />
        </Paper>
      </Container>
    </DefaultLayout>
  );
};
