import React, { useEffect, useState } from 'react';

import Skeleton from 'react-loading-skeleton';
import { Controller } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles({
  select: {
    width: '100%',
    marginTop: '1em',
    marginBottom: '1em',
  },
  formControl: {
    width: '100%',
    marginTop: '1.1em',
  },
});

export const GenericSelect = (props) => {
  const classes = useStyles();
  const [isLoading] = useState(false);

  const [itemSelected, setItemSelected] = useState('');

  const handleChange = (event) => {
    setItemSelected(event.target.value);
    props.parentCallback(event.target.value);

    if (props.parentExtraCallback) {
      props.parentExtraCallback(event.currentTarget.dataset.extra);
    }
  };

  useEffect(() => {
    if (props.itemSelected !== '' && props.itemSelected !== undefined) {
      setItemSelected(props.itemSelected);
    }
  }, [props.itemSelected]);

  return (
    <React.Fragment>
      {isLoading ? (
        <Skeleton count={1} height={50} className={classes.formControl} />
      ) : (
        <Controller
          as={
            <FormControl variant="outlined" className={classes.select}>
              <InputLabel id="genericSelectLabel">{props.label}</InputLabel>
              <Select
                labelId="genericSelectLabel"
                id="genericSelect"
                autoWidth
                value={itemSelected}
                onChange={handleChange}
                label={props.label}
                disabled={ props.disabled ? props.disabled : false }
              >
                {props.items.map((item) => (
                  <MenuItem
                    data-extra={item[props.itemExtra]}
                    key={item[props.itemKey]}
                    value={item[props.itemValue]}
                  >
                    {item[props.itemText]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          className={classes.select}
          name={props.inputName}
          control={props.control}
          error={props.error}
        />
      )}
    </React.Fragment>
  );
};
