import request from './../request';
import moment from 'moment';
import { getAuthorizationToken } from './../session';

function getList(signal) {
  return request({
    url: '/usuario/usuarios/?categoriaUsuarioID=2',
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
    signal,
  });
}

function get(userId) {
  return request({
    url: `/usuario/usuarios/${userId}`,
    headers: { Authorization: getAuthorizationToken() },
    method: 'GET',
  });
}

function create(data) {
  return request({
    url: '/usuario/usuarios/',
    method: 'POST',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      Nombre: data.name,
      Direccion: data.address,
      Email: data.email,
      Telefono1: data.phone1,
      Telefono2: data.phone2,
      FechaExpiracion: moment(data.expiration_date).utc(),
      Puesto: 'Chofer',
      EstaActivo: data.status,
      Contraseña: data.password,
      NombreUsuario: data.username,
      FechaCreacion: new Date(),
      ClienteID: data.companyId,
      TipoUsuarioID: data.clientId,
      CategoriaUsuarioID: 2,
    },
  });
}

function update(driverId, data) {
  return request({
    url: `/usuario/usuarios/`,
    method: 'PUT',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      UsuarioID: driverId,
      Nombre: data.name,
      Direccion: data.address,
      Email: data.email,
      Telefono1: data.phone1,
      Telefono2: data.phone2,
      Puesto: 'Chofer',
      FechaExpiracion: moment(data.expiration_date).utc(),
      ClienteID: data.companyId,
      TipoUsuarioID: data.clientId,
      CategoriaUsuarioID: 2,
      EstaActivo: data.status,
      Contraseña: data.password,
      NombreUsuario: data.username,
      FechaCreacion: data.created_at,
    },
  });
}

function deleteItem(driverId) {
  return request({
    url: `/usuario/usuarios/`,
    method: 'DELETE',
    headers: { Authorization: getAuthorizationToken() },
    data: {
      UsuarioID: driverId,
    },
  });
}

const DriversService = {
  get,
  getList,
  create,
  update,
  deleteItem,
};

export default DriversService;
