import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Alert, AlertTitle } from '@material-ui/lab';

import { makeStyles } from '@material-ui/core/styles';

import AuthenticationService from './../../services/api/authentication';
import { setSessionCookie } from './../../services/session';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {},
}));

export const LoginForm = () => {
  const classes = useStyles();
  let history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState({
    error: false,
    errorDescription: null,
  });

  let loginSchema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
  });

  const { handleSubmit, errors, control } = useForm({
    validationSchema: loginSchema,
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    setRequestError({ error: false, errorDescription: null });

    AuthenticationService.signin({
      username: data.username,
      password: data.password,
    })
      .then((response) => {
        if (response === null || response === undefined || !response.IsValid) {
          throw response.Message;
        }
        //Storage user session
        //Get Role
        let role = 'logged';
        switch (response.Data.Categoria.CategoriaID) {
          case 1:
            role = 'superadmin';
            break;
          case 2:
            role = 'driver';
            break;
          case 3:
            role = 'employee';
            break;
          case 4:
            role = 'client';
            break;
          case 5:
            role = 'company';
            break;
          default:
            role = 'logged';
            break;
        }

        setSessionCookie(
          response.Data.NombreUsuario,
          response.Data.Token,
          response.Data.UsuarioID,
          role
        );
        history.push('/dashboard');
      })
      .catch((e) => {
        setRequestError({
          error: true,
          errorDescription: e.status ? e.data.Message : e,
        });
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Controller
          as={<TextField />}
          control={control}
          rules={{ required: true }}
          name="username"
          defaultValue=""
          variant="outlined"
          margin="normal"
          fullWidth
          required
          id="username"
          label="Nombre de Usuario"
          autoComplete="username"
          autoFocus
          disabled={isLoading}
          error={errors.username ? true : false}
        />
        <Controller
          as={<TextField />}
          control={control}
          name="password"
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          disabled={isLoading}
          value=""
          required
          id="password"
          label="Contraseña"
          autoComplete="current-password"
          defaultValue=""
          error={errors.password ? true : false}
        />
        {requestError.error && (
          <Alert
            severity="error"
            style={{ marginBottom: '5px', maxWidth: '90%' }}
          >
            <AlertTitle>Algo salió mal</AlertTitle>
            <p style={{ wordWrap: 'break-word', maxWidth: '90%' }}>
              {requestError.errorDescription}
            </p>
          </Alert>
        )}

        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item style={{ margin: '2px', height: '40px', width: '100%' }}>
            {isLoading ? (
              <Skeleton count={1} height={40} />
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Iniciar Sesión
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
      <Grid container>
        <Grid item xs style={{ marginTop: '12px' }}>
          <Link href="#" variant="body2">
            ¿Olvidó su contraseña?
          </Link>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
