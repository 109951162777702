import React, { createRef, useEffect, useState } from 'react';
import Chart from 'chart.js';
import moment from 'moment';
import { blue, red, lime, amber, deepOrange } from '@material-ui/core/colors';

const labels = moment();

const config = {
  type: 'doughnut',
  data: {
    labels: ['Ruta 1', 'Ruta 2', 'Ruta 3', 'Ruta 4', 'Ruta 5'],
    datasets: [
      {
        data: [526, 125, 485, 450, 862],
        backgroundColor: [
          blue['A100'],
          red['A100'],
          lime['A100'],
          amber['A100'],
          deepOrange['A100'],
        ],
      },
    ],
  },
  options: {
    responsive: true,
    legend: {
      display: true,
    },
    title: {
      display: true,
      text: 'Rutas afluencia ultimos 7 días',
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
  },
};

export const DoughnutChart = (props) => {
  const chartRef = createRef();

  useEffect(() => {
    let chart = new Chart(chartRef.current, config);
  }, []);

  return <canvas ref={chartRef} />;
};
