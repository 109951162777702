import React, { useEffect, useState } from 'react';

import Skeleton from 'react-loading-skeleton';
import { Controller } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import CompaniesService from './../../../../services/api/companies';

const useStyles = makeStyles({
  select: {
    width: '100%',
    marginTop: '1em',
    marginBottom: '1em',
  },
  formControl: {
    width: '100%',
    marginTop: '1.1em',
  },
});

export const CompanySelect = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [companyId, setCompanyId] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    setIsLoading(true);

    setTimeout(function () {
      //Request
      CompaniesService.getCityClients()
        .then((response) => {
          if (response === null || !response.IsValid) {
            throw response.Message;
          }
          //Get Routes
          if (response && response.Data !== undefined) {
            if (!isCancelled) {
              setCompanyId(props.companyId);
              setIsLoading(false);
              setCompanies(response.Data);
            }
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 0);

    return () => {
      isCancelled = true;
    };
  }, []);

  const handleChange = (event) => {
    setCompanyId(event.target.value);
    props.parentCallback(event.target.value);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Skeleton count={1} height={50} className={classes.formControl} />
      ) : (
        <Controller
          as={
            <FormControl variant="outlined" className={classes.select}>
              <InputLabel id="companyLabel">Compañia</InputLabel>
              <Select
                labelId="companyLabel"
                id="companyId"
                autoWidth
                value={companyId}
                onChange={handleChange}
                label="Compañia"
              >
                {companies.map((company) => (
                  <MenuItem key={company.ClienteID} value={company.ClienteID}>
                    {company.NombreCliente}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          className={classes.select}
          name="companyId"
          control={props.control}
          error={props.error}
        />
      )}
    </React.Fragment>
  );
};
